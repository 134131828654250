
import React from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import { AxiosConfig } from "../Common/AxiosConfig";
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';


const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
export default class VesselDesignMasterList
  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        // { title: "Id", field: "id" , hidden: true }
        { title: "Id", field: "id", hidden: true },
        {
          title: "Sr No",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
        },
        { title: "Design Type Name", field: "designTypeName" },

      ],
      DesignData: [],
      rowData: [],
      // id: this.props.match.params.id,
      designTypeName: "",
      message: "",
      show: false,

    }
  }


  getDesign() {
    AxiosConfig.get('dsgType_view').then(response => {
      this.setState({ DesignData: response.data })
    });
  }



  updateDesign(id) {
    console.log("update")
    AxiosConfig.get("dsgType_findone/" + id)
      .then(response => {
        this.props.history.push("/VesselDesignMaster/" + id);
      })
  }
  componentDidMount() {
    this.getDesign();
  }

  deleteDesign = (id) => {
    AxiosConfig.delete('dsgType_delete/' + id, this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
        this.getDesign();
        this.setState({ message: 'Vessel Design Deleted Sucessfully!!' })
      })
      .catch((error) => {
        console.log(error);
      })
    this.outMessage();
  }

  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  render() {
    const { DesignData, message, SelectParentCategory, rowData, columns } = this.state;
    return (

      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">

        <h6>{this.state.message && (
          <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
            <a
              href="VesselDesignMasterList" className="close" data-dismiss="alert" aria-label="close"> &times;
            </a>
            {this.state.message}
          </div>)}
        </h6>

        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="">
              <h3 className="text-center blueText mb-3">Vessel Design Type List</h3>
            </Col>
          </Row>
          <Row className="">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={DesignData} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.updateDesign(rowData.id)
                            // this.props.history.push(`/VesselDesignMaster?id=${rowData._id}`)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ show: true, rowData: rowData })
                          }
                        }
                      ]
                    }


                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>
        <DeleteConfirm show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.designTypeName}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteDesign} />

      </Container>
    );
  }
}
