
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default  class VesselEquipmentDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        VesselYearBuilt:[
            { value: '2019' },
            { value: '2018' }
          ] ,
        columns: [  
            // { title: "Id", field: "id" , hidden: true },
            { title: "Vessel Id", field: "Id" , hidden: true },
            { title: "Vessel Equipment", field: "Equip"},
            { title: "Equipment Value", field: "EquipmentValue"},
            
           
          ],
          Data : [
            {Id: "01",Equip:"ABC", EquipmentValue: "123"},
            {Id: "01",Equip:"ABC", EquipmentValue: "123"},
            {Id: "01",Equip:"ABC", EquipmentValue: "123"},
            {Id: "01",Equip:"ABC", EquipmentValue: "123"},
          ],
          
          
        }
             
  }

  render(){
    const{Data,columns} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
        <Card className="shadow p-4 col-md-12">
       <Card className="shadow p-4 col-md-6 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">vessel Equipment Details</h3>
         </Col>
       </Row>
       <Form>
        <Row>
        <Col md={12}>
            <FormGroup>
              <Label>Vessel Equipment </Label>
                <Input type="text" name="VesselEquip"  placeholder="Vessel Equipment " />
            </FormGroup>
           </Col>   
           <Col md={12}>
            <FormGroup>
              <Label>Vessel Equipment Value</Label>
                <Input type="text" name="VesselEquipvalue"  placeholder="Vessel Equipment Value" />
            </FormGroup>
           </Col>   
           {/* <Col md={6}>
            <FormGroup>
                <Label>Unit Id</Label>
                <Input type="number" name="UnitId" placeholder=" Unit Id"/>
            </FormGroup>
            </Col> */}
        </Row>
        <Row>
          <Col className="text-center mt-5">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
       <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'visibility',
                        tooltip: 'View',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row>
       </Card>
    </Container>
    );
}

}