
import React from "react";
import axios from "axios";
import $ from 'jquery';
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import { Modal } from 'react-bootstrap'
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import { AxiosConfig } from '../Common/AxiosConfig.js';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
const validateUrl = 'unit/name';
export default class UnitMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      columns: [
        // { title: "Id", field: "id" , hidden: true }

        {

          title: 'Sr No', field: 'tableData.id',

          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }

        },
        { title: "Unit Name", field: "unitName" },



      ],

      checked: false,
      unitName: '',
      errors: {},
      id: this.props.match.params.id,
      message: "",
      message1: "",
      rowData: [],
      units: [],
      showMod: false,
      cr_id: "",
      cr_date: "",


    }
    this.handleChange = this.handleChange.bind(this);
  }



  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getVesselUnit() {
    AxiosConfig.get("unit")
      .then((res) => {
        this.setState({ units: res.data });

      });
  }

  outMessage = () => {

    $(".alert").show();

    setTimeout(function () {

      $(".alert").hide();

    }, 2000);
    this.setState({ message: "" })
    this.setState({ message1: "" })

  }


  onReset = (e) => {

    this.setState({
      unitName: "",



    })

  }

  componentDidMount() {

    this.getVesselUnit();
  }

  selectOne(id) {
    AxiosConfig.get("unit/" + id).
      then(response => {
        this.getData(id);
      })
  }

  getData(id) {
    if (id != null) {
      AxiosConfig.get("unit/" + id)
        .then((response) => {
          let unit = response.data;

          this.setState({
            id: unit.id,
            unitName: unit.unitName,
            cr_id: unit.cr_id,
            cr_date: unit.cr_date,

          });

        })
        .catch((error) => {
          alert('error ')
          console.log(error);
        });
    }
  }

  updateRecord = (e) => {

    e.preventDefault();
    e.target.reset();
    var unit = {
      id: this.state.id,
      unitName: this.state.unitName,
      cr_id: this.state.cr_id,
      cr_date: this.state.cr_date,


    }
    AxiosConfig.put("unit/" + this.state.id, unit)
      .then((result) => {
        if (result === 201) {
          console.log(result);
        }
        this.getVesselUnit();
        this.setState({
          message: ` Unit Updated Successfully!!`, id: ""
        });
        this.onReset();

      })
      .catch((error) => {
        console.log(error);
      });

    this.outMessage();

  }

  deleteDesign = (id) => {

    AxiosConfig.delete(`unit/` + id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.getVesselUnit();
        this.setState({ message1: " unit Deleted Successfully" });
      })
    this.outMessage();
  }

  saveRecord = (e) => {


    e.preventDefault();


    let units = { unitName: this.state.unitName };
    console.log('employee => ' + JSON.stringify(units));

    // step 5

    AxiosConfig.post("unit", units)

      .then(res => {
        this.getVesselUnit();
        this.onReset();

        this.setState({
          message: ` Unit Added Successfully!!`,
        });


      })
      .catch((error) => {
        alert(error);
      })
    this.outMessage();
  }



  viewModal = (event, rowData) => {
    this.setState({ show: true, rowData: rowData });


  }

  render() {
    const { rowData, units, unitName, id, Data, columns, cr_id, cr_date, } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message1 && (<div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
          <a href="UnitMaster" className="close" data-dismiss="alert" aria-label="close"
          > &times; </a> {this.state.message1}  </div>)} </h6>

        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/UnitMaster"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Card className="shadow p-4 col-md-8 mx-auto">
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">Unit Master</h3>
              </Col>
            </Row>
            <Form onSubmit={this.state.id ? this.updateRecord : this.saveRecord}>
              <Row>
                <Col md={12}>
                  {/* <Input type="hidden" name="id" required value={id} />
                  <Input type="hidden" name="cr_id" required value={cr_id} />
                  <Input type="hidden" name="cr_date" required value={cr_date} /> */}

                  <FormGroup>
                    <Input

                      type="hidden"

                      name="id"

                      required

                      value={id}

                    />

                    <Input type="hidden" name="cr_id" required value={cr_id} />

                    <Input

                      type="hidden"

                      name="cr_date"

                      required

                      value={cr_date}

                    />
                    <Label>Unit Name</Label>
                    <Input type="text" name="unitName" value={unitName} onChange={this.handleChange} placeholder="Enter Unit Name" required />

                    <ValidateMaterial url={validateUrl} name={unitName} id={id}></ValidateMaterial>

                    {/* <div style={{color:"green"}}><NotificationContainer/></div> */}

                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-2">
                  <div className="center-block text-center">
                    <Button className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>
                    <Button type="reset" className="text-center " color="danger"
                      onClick={this.onReset}>Cancel</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
          <DeleteConfirm show={this.state.showMod}
            animation={true}
            rowData={rowData}
            id={rowData.id}
            name={rowData.unitName}
            close={() => this.setState({ showMod: false })}
            deleteHandler={this.deleteDesign} />

          <Row className="mt-5 mb-3">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={units} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.selectOne(rowData.id)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ showMod: true, rowData: rowData })
                          }
                        },
                        // {
                        //   icon: 'visibility',
                        //   tooltip: 'View',
                        //   onClick: (event, rowData) => {
                        //     this.viewModal(event, rowData)
                        //   }
                        // }
                      ]
                    }
                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>

      </Container>
    );
  }

}