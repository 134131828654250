import React, { Component } from 'react'
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import Select from 'react-select';
import axios from '../../service/axiosConfig.js';
import { AxiosConfig } from "../Common/AxiosConfig";
import $ from 'jquery';

export default class UpdateVesselDimension extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vessel_parameter: "",
            parameter_value: "",
            unit: "",
            selectedUnit: [],
            error: {},
            cr_id: "",
            cr_date: "",
            message: "",
            dimensionId: sessionStorage.getItem('dimensionId')
        }
    }
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onParameterChange = (obj) => {
        this.setState({ vessel_parameter: obj })

    }
    onUnitChange = (obj) => {
        this.setState({ unit: obj })

    }
    componentDidMount() {
        this.getVesselParameters();



    }
    updateDimension = (e) => {
        e.preventDefault();
        var Dimension = {
            id: this.state.dimensionId,
parameterModel:{
    id:this.state.vessel_parameter.value
},
            parameter_value: this.state.parameter_value,
            unitModel:{
                id:this.state.unit.value
            },
         
            cr_id: this.state.cr_id,
            cr_date: this.state.cr_date
        }
        if(this.validateForm()){
        AxiosConfig.put('VesselDimension/' + this.state.dimensionId, Dimension)
            .then(result => {
                this.setState({
                    message: 'Vessel Dimension Updated sucessfully!!',

                });

                this.onClear();
            })
        }
        this.outMessage();
    }
    outMessage = () => {
        $(".alert").show();
        setTimeout(function () {
            $(".alert").hide();
        }, 2000);
        this.setState({
            message: ""
        })
    }
    async getVesselParameters() {
        await axios.get("parameter").then((res) => {


            const data = res.data
            const parameterOptions = data.map(d => ({
                "value": d.id,
                "label": d.parameterName
            }))
            this.setState({ selectedParameter: parameterOptions })
            axios.get("unit").then((res1) => {


                const data = res1.data
                const unitOptions = data.map(d => ({
                    "value": d.id,
                    "label": d.unitName
                }))
                this.setState({ selectedUnit: unitOptions })
                this.getVesselDimensionById();
            })
        })
    }
    getVesselDimensionById() {
        AxiosConfig.get("VesselDimension/" + this.state.dimensionId)
            .then(response => {
                
                this.setState({
                  vessel_parameter: response.data.parameterModel.id,
                  parameter_value: response.data.parameter_value,
                  unit: response.data.unitModel.id,
                  cr_id: response.data.cr_id,
                  cr_date: response.data.cr_date,
                });
                const Parameter = this.state.selectedParameter.find((row) => {
                    return row.value === this.state.vessel_parameter;
                });
                if (Parameter) {
                    this.onParameterChange(Parameter);
                }
                const unit = this.state.selectedUnit.find((row) => {
                    return row.value === this.state.unit;
                });
                if (unit) {

                    this.onUnitChange(unit);
                }
            })
    }
    onClear = () => {
        this.setState({
            vessel_parameter: "",
            parameter_value: "",
            unit: "",
        })
    }
    validateForm = () => {
       
        let error = {};
        let formIsValid = true;
        if (!this.state.vessel_parameter) {
            formIsValid = false;
            error["vessel_parameter"] = "*Please Choose Vessel Parameter";
        }

        if (!this.state.unit) {
            formIsValid = false;
            error["unit"] = "*Please Choose unit";
        }
        if (!this.state.parameter_value) {
            formIsValid = false;
            error["parameter_value"] = "*Please Choose Parameter Value";
        }

        this.setState({
            error: error
        });
        return formIsValid;
    }

    render() {
        return (
            <div>
                <Container fluid className="formWpr">

                    <h6>
                        {this.state.message && (
                            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                                <a
                                    href="/VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;
                                </a>
                                {this.state.message}
                            </div>)}
                    </h6>

                    <Card className="shadow p-4 col-md-8 mx-auto">
                        <Row>
                            <Col className="mb-4">
                                <h3 className="text-center blueText">Vessel Dimensions</h3>
                            </Col>

                        </Row>
                        <Form
                            onSubmit={this.updateDimension}
                        >
                            <Row>
                                <Col md={4}>
                                    <FormGroup>


                                        <Label className="required">Vessel Parameter</Label>
                                        <Select
                                            value={this.state.vessel_parameter}
                                            onChange={this.onParameterChange}
                                            options={this.state.selectedParameter}
                                            name="vessel_parameter"
                                        />
                                        {this.state.error.vessel_parameter &&

                                            <div className="errorMsg">{this.state.error.vessel_parameter} </div>
                                        }

                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label className="required">Parameter Value</Label>
                                        <Input type="text" required
                                            name="parameter_value"
                                            value={this.state.parameter_value}
                                            placeholder="Enter Parameter Value"
                                            onChange={e => { this.onInputChange(e); }} />
                                        {this.state.error.parameter_value &&

                                            <div className="errorMsg">{this.state.error.parameter_value} </div>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Label className="required">Unit</Label>
                                    <Select
                                        value={this.state.unit}
                                        onChange={this.onUnitChange}
                                        options={this.state.selectedUnit}
                                        name="unit"
                                    />
                                    {this.state.error.unit &&

                                        <div className="errorMsg">{this.state.error.unit} </div>}
                                </Col>
                            </Row>
                            {/* <Row>
                              
                                <Col md={2} className="AddBtnCol">
                                    <Button onClick={this.updateDimension}>UPDATE</Button>
                                </Col> 
                            <Col md={2} npm startclassName="text-center mt-3">
                                <div className="center-block text-center">
                                    <Button className="text-center " color="danger" type="reset" onClick={this.onReset}>Clear</Button>
                                </div>
                            </Col> 
                            </Row> */}
                            <Row>
                                <Col md={1} className="AddBtnCol">

                                    <Button>UPDATE</Button>
                                </Col>


                                <Col md={3} className="AddBtnCol">
                                    <div className="center-block text-center">
                                        <Button className="text-center "
                                            onClick={this.onClear}
                                            color="danger">Clear</Button>
                                    </div>
                                </Col>
                            </Row>


                        </Form>
                    </Card>



                </Container>

            </div>
        )
    }
}
