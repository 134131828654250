import React from "react";
import '../../../src/index.css'
import { AxiosConfig, ThumbnailImageUrl } from '../Common/AxiosConfig.js';
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import Switch from "react-switch";
import Table from '../Common/Table'
import { withRouter } from 'react-router';
import FileDropzone from '../Common/FileDropzone'
import '../../Containers/Common/Table.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Modal } from 'react-bootstrap'
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import $ from 'jquery';
import axios from '../../service/axiosConfig.js';

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0,

}
const rowStyle = {
    fontSize: '12px',
    padding: '10px',
    color: '#818698',

}


class AddVesselDesign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            columns: [
                {
                    title: "Sr.No.", field: "tableData.id", render: (rowData) => {
                        return <p>{rowData.tableData.id + 1}</p>;
                    }, hidden: false
                },
                { title: "Design", field: "designTypeName" },
                {
                    title: "Availble", field: "available", render: rowData => {
                        return (rowData.availble ? "YES" : "NO")
                    }
                },
                {
                    title: "Attachment 1", field: "attachment1", render: rowData =>
                        <VisibilityIcon className="visiblityIcnViewer" onClick={() => this.handleModalShowHide(rowData.id, rowData.thumbnail1Name)} status={rowData.id} />
                },
                { title: "Attachment 2", field: "attachment2", render: rowData => <VisibilityIcon className="visiblityIcnViewer " onClick={() => this.handleModalShowHide1(rowData.id, rowData.thumbnail2Name)} status={rowData.id} /> },
                { title: "Attachment 3", field: "attachment3", render: rowData => <VisibilityIcon className="visiblityIcnViewer" onClick={() => this.handleModalShowHide2(rowData.id, rowData.thumbnail3Name)} status={rowData.id} /> },
            ],
            vessel_design: [],
            selectOptions: [],
            VesselDesignList: [],
            VesselDesignList1: [],
            rowData: [],
            selectShow: '',
            FlagData: [],
            SelectCountry: [],
            id: this.props.match.params.id,
            vessel_Design_name: '',
            availble: false,
            attachment1: '',
            attachment2: '',
            attachment3: '',
            selectedCity: [],
            message: "",
            message1: '',
            url: '',
            // fileInfos: [],
            // fileInfos1: [],
            // fileInfos2: [],
            showHide: false,
            currentFile: "",
            currentFile1: "",
            currentFile2: "",
            cr_id: "",
            cr_date: "",
            name1: "",
            name2: "",
            name3: "",
            errors: {},
            merged: [],
            vesselDesign_id: 0,
            detailsArr: [],
            arrLen: 0
        }
        // this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        if (this.state.vessel_Design_name == "") {
            formIsValid = false;
            errors["vessel_Design_name"] = "*Please choose Vessel Design Name";
        }
        if (this.state.attachment1 == "") {
            formIsValid = false;
            errors["attachment1"] = "*Please select attachment1 ";
        }
        if (this.state.attachment2 == "") {
            formIsValid = false;
            errors["attachment2"] = "*Please select  attachment2";
        }
        if (this.state.attachment3 == "") {
            formIsValid = false;
            errors["attachment3"] = "*Please select attachment3";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    async getVesselDesignDetail() {
        await axios.get("VesselDetailDesign")
            .then(res => {
                this.setState({ VesselDesignList: res.data })

            });
        await axios.get("getInfoDesign")
            .then(res => {
                this.setState({ VesselDesignList1: res.data })

            });

        var a1 = this.state.VesselDesignList;
        var a2 = this.state.VesselDesignList1;
        const result = a1.map(item => {
            const obj = a2.find(o => o.id === item.id);
            return { ...item, ...obj };
        });
        this.setState({ merged: result })
    }

    async getOptions() {
        const res = await axios.get('dsgType_view')
        const data = res.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.designTypeName
        }))
        this.setState({ selectOptions: options })
        this.setState({ sel: options })
    }


    componentDidMount() {
        this.getOptions();
        this.getData();
        // this.getVesselDesignDetail();
        // axios.get("files34")
        //     .then((res) => {
        //         this.setState({ fileInfos: res.data })
        //     });
    }

    //function for alert suto close 
    outMessage = () => {
        $(".alert").show();
        setTimeout(function () {
            $(".alert").hide();
        }, 2000);
        this.setState({ message: "" });
        this.setState({ message1: "" });
    }



    handleChange(value) {
        this.setState({ vessel_Design_name: value })
        this.props.inputData.vessel_Design_name = value
    }

    switchhandleChange = (availble) => {
        this.setState({ availble });
    }

    onDropAttachment1 = (files) => {
        if (files.length > 0) {
            this.setState({ attachment1: files })
        }
    };


    onDropAttachment2 = (files) => {
        if (files.length > 0) {
            this.setState({ attachment2: files })
        }
    };

    onDropAttachment3 = (files) => {
        if (files.length > 0) {
            this.setState({ attachment3: files })
        }
    };

    saveDesign = (e) => {
      
        e.preventDefault();
        // e.target.reset();
        var records = {
            "vessel_Design_name": this.state.vessel_Design_name.value,
            "availble": this.state.availble
        }
        // let currentFile = this.state.attachment1[0];
        // let currentFile1 = this.state.attachment2[0];
        // let currentFile2 = this.state.attachment3[0];
        // let vessel_Design_name = this.state.vessel_Design_name.value;
        // let availble = this.state.availble;
        this.setState({ progress: 0 })
        // this.setState({ currentFile: currentFile })
        // this.setState({ currentFile1: currentFile1 })
        // this.setState({ currentFile2: currentFile2 })
        let formData = new FormData();
        formData.append("vesselDesign", records);
        formData.append("attachment1", this.state.attachment1[0]);
        formData.append("attachment2", this.state.attachment2[0]);
        formData.append("attachment3", this.state.attachment3[0]);
        if (this.validateForm()) {
            axios.post("VesselDetailDesign", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {
                    console.log(response, "response")
                    // console.log("vesselDesign_id" , response.data)
                    this.setState({ vesselDesign_id: response.data })
                    console.log(this.state.vesselDesign_id, "vesselDesign_id123 state")
                    this.setState({
                        message: `vessel Design added Successfully!!`,
                    });
                    this.getVesselDesignDetail();
                    this.onReset();
                    // return axios.get("files34");
                })
                .catch((error) => console.log(error))
        }
        this.outMessage();
    };

    onReset() {
        this.setState({
            attachment1: "", attachment2: "", attachment3: "", availble: false, vessel_Design_name: ""
        })
        this.props.inputData.designid = ""
        this.props.inputData.vessel_Design_name = ""
        this.props.inputData.availble = ""
        this.props.inputData.attachment1 = ""
        this.props.inputData.attachment2 = ""
        this.props.inputData.attachment3 = ""
        this.props.inputData.name1 = ""
        this.props.inputData.name2 = ""
        this.props.inputData.name3 = ""
        this.props.inputData.cr_id = ""
        this.props.inputData.cr_date = ""
    }


    deleteDesign = (id) => {
        axios.delete(`VesselDetailDesign/` + id, this.state)
            .then(response => {
                this.getVesselDesignDetail();
                this.setState({ message1: "Vessel Design Deleted Successfully" });
            })
        this.outMessage();
    }


    selectOne(id) {
        AxiosConfig.get("/VesselDetailDesign/" + id).
            then(response => {
                this.getData(id);
            })
    }


    updateDesign = (e) => {
        e.preventDefault();
        console.log(this.state)
        // e.target.reset();

        let currentFile;
        let currentFile1;
        let currentFile2;
        if (this.props.inputData.attachment1.length !== 0) {
            currentFile = this.props.inputData.attachment1[0];
        }
        else {
            currentFile = this.props.inputData.attachment1
        }
        if (this.props.inputData.attachment2.length !== 0) {
            currentFile1 = this.props.inputData.attachment2[0];
        }
        else {
            currentFile1 = this.props.inputData.attachment2
        }
        if (this.props.inputData.attachment3.length !== 0) {
            currentFile2 = this.props.inputData.attachment3[0];
        }
        else {
            currentFile2 = this.props.inputData.attachment3
        }
        let vessel_Design_name = this.state.vessel_Design_name.value;
        let availble = this.state.availble;
        this.setState({ progress: 0 })
        this.setState({ currentFile: currentFile })
        this.setState({ currentFile1: currentFile1 })
        this.setState({ currentFile2: currentFile2 })
        let formData = new FormData();

        formData.append("vessel_Design_name", this.props.inputData.vessel_Design_name.value);
        formData.append("availble", this.props.inputData.availble);
        formData.append("attachment1", currentFile);
        formData.append("attachment2", currentFile1);
        formData.append("attachment3", currentFile2);
        formData.append("cr_id", this.props.inputData.cr_id);
        formData.append("cr_date", this.props.inputData.cr_date);

        axios.put("VesselDetailDesign/" + this.props.inputData.designid, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                console.log("file added successfully")
                this.setState({
                    message: ` Vessel design Updated Successfully!!`,
                });
                this.getVesselDesignDetail();
                this.setState({ id: "" })
                this.setState({ attachment1: "" })
                this.setState({ attachment2: "" })
                this.setState({ attachment3: "" })
                this.setState({ name1: "" })
                this.setState({ name2: "" })
                this.setState({ name3: "" })
                this.setState({ availble: false })
                this.setState({ vessel_Design_name: "" })
                this.onReset();
                return axios.get("files");

            })
            .catch((error) => {
                console.log("file not upload")
                this.onReset();
            });
        this.outMessage();
    };

    getData(id) {
        if (id != null) {
            axios.get("VesselDetailDesign/" + id)
                .then((response) => {
                    let vesselOwner = response.data;
                    // let info = this.state.fileInfos[id - 1];
                    // console.log(info, "info name ")
                    // let info1 = this.state.fileInfos1[id - 1];
                    // let info2 = this.state.fileInfos2[id - 1];
                    this.setState({
                        id: vesselOwner.id,
                        vessel_Design_name: vesselOwner.vessel_Design_name,
                        availble: vesselOwner.availble,
                        attachment1: vesselOwner.attachment1,
                        attachment2: vesselOwner.attachment2,
                        attachment3: vesselOwner.attachment3,
                        name1: vesselOwner.attachment1name,
                        name2: vesselOwner.attachment2name,
                        name3: vesselOwner.attachment3name,
                        cr_id: vesselOwner.cr_id,
                        cr_date: vesselOwner.cr_date,
                    });
                    this.props.inputData.designid = vesselOwner.id
                    this.props.inputData.vessel_Design_name = vesselOwner.vessel_Design_name
                    this.props.inputData.availble = vesselOwner.availble
                    this.props.inputData.attachment1 = vesselOwner.attachment1
                    this.props.inputData.attachment2 = vesselOwner.attachment2
                    this.props.inputData.attachment3 = vesselOwner.attachment3
                    this.props.inputData.name1 = vesselOwner.attachment1name
                    this.props.inputData.name2 = vesselOwner.attachment2name
                    this.props.inputData.name3 = vesselOwner.attachment3name
                    this.props.inputData.cr_id = vesselOwner.cr_id
                    this.props.inputData.cr_date = vesselOwner.cr_date

                    const valueToSet = this.state.selectOptions.find((row) => {
                        return row.value === this.props.inputData.vessel_Design_name;
                    });
                    if (valueToSet) {
                        this.handleChange(valueToSet);
                    }
                    if (valueToSet) {
                        this.handleChange(valueToSet);
                    }
                })
                .catch((error) => {
                    alert('error ')
                    console.log(error);
                });
        }
    }

    handleModalShowHide(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })
        if (id != null) {
            AxiosConfig.get("/VesselDetailDesign/" + id).
                then(response => {
                    let res = response.data;
                    this.setState({
                        url: thumbnailName,
                    });
                }).catch((error) => {

                })
        }
    }


    handleModalShowHide1(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })
        if (id != null) {
            AxiosConfig.get("/VesselDetailDesign/" + id).
                then(response => {
                    let res = response.data;
                    this.setState({
                        url: thumbnailName,
                    });
                })
                .catch((error) => {
                })
        }
    }
    handleModalShowHide2(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })
        if (id != null) {
            AxiosConfig.get("/VesselDetailDesign/" + id).
                then(response => {
                    let res = response.data;
                    this.setState({
                        url: thumbnailName,
                    });
                })
                .catch((error) => {
                })
        }
    }

    onClear() {
        this.setState({
            vessel_Design_name: "",
            availble: "",
            attachment1: '',
            attachment2: '',
            attachment3: '',
            name1: "",
            name2: "",
            currentFile: "",
            currentFile1: '',
            currentFile2: '',
            // fileInfos: '',
            name3: ''
        })
    }


    // onSubmitClick=()=> {
    //     alert("onSubmitClick")
    //     //         var details = [];
    //     //         details = {
    //     //             "vesselParticularsModel": {
    //     //                 // "addressId": this.state.addressId
    //     //                 "id": sessionStorage.getItem('vessel_particulars_id')
    //     //             },
    //     //             "vesselDimensionModal":{
    //     //                 "id": sessionStorage.getItem('dimension_id'),
    //     //             },
    //     //             "vesselEquipmentModel":{
    //     //                 "id": sessionStorage.getItem('vessel_equipment_id'),

    //     //             },
    //     //             "vesselMaterialModel":{
    //     //                 "id": sessionStorage.getItem('material_id'),

    //     //             }, 
    //     //             "vesselDrawingModel":{
    //     //                 "id": sessionStorage.getItem('vessel_drawings_id'),

    //     //             },
    //     //              "vesselDesignModel":{
    //     //                  "id": this.state.vesselDesign_id

    //     //              }

    //     //             // vessel_particulars_id: sessionStorage.getItem('vessel_particulars_id'),

    //     //         }
    //     // console.log(details,"details")
    //     //         this.state.detailsArr.push(details);
    //     //         console.log(this.state.detailsArr,"detailsArr");
    //     //         this.state.arrLen = Object.keys(this.state.detailsArr[0]).length;

    //     //         if (this.state.arrLen === 6 && this.state.vesselDesign_id !== 0) {
    //     //             alert("hello")
    //     //             console.log(this.state.detailsArr);
    //     //             axios.post("VesselDetails", this.state.detailsArr[0]).then((res) => {
    //     //                 console.log(res);
    //     //                 this.setState({
    //     //                     message: `Vessel Details Submitted Sucessfullay..!`
    //     //                 })
    //     //                 // sessionStorage.clear();
    //     //                 // alert(sessionStorage.getItem('vessel_particulars_id'))
    //     //             }).catch((error) => console.log(error))
    //     //         } else {
    //     //             this.setState({
    //     //                 message1: `Please submit all forms data`
    //     //             })
    //     // }
    //     // this.outMessage();
    //     alert(this.state.particularsName)
    //     var Particulars = {
    //         particularsName: this.state.particularsName,
    //         vessel_Flag: this.state.vessel_Flag.value,
    //         vessel_Year_Built: this.state.vessel_Year_Built,
    //         vessel_Type: this.state.vessel_Type.value,
    //         vessel_GRT: this.state.vessel_GRT,
    //         vessel_DWT: this.state.vessel_DWT,
    //         vessel_class: this.state.vessel_class.value,
    //         vessel_Owner: this.state.vessel_Owner.value,
    //         vessel_Commissioned: this.state.vessel_Commissioned,
    //         cr_id: this.state.cr_id,
    //         cr_date: this.cr_date
    //     }
    //     var Dimension = {
    //         vessel_parameter: this.state.vessel_parameter.value,
    //         parameter_value: this.state.parameter_value,
    //         unit: this.state.unit.value,

    //     }
    //     let equipments = { vesselEquipment: this.state.vesselEquipment.value, equipmentValue: this.state.equipmentValue };
    //     var Material = {
    //         material_Name: this.state.material_Name.value,
    //         material_Value: this.state.material_Value,
    //         material_Unit: this.state.material_Unit.value,
    //         surveyor: this.state.surveyor.value,
    //         approved: this.state.approved,

    //     }
    //     var drawingrecords = {
    //         "vesselDrawing_name": this.state.vesselDrawing_name.value,
    //         "vesselDrawing_available": this.state.vesselDrawing_available
    //     }

    //     this.setState({ progress: 0 })

    //     let drawingformData = new FormData();
    //     drawingformData.append('vesselDrawing', JSON.stringify(drawingrecords));

    //     drawingformData.append("vesselDrawing_attachment1", this.state.selectedFiles[0]);
    //     drawingformData.append("vesselDrawing_attachment2", this.state.selectedFiles1[0]);
    //     drawingformData.append("vesselDrawing_attachment3", this.state.selectedFiles2[0]);

    //     var designrecords = {
    //         "vessel_Design_name": this.state.vessel_Design_name.value,
    //         "availble": this.state.availble
    //     }

    //     this.setState({ progress: 0 })

    //     let designformData = new FormData();
    //     designformData.append("vesselDesign", JSON.stringify(designrecords));
    //     designformData.append("attachment1", this.state.attachment1[0]);
    //     designformData.append("attachment2", this.state.attachment2[0]);
    //     designformData.append("attachment3", this.state.attachment3[0]);

    //     let particularid;
    //     let dimensionid;
    //     let equipmentid;
    //     let materialid;
    //     let drawingid;
    //     let designid;

    //     axios.post("VesselParticulars", Particulars).
    //         then((res) => {
    //             particularid = res.data.id
    //             alert("particular")
    //             AxiosConfig.post('VesselDimension', Dimension).
    //                 then((res1) => {
    //                     dimensionid = res1.data.id
    //                     alert("dimension")
    //                     AxiosConfig.post("VesselEquipment", equipments)
    //                         .then((res2) => {
    //                             equipmentid = res2.data.id
    //                             alert("VesselEquipment")
    //                             axios.post("VesselMaterial", Material).
    //                                 then((res3) => {
    //                                     materialid = res3.data.id
    //                                     alert("VesselMaterial")
    //                                     AxiosConfig.post("vesseldrawing", drawingformData
    //                                         , {
    //                                             headers: {
    //                                                 "Content-Type": "multipart/form-data",
    //                                             }
    //                                         }
    //                                     )
    //                                         .then((res4) => {
    //                                             drawingid = res4.data.id
    //                                             alert("drawing")
    //                                             axios.post("VesselDetailDesign", designformData, {
    //                                                 headers: {
    //                                                     "Content-Type": "multipart/form-data",
    //                                                 }
    //                                             })
    //                                                 .then((res5) => {
    //                                                     designid = res5.data.id
    //                                                     alert("design")
    //                                                     let alldetails = {
    //                                                         "vesselParticularsModel": {
    //                                                             // "addressId": this.state.addressId
    //                                                             "id": particularid
    //                                                         },
    //                                                         "vesselDimensionModal": {
    //                                                             "id": dimensionid
    //                                                         },
    //                                                         "vesselEquipmentModel": {
    //                                                             "id": equipmentid

    //                                                         },
    //                                                         "vesselMaterialModel": {
    //                                                             "id": materialid

    //                                                         },
    //                                                         "vesselDrawingModel": {
    //                                                             "id": drawingid

    //                                                         },
    //                                                         "vesselDesignModel": {
    //                                                             "id": designid

    //                                                         }

    //                                                         // vessel_particulars_id: sessionStorage.getItem('vessel_particulars_id'),

    //                                                     }
    //                                                     axios.post("VesselDetails", alldetails)
    //                                                         .then((res) => {
    //                                                             alert("All data save")
    //                                                         })


    //                                                 })


    //                                         })
    //                                 })

    //                         })
    //                 })
    //         })
    // }

    render() {
        const { selectOptions, cr_id, cr_date, vessel_Design_name, rowData, columns } = this.state;
        return (
            <div>
                <Container fluid className="AddVesselDrawing formWpr">
                    <h6>{this.state.message1 && (<div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
                        <a href="FlagMasterTable" className="close" data-dismiss="alert" aria-label="close"
                        > &times; </a> {this.state.message1}  </div>)} </h6>
                    <h6> {this.state.message && (<div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                        <a href="/VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;</a> {this.state.message}</div>)} </h6>
                    <Card className="shadow p-4 col-md-10 mx-auto"> <Row>
                        <Col className="mb-4">
                            <h3 className="text-center blueText">Vessel Designs </h3>
                        </Col>
                        {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                    </Row>
                        <Form>
                            <Input type="hidden" name="cr_id" required value={cr_id} />
                            <Input type="hidden" name="cr_date" required value={cr_date} />
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="required">Vessel Design</Label>
                                        <Select options={selectOptions} onChange={this.props.handleChange} required value={this.props.inputData.vessel_Design_name}
                                        />   <div className="errorMsg">{this.props.errors.vessel_Design_name}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="required">availble</Label><br />
                                        <Switch onChange={this.props.switchhandleChange} checked={this.props.inputData.availble} icon={false} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mb-2">
                                    <FormGroup>
                                        <Label className="required" >Attachment 1</Label>
                                        <FileDropzone ondrop={this.props.onDropAttachment1} selectedfiles={this.props.inputData.attachment1} fileinfo={this.props.inputData.name1} />
                                        <div className="errorMsg">{this.props.errors.attachment1}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <Label className="required">Attachment 2</Label>
                                    <FileDropzone ondrop={this.props.onDropAttachment2} selectedfiles={this.props.inputData.attachment2} fileinfo={this.props.inputData.name2} />
                                    <div className="errorMsg">{this.props.errors.attachment2}</div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Label className="required">Attachment 3</Label>
                                    <FileDropzone ondrop={this.props.onDropAttachment3} selectedfiles={this.props.inputData.attachment3} fileinfo={this.props.inputData.name3} />
                                    <div className="errorMsg">{this.props.errors.attachment3}</div>
                                </Col>
                                {/* <Col md={1} className="AddBtnCol" >
                                    <Button onClick={(e) => { this.state.id ? this.updateDesign(e) : this.saveDesign(e) }} >+</Button>
                                </Col> */}
                                <div style={{ color: "red" }}>{this.state.errormessage}</div>
                                <div style={{ color: "green" }}>
                                    <NotificationContainer />
                                </div>
                                <Col>
                                    {this.props.inputData.designid ?
                                        <div className="center-block text-center">
                                            <Button className="text-center mr-3  btnblue" onClick={this.updateDesign}>UPDATE</Button>
                                        </div>
                                        :
                                        <div className="center-block text-center">
                                            <Button className="text-center mr-3  btnblue" onClick={this.props.onSubmit}>Submit</Button>
                                        </div>}
                                </Col>
                                <Col md={1} className="text-center mt-3">
                                    <div className="center-block text-center">
                                        {/* <Button className="text-center mr-3  btnblue">Submit</Button> */}
                                        <Button className="text-center " onClick={this.onClear.bind(this)} color="danger">Clear</Button>
                                    </div>
                                </Col>
                            </Row>




                        </Form>

                    </Card>
                    <DeleteConfirm
                        show={this.state.show}
                        animation={true}
                        rowData={rowData}
                        id={rowData.id}
                        name={rowData.designTypeName}
                        close={() => this.setState({ show: false })}
                        deleteHandler={this.deleteDesign}
                    />
                    <Modal show={this.state.showHide} className="ViewModal">
                        <Modal.Header closeButton onClick={() => { this.handleModalShowHide(); this.handleModalShowHide1(); this.handleModalShowHide2(); }}>
                            <Modal.Title>Attachment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <img className="photo" src={this.state.url1} /> */}
                            <img className="photo" src={ThumbnailImageUrl + `${this.state.url}`} />
                        </Modal.Body>
                    </Modal>
                </Container >
            </div>
        );
    }
}
export default withRouter(AddVesselDesign);