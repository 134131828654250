import React, { Component } from "react";
import axios from "../../service/axiosConfig.js";
import Table from "../Common/Table";
import { Container, Card, Row, Col } from "reactstrap";

const headerStyle = {
  backgroundColor: "#052754",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

export default class AllVesselDetailsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vesselParticular: [],
      vesselParticularColumns: [
        {
          title: "Id",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
          hidden: true,
        },
        { title: "Vessel Name", field: "particularsName" },
        { title: "Vessel Flag", field: "vesselFlagMaster.flagName" },
        { title: "Vessel Year Built", field: "vessel_Year_Built" },
        { title: "Vessel Type", field: "vesselTypeModel.vesselTypeName" },
        { title: "Vessel GRT", field: "vessel_GRT" },
        { title: "Vessel DWT", field: "vessel_DWT" },
        { title: "Vessel Class", field: "vesselClassModel.className" },
        { title: "Vessel Owner", field: "vesselOwnerMaster.vessel_Owner_Name" },
        {
          title: "Vessel Commissioned",
          field: "vessel_Commissioned",
          render: (rowData) => {
            return rowData.vessel_Commissioned ? "YES" : "NO";
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getParticularsDetails();
  }

  async getParticularsDetails() {
    await axios.get("VesselDetails").then((res1) => {
      axios.get("flagmaster").then((res2) => {
        axios.get("VesselClass").then((res3) => {
          axios.get("vesselOwnerMaster").then((res4) => {
            axios.get("VesselType").then((res5) => {
              var a1 = res1.data;
              var a2 = res2.data;
              var a3 = res3.data;
              var a4 = res4.data;
              var a5 = res5.data;
              console.log(a1, "a1");
              const result = a1.map((item) => {
                const obj = a2.find(
                  (o) => o.id === item.vesselParticularsModel.vessel_Flag
                );
                const obj1 = a3.find(
                  (p) => p.id === item.vesselParticularsModel.vessel_class
                );
                const obj2 = a4.find(
                  (p) => p.id === item.vesselParticularsModel.vessel_Owner
                );
                const obj3 = a5.find(
                  (p) => p.id === item.vesselParticularsModel.vessel_Type
                );
                return {
                  ...item,
                  ...item.vesselParticularsModel,
                  ...obj,
                  ...obj1,
                  ...obj2,
                  ...obj3,
                };
              });

              console.log(result, "result");

              this.setState({ vesselParticular: result });
            });
          });
        });
      });
    });
  }
  render() {
    return (
      <Container className="formWpr">
        <div>
          <Row className="mt-4 mb-3">
            <Col className="tableWp">
              <Table
                data={this.state.vesselParticular}
                pageSize={8}
                paging={false}
                columns={this.state.vesselParticularColumns}
                search={false}
                headerStyle={headerStyle}
                rowStyle={rowStyle}
                maxBodyHeight="350px"
                actions={[
                  // {
                  //     icon: 'edit',
                  //     tooltip: 'Edit',
                  //     onClick: (event, rowData) => {
                  //         console.log(rowData, "row data");
                  //         this.selectOne(rowData.id);
                  //     },
                  // },
                  // {
                  //     icon: 'delete',
                  //     tooltip: 'Delete',
                  //     onClick: (event, rowData) => {
                  //         this.setState({ show: true, rowData: rowData });
                  //         console.log(rowData, "roe")
                  //     }
                  // },
                  {
                    icon: "visibility",
                    tooltip: "View",
                    onClick: (event, rowData) => {
                      this.props.history.push("/VesselDetailsList");
                      sessionStorage.setItem(
                        "vesselId",
                        rowData.vesseDetailsId
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
