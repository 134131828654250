import React from "react";
import { Card, Col, Row, Container, Button } from 'reactstrap';
import Table from '../../Containers/Common/Table';
import '../../Containers/Common/Table.css';
import axios from '../../service/axiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteConf.js';
import $ from 'jquery';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',
}


const springUrl = '/material';
export default class MaterialMasterList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Id", field: "id", hidden: true },
        {
          title: 'Sr No', field: 'tableData.id',
          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        },
        { title: "Material Name", field: "materialName" }
      ],
      Data: [],
      rowData: [],
      show: false,
      close: false,
      message: ""
    }
  }

  refreshList() {
    axios.get(springUrl)
      .then(response => {
        this.setState({ Data: response.data });
      })
  }

  componentDidMount() {
    this.refreshList();
  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/MaterialMaster');
  }

  deleteMaterial = (id) => {
    axios.delete(springUrl + '/' + id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.setState({ message: "Vessel Design Deleted Successfully" });
        this.refreshList();
        this.outMessage();
      })
  }

  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  render() {
    const { Data, columns, show, close, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <h6>{this.state.message && <div class="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
          <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>
          {this.state.message}</div>}</h6>
        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Material List</h3>
            </Col>
          </Row>
          <Col md={5}><Button onClick={this.redirection}>Add Material</Button></Col>
          <Row className="">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.props.history.push(`/MaterialMaster/` + `${rowData.id}`)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {

                            this.setState({ show: true, rowData: rowData })
                            // this.deleteMaterial(rowData.id)
                          }
                        }
                      ]
                    } />
                </Col>
              </Row>
              <DeleteConfirm show={this.state.show} animation={true}
                rowData={rowData} id={rowData.id} name={rowData.materialName}
                close={() => this.setState({ show: false })} deleteHandler={this.deleteMaterial} />
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}
