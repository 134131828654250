import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import AddVesselParticulars from "./AddVesselParticulars";
import AddVesselDimensions from "./AddVesselDimensions";
import AddVesselEquipments from "./AddVesselEquipments";
import AddVesselMaterial from "./AddVesselMaterial";
import AddVesselDrawing from "./AddVesselDrawing";
import AddVesselDesign from "./AddVesselDesign";
import axios from "../../service/axiosConfig.js";
import { AxiosConfig, ImageService } from "../Common/AxiosConfig.js";
import Swal from "sweetalert2";

export default class VesselDetails extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      errors: {},
      errors1: {},

      // particularsName: "",
      thumbnail1Name: "",
      thumbnail2Name: "",
      thumbnail3Name: "",
      attachment1name: "",
      attachment2name: "",
      attachment3name: "",

      dsgThumbnail1Name: "",
      dsgThumbnail2Name: "",
      dsgThumbnail3Name: "",
      dsgAttachment1name: "",
      dsgAttachment2name: "",
      dsgAttachment3name: "",
      vessel_Commissioned: false,
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  //particular
  handleChange1 = (value) => {
    this.setState({ vessel_Flag: value });
  };
  handleChange2 = (value) => {
    this.setState({ vessel_Type: value });
  };
  handleChange3 = (value) => {
    this.setState({ vessel_Owner: value });
  };
  handleChange4 = (value) => {
    this.setState({ vessel_class: value });
  };
  switchhandleChange = (vessel_Commissioned) => {
  
    this.setState({ vessel_Commissioned });
  };

  handleParticular = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDimension = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEquipments = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleMaterial = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDrawings = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDesigns = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  //dimension
  onParameterChange = (obj) => {
    this.setState({ vessel_parameter: obj });
  };
  onUnitChange = (obj) => {
    this.setState({ unit: obj });
  };
  //equipment
  onInputChange = (obj) => {
    this.setState({ vesselEquipment: obj });
  };
  //material
  onMaterialChange = (obj) => {
    this.setState({ material_Name: obj });
    console.log(obj, "obj");
  };
  onUnitChange1 = (obj) => {
    this.setState({ material_Unit: obj });
  };
  onSurveyorChange = (obj) => {
    this.setState({ surveyor: obj });
  };
  SwitchhandleChange1 = (approved) => {
    this.setState({ approved });
  };
  //drawing
  onInputChange1 = (obj) => {
    this.setState({ vesselDrawing_name: obj });
  };
  switchhandleChange2 = (vesselDrawing_available) => {
    this.setState({ vesselDrawing_available });
  };
  onDropAttachment1 = (files) => {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  };
  onDropAttachment2 = (files) => {
    if (files.length > 0) {
      this.setState({ selectedFiles1: files });
    }
  };
  onDropAttachment3 = (files) => {
    if (files.length > 0) {
      this.setState({ selectedFiles2: files });
    }
  };

  //design
  handleChange = (value) => {
    this.setState({ vessel_Design_name: value });
  };
  switchhandleChange3 = (availble) => {
    this.setState({ availble });
  };
  onDropAttachment11 = (files) => {
    if (files.length > 0) {
      this.setState({ attachment1: files });
    }
  };

  onDropAttachment22 = (files) => {
    if (files.length > 0) {
      this.setState({ attachment2: files });
    }
  };

  onDropAttachment33 = (files) => {
    if (files.length > 0) {
      this.setState({ attachment3: files });
    }
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.vessel_Design_name) {
      formIsValid = false;
      errors["vessel_Design_name"] = "*Please choose Vessel Design Name";
    }
    if (!this.state.attachment1) {
      formIsValid = false;
      errors["attachment1"] = "*Please select attachment1";
    }
    if (!this.state.attachment2) {
      formIsValid = false;
      errors["attachment2"] = "*Please select  attachment2";
    }
    if (!this.state.attachment3) {
      formIsValid = false;
      errors["attachment3"] = "*Please select attachment3";
    }
    if (
      !this.state.particularsName ||
      !this.state.vessel_Flag ||
      !this.state.vessel_Year_Built ||
      !this.state.vessel_Type ||
      !this.state.vessel_GRT ||
      !this.state.vessel_DWT ||
      !this.state.vessel_class ||
      !this.state.vessel_Owner
    ) {
      Swal.fire({
        icon: "error",
        text: "Incomplete data in vessel particular tab",
        confirmButtonColor: "#052754",
      }).then((res) => {
        this.toggle("1");
      });
    } else {
      if (
        !this.state.vessel_parameter ||
        !this.state.parameter_value ||
        !this.state.unit
      ) {
        Swal.fire({
          icon: "error",
          text: "Incomplete data in vessel dimension tab",
          confirmButtonColor: "#052754",
        }).then((res) => {
          this.toggle("2");
        });
      } else {
        if (!this.state.vesselEquipment || !this.state.equipmentValue) {
          Swal.fire({
            icon: "error",
            text: "Incomplete data in vessel equipment tab",
            confirmButtonColor: "#052754",
          }).then((res) => {
            this.toggle("3");
          });
        } else {
          if (
            !this.state.material_Name ||
            !this.state.material_Value ||
            !this.state.material_Unit ||
            !this.state.surveyor
          ) {
            Swal.fire({
              icon: "error",
              text: "Incomplete data in vessel material tab",
              confirmButtonColor: "#052754",
            }).then((res) => {
              this.toggle("4");
            });
          } else {
            if (
              !this.state.vesselDrawing_name ||
              !this.state.selectedFiles[0] ||
              !this.state.selectedFiles1[0] ||
              !this.state.selectedFiles2[0]
            ) {
              Swal.fire({
                icon: "error",
                text: "Incomplete data in vessel drawing tab",
                confirmButtonColor: "#052754",
              }).then((res) => {
                this.toggle("5");
              });
            } else {
              if (
                !this.state.vessel_Design_name ||
                !this.state.attachment1[0] ||
                !this.state.attachment2[0] ||
                !this.state.attachment3[0]
              ) {
                Swal.fire({
                  icon: "error",
                  text: "Incomplete data in vessel design tab",
                  confirmButtonColor: "#052754",
                }).then((res) => {
                  this.toggle("6");
                });
              }
            }
          }
        }
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //save

  onSubmit = async () => {
    if (this.validateForm()) {
      let vesselCommision;
     
      if (
        !this.state.vessel_Commissioned ||
        this.state.vessel_Commissioned === undefined
      ) {
        // eslint-disable-next-line no-unused-expressions
        vesselCommision === false;
      } else {
        // eslint-disable-next-line no-unused-expressions
        vesselCommision === this.state.vessel_Commissioned;
      }
      var Particulars = {
        particularsName: this.state.particularsName,
        vesselFlagMaster: {
          id: this.state.vessel_Flag.value,
        },
        vessel_Year_Built: this.state.vessel_Year_Built,
        vesselTypeModel: {
          id: this.state.vessel_Type.value,
        },
        vessel_GRT: this.state.vessel_GRT,
        vessel_DWT: this.state.vessel_DWT,
        vesselClassModel: {
          id: this.state.vessel_class.value,
        },
        vesselOwnerMaster: {
          vessel_Owner_ID: this.state.vessel_Owner.value,
        },
        vessel_Commissioned: vesselCommision,
        cr_id: this.state.cr_id,
        cr_date: this.cr_date,
      };
      var Dimension = {
        parameterModel: {
          id: this.state.vessel_parameter.value,
        },
        parameter_value: this.state.parameter_value,
        unitModel: {
          id: this.state.unit.value,
        },
      };
      let equipments = {
        vesselEquipmentMaster: {
          id: this.state.vesselEquipment.value,
        },
        equipmentValue: this.state.equipmentValue,
      };
      var Material = {
        materialModel: {
          id: this.state.material_Name.value,
        },
        material_Value: this.state.material_Value,
        unitModel: {
          id: this.state.material_Unit.value,
        },
        surveyorModel: {
          id: this.state.surveyor.value,
        },

        // surveyor: this.state.surveyor.value,
        approved: this.state.approved,
      };

      let formData1 = new FormData();
      formData1.append("files", this.state.selectedFiles[0]);
      formData1.append("files", this.state.selectedFiles1[0]);
      formData1.append("files", this.state.selectedFiles2[0]);
      await ImageService.post("addMultiImageFile", formData1).then((res) => {
        if (res.status === 200) {
          let data = res.data;

          this.setState({
            // fileDownloadUri: data.fileDownloadUri,
            // thumbnailUrl: data.thumbnailUrl,
            // fileName: data.filename,
            // thumbnailsFileName: data.thumbnailsFileName
            thumbnail1Name: data.thumbnailsFileName[0],
            thumbnail2Name: data.thumbnailsFileName[1],
            thumbnail3Name: data.thumbnailsFileName[2],
            attachment1name: data.fileNames[0],
            attachment2name: data.fileNames[1],
            attachment3name: data.fileNames[2],
          });
        }
      });

      var drawingformData = {
        drawing_typeModel: {
          id: this.state.vesselDrawing_name.value,
        },
        vesselDrawing_available: this.state.vesselDrawing_available,
        attachment1name: this.state.attachment1name,
        attachment2name: this.state.attachment2name,
        attachment3name: this.state.attachment3name,
        // "thumbnailsFileName": this.state.thumbnailsFileName,
        thumbnail1Name: this.state.thumbnail1Name,
        thumbnail2Name: this.state.thumbnail2Name,
        thumbnail3Name: this.state.thumbnail3Name,
      };
      this.setState({ progress: 0 });

      // let drawingformData = new FormData();
      // drawingformData.append('vesselDrawing', JSON.stringify(drawingrecords));

      // drawingformData.append("vesselDrawing_attachment1", this.state.selectedFiles[0]);
      // drawingformData.append("vesselDrawing_attachment2", this.state.selectedFiles1[0]);
      // drawingformData.append("vesselDrawing_attachment3", this.state.selectedFiles2[0]);
      let formData2 = new FormData();
      formData2.append("files", this.state.attachment1[0]);
      formData2.append("files", this.state.attachment2[0]);
      formData2.append("files", this.state.attachment3[0]);

      ImageService.post("addMultiImageFile", formData2).then((res) => {
        if (res.status === 200) {
          let data = res.data;

          this.setState({
            // fileDownloadUri: data.fileDownloadUri,
            // thumbnailUrl: data.thumbnailUrl,
            // fileName: data.filename,
            // thumbnailsFileName: data.thumbnailsFileName
            dsgThumbnail1Name: data.thumbnailsFileName[0],
            dsgThumbnail2Name: data.thumbnailsFileName[1],
            dsgThumbnail3Name: data.thumbnailsFileName[2],
            dsgAttachment1name: data.fileNames[0],
            dsgAttachment2name: data.fileNames[1],
            dsgAttachment3name: data.fileNames[2],
          });
        }
      });

      this.setState({ progress: 0 });

      // let designformData = new FormData();
      // designformData.append("vesselDesign", JSON.stringify(designrecords));
      // designformData.append("attachment1", this.state.attachment1[0]);
      // designformData.append("attachment2", this.state.attachment2[0]);
      // designformData.append("attachment3", this.state.attachment3[0]);

      let particularid = 0;
      let dimensionid = 0;
      let equipmentid = 0;
      let materialid = 0;
      let drawingid = 0;
      let designid = 0;

      axios
        .post("VesselParticulars", Particulars)
        .then((res) => {
          particularid = res.data;

          AxiosConfig.post("VesselDimension", Dimension).then((res1) => {
            dimensionid = res1.data;

            AxiosConfig.post("VesselEquipment", equipments).then((res2) => {
              equipmentid = res2.data;

              axios.post("VesselMaterial", Material).then((res3) => {
                materialid = res3.data;

                AxiosConfig.post("vesseldrawing", drawingformData)

                  // AxiosConfig.post("vesseldrawing", drawingformData
                  //   , {
                  //     headers: {
                  //       "Content-Type": "multipart/form-data",
                  //     }
                  //   }
                  // )
                  .then((res4) => {
                    drawingid = res4.data;

                    var designformData = {
                      vesselDesignTypeMaster: {
                        id: this.state.vessel_Design_name.value,
                      },
                      availble: this.state.availble,
                      attachment1name: this.state.dsgAttachment1name,
                      attachment2name: this.state.dsgAttachment2name,
                      attachment3name: this.state.dsgAttachment3name,
                      thumbnail1Name: this.state.dsgThumbnail1Name,
                      thumbnail2Name: this.state.dsgThumbnail2Name,
                      thumbnail3Name: this.state.dsgThumbnail3Name,
                    };

                    AxiosConfig.post("VesselDetailDesign", designformData)
                      //   headers: {
                      //     "Content-Type": "multipart/form-data",
                      //   }
                      // })
                      .then((res5) => {
                        designid = res5.data;

                        let alldetails = {
                          vesselParticularsModel: {
                            // "addressId": this.state.addressId
                            id: particularid,
                          },
                          vesselDimensionModal: {
                            id: dimensionid,
                          },
                          vesselEquipmentModel: {
                            id: equipmentid,
                          },
                          vesselMaterialModel: {
                            id: materialid,
                          },
                          vesselDrawingModel: {
                            id: drawingid,
                          },
                          vesselDesignModel: {
                            id: designid,
                          },

                          // vessel_particulars_id: sessionStorage.getItem('vessel_particulars_id'),
                        };
                        axios.post("VesselDetails", alldetails).then((res) => {
                          Swal.fire({
                            icon: "success",
                            text: "Data Saved Successfully",
                            confirmButtonColor: "#052754",
                          }).then((res) => {
                            // this.onReset();
                            this.toggle("1");
                          });
                        });
                      });
                  });
              });
            });
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: "Something went wrong!!",
            confirmButtonColor: "#052754",
          });
        });
    } else {
      this.validateForm();
    }
  };
  validation = (toggle) => {
    if (toggle === "2") {
      if (this.validateFormParticular() === true) {
        this.toggle("2");
      } else {
        this.validateFormParticular();
      }
    } else if (toggle === "3") {
      if (this.validateFormDimension() === true) {
        this.toggle("3");
      } else {
        this.validateFormDimension();
      }
    } else if (toggle === "4") {
      if (this.validateFormEquipment() === true) {
        this.toggle("4");
      } else {
        this.validateFormEquipment();
      }
    } else if (toggle === "5") {
      if (this.validateFormMaterial() === true) {
        this.toggle("5");
      } else {
        this.validateFormMaterial();
      }
    } else if (toggle === "6") {
      if (this.validateFormDrawing() === true) {
        this.toggle("6");
      } else {
        this.validateFormDrawing();
      }
    }
  };
  validateFormParticular = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.vessel_Flag) {
      formIsValid = false;
      errors["vessel_Flag"] = "*Please choose Vessel Flag";
    }
    if (!this.state.vessel_class) {
      formIsValid = false;
      errors["vessel_class"] = "*Please choose Vessel Class";
    }
    if (!this.state.vessel_Owner) {
      formIsValid = false;
      errors["vessel_Owner"] = "*Please choose Vessel owner";
    }
    if (!this.state.vessel_Type) {
      formIsValid = false;
      errors["vessel_Type"] = "*Please choose Vessel Type";
    }
    this.setState({
      errors: errors,
    });

    return formIsValid;
  };
  validateFormDimension = () => {
    let errors1 = {};
    let formIsValid = true;
    if (!this.state.vessel_parameter) {
      formIsValid = false;
      errors1["vessel_parameter"] = "*Please Choose Vessel Parameter";
    }

    if (!this.state.unit) {
      formIsValid = false;
      errors1["unit"] = "*Please Choose Parameter Value";
    }

    this.setState({
      errors1: errors1,
    });
    return formIsValid;
  };

  validateFormEquipment() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.vesselEquipment) {
      formIsValid = false;
      errors["vesselEquipment"] = "*Please choose vessel equipments";
    }
    if (!this.state.equipmentValue) {
      formIsValid = false;
      errors["equipmentValue"] = "*Please enter equipment value";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validateFormMaterial = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.material_Name) {
      formIsValid = false;
      errors["material_Name"] = "*Please Choose Material Name";
    }

    if (!this.state.material_Value) {
      formIsValid = false;
      errors["material_Value"] = "*Please Choose Material Value";
    }

    if (!this.state.material_Unit) {
      formIsValid = false;
      errors["material_Unit"] = "*Please Choose Material Unit";
    }

    if (!this.state.surveyor) {
      formIsValid = false;
      errors["surveyor"] = "*Please Choose Surveyor";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  validateFormDrawing = () => {
    let errors = {};
    let formIsValid = true;

    if (!this.state.vesselDrawing_name) {
      formIsValid = false;

      errors["vesselDrawing_name"] = "*Please choose vessel drawing type";
    }

    if (!this.state.selectedFiles && !this.state.vesselDrawing_attachment1) {
      formIsValid = false;
      errors["selectedFiles"] = "*Please select attachment1";
    }

    if (!this.state.selectedFiles1 && !this.state.vesselDrawing_attachment2) {
      formIsValid = false;
      errors["selectedFiles1"] = "*Please select attachment2";
    }

    if (!this.state.selectedFiles2 && !this.state.vesselDrawing_attachment3) {
      formIsValid = false;
      errors["selectedFiles2"] = "*Please select attachment3";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  render() {
    return (
      <div className="TablistWp card p-2" style={{ border: "none" }}>
        <Nav tabs className="NavTabs">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
              // onClick={() => { this.validation() }}
            >
              Particulars
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
              // onClick={() => { this.validation('2') }}
            >
              Dimensions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
              // onClick={() => { this.validation('3') }}
            >
              Equipments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "4" })}
              onClick={() => {
                this.toggle("4");
              }}
              // onClick={() => { this.validation('4') }}
            >
              Material
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "5" })}
              onClick={() => {
                this.toggle("5");
              }}
              // onClick={() => { this.validation('5') }}
            >
              Drawings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "6" })}
              onClick={() => {
                this.toggle("6");
              }}
              // onClick={() => { this.validation('6') }}
            >
              Designs
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '7' })}
              onClick={() => { this.toggle('7'); }}>
              Inspection
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12" className="mt-5">
                <AddVesselParticulars
                  test={this.handleParticular}
                  handleChange1={this.handleChange1}
                  handleChange2={this.handleChange2}
                  handleChange3={this.handleChange3}
                  handleChange4={this.handleChange4}
                  switchhandleChange={this.switchhandleChange}
                  inputData={this.state}
                  next={() => this.toggle("2")}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col className="mt-5">
                <AddVesselDimensions
                  onParameterChange={this.onParameterChange}
                  test={this.handleDimension}
                  onUnitChange={this.onUnitChange}
                  inputData={this.state}
                  next={() => this.toggle("3")}
                  errors1={this.state.errors1}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col className="mt-5">
                <AddVesselEquipments
                  test={this.handleEquipments}
                  onInputChange={this.onInputChange}
                  inputData={this.state}
                  next={() => this.toggle("4")}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col className="mt-5">
                <AddVesselMaterial
                  inputData={this.state}
                  test={this.handleMaterial}
                  onMaterialChange={this.onMaterialChange}
                  onUnitChange={this.onUnitChange1}
                  onSurveyorChange={this.onSurveyorChange}
                  SwitchhandleChange={this.SwitchhandleChange1}
                  next={() => this.toggle("5")}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col className="mt-5">
                <AddVesselDrawing
                  inputData={this.state}
                  test={this.handleDrawings}
                  onInputChange={this.onInputChange1}
                  switchhandleChange={this.switchhandleChange2}
                  onDropAttachment1={this.onDropAttachment1}
                  onDropAttachment2={this.onDropAttachment2}
                  onDropAttachment3={this.onDropAttachment3}
                  next={() => this.toggle("6")}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col className="mt-5">
                <AddVesselDesign
                  inputData={this.state}
                  test={this.handleDesigns}
                  handleChange={this.handleChange}
                  switchhandleChange={this.switchhandleChange3}
                  onDropAttachment1={this.onDropAttachment11}
                  onDropAttachment2={this.onDropAttachment22}
                  onDropAttachment3={this.onDropAttachment33}
                  onSubmit={this.onSubmit}
                  next={() => this.toggle("7")}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="7">
            <Row>
              <Col className="mt-2">
                <AddVesselInspectionDetails />
              </Col>
            </Row>
          </TabPane> */}
        </TabContent>
        {/* <Row>
          <Col className="text-center mt-5">
            <div className="center-block text-center">
              <Button className="text-center mr-3  btnblue">Submit</Button>
              <Button className="text-center " color="danger">Cancel</Button>
            </div>
          </Col>
        </Row> */}
      </div>
    );
  }
}
