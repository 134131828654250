import React, { Component } from 'react'
import AddVesselDrawing from '../VesselDetails/AddVesselDrawing'
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import Dropzone from 'react-dropzone';

export default class FileDropzone extends Component {

    constructor(props) {

        super(props);


    }

    

    render() {

        return (
            <div>
                <Form>
                    <Row>
                        <Col>

                            <Dropzone onDrop={this.props.ondrop} multiple={true} >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            {(this.props.selectedfiles && this.props.selectedfiles[0].name) || this.props.fileinfo ? (
                                                <div className="selected-file">


                                                    {(this.props.selectedfiles && this.props.selectedfiles[0].name) || this.props.fileinfo}
                                                </div>
                                            ) : (
                                                "Attachment"
                                            )}
                                        </div>

                                    </section>
                                )}
                            </Dropzone>


                        </Col>
                    </Row>
                </Form>

            </div>
        )
    }
}
