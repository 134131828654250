
import React from "react";
// import '../Course/CourseMaster.css'
import { Card, Col, Row, Container } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
// import axios from 'axios';
import axios from "../../service/axiosConfig";
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';


const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}


export default class vessel_paramter_master extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectParameter: [
        { value: 'Dimension' },
        { value: 'Width' }
      ],
      multiSelctParameter: [{ value: 'Dimensions', menu: [{ value: "length" }, { value: "width" }] }],

      columns: [
        { title: "Id", field: "id", hidden: true },
        {
          title: 'Sr No', field: 'tableData.id', render: rowData => {
            return (<p>{rowData.tableData.id + 1}​​</p>)
          }
        },
        { title: "Parameter Name", field: "parameterName" },
        // { title: "Value", field: "Value"},

      ],

      message: "",
      rowData: [],
      show: false,
      Data:[]
    }
  }
  componentDidMount() {
    this.refreshList();
  }
  refreshList = () => {
    axios.get("parameter")
      .then(Response => {
        this.setState({ Data: Response.data });
        console.log(Response.data);
      })
  }

  selectedValue = (value) => {
    this.setState({ selectedValue: value })
  }

  updateParameter(id) {
    this.props.history.push("/AddParameter/" + id);
  }
  deleteParameter = (id) => {
    axios.delete("parameter" + '/' + id)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.refreshList();
      })

    this.setState({ message: "Vessel Parameter Deleted Successfully" });
    this.outMessage();
  }

  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  render() {
    const { rowData, Data, columns } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message && (
          <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
            <a
              href="ParameterListTable" className="close" data-dismiss="alert" aria-label="close"> &times;
            </a>
            {this.state.message}
          </div>)}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Row className=" mb-3">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="">
                  <h3 className="text-center blueText">Parameter List</h3>
                </Col>
              </Row>
              <Row>
                <Col className="DashboardTbl">
                  <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.updateParameter(rowData.id)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            //this.props.history.push(`/Page?id=${rowData._id}`)
                            this.setState({ show: true, rowData: rowData })
                          }

                        }

                      ]
                    }


                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>
        <DeleteConfirm show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.parameterName}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteParameter} />

      </Container>
    );
  }

}
