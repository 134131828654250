import React, { Component } from 'react'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Switch from "react-switch";
import Select from 'react-select';
import axios from '../../service/axiosConfig.js';
import $ from 'jquery';
import { AxiosConfig } from '../Common/AxiosConfig.js';

export default class UpdateVesselMaterial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            material_Name: "",
            material_Value: "",
            material_Unit: "",
            surveyor: "",
            approved: false,
            cr_id: "",
            cr_date: "",
            selectedName: [],
            selectedUnit:[],
            errors: {},
            message:"",
            materialId: sessionStorage.getItem('materialId')
        }
    }
    validateForm = () => {

        let error = {};
        let formIsValid = true;
        if (!this.state.material_Name) {
            formIsValid = false;
            error["material_Name"] = "*Please Choose Material Name";
        }


        if (!this.state.material_Value) {
            formIsValid = false;
            error["material_Value"] = "*Please Choose Material Value";
        }


        if (!this.state.material_Unit) {
            formIsValid = false;
            error["material_Unit"] = "*Please Choose Material Unit";
        }

        if (!this.state.surveyor) {
            formIsValid = false;
            error["surveyor"] = "*Please Choose Surveyor";
        }

        this.setState({
            errors: error
        });
        return formIsValid;
    }
    async getMaterialName() {
        await axios.get("material").then((res)=>{

        const data = res.data
        const materialOptions = data.map(d => ({
            "value": d.id,
            "label": d.materialName
        }))
        this.setState({ selectedName: materialOptions })
            axios.get("unit").then((res1)=>{

            const unitOptions = res1.data.map(d => ({
                "value": d.id,
                "label": d.unitName
            }))
            this.setState({ selectedUnit: unitOptions })
           
                  axios.get("surveyor").then((res3)=>{

                 
                    const surveyorOptions = res3.data.map(d => ({
                        "value": d.id,
                        "label": d.surveyorName
                    }))
                    this.setState({ selectedSurveyor: surveyorOptions })
                      this.getMaterialById()
                  })
                
            })
       
        })
    }
    componentDidMount() {
        this.getMaterialName();
        
    }
    getMaterialById() {
        AxiosConfig.get("VesselMaterial/" + this.state.materialId)
            .then((res) => {
                console.log(res.data);
                this.setState({
                  material_Name: res.data.materialModel.id,
                  material_Value: res.data.material_Value,
                  material_Unit: res.data.unitModel.id,
                  surveyor: res.data.surveyorModel.id,
                  approved: res.data.approved,
                  cr_id: res.data.cr_id,
                  cr_date: res.data.cr_date,
                });
                const valueToSet = this.state.selectedName.find((row) => {
                    return row.value === this.state.material_Name;
                });
                if (valueToSet) {
                    this.onMaterialChange(valueToSet);
                }
                const valueToSet1 = this.state.selectedUnit.find((row) => {
                    return row.value === this.state.material_Unit;
                });
                if (valueToSet1) {
                    this.onUnitChange(valueToSet1);
                }
                const valueToSet2 = this.state.selectedSurveyor.find((row) => {
                    return row.value === this.state.surveyor;
                });
                if (valueToSet2) {
                    this.onSurveyorChange(valueToSet2);
                }
            })
    }
    onSurveyorChange = (obj) => {
        this.state.surveyor = obj
        this.setState({ surveyor: obj })
       
    }
    onUnitChange = (obj) => {
        this.setState({ material_Unit: obj })
      
    }
    onMaterialChange = (obj) => {
        this.setState({ material_Name: obj })

    }
    SwitchhandleChange = (approved) => {
        this.setState({ approved });
    }
    onClear=()=>{
        this.setState({
            material_Name: "",
            material_Value: "",
            material_Unit: "",
            surveyor: "",
            approved: false,
        })
       
    }
    updateMaterial=(e)=>{
        e.preventDefault();
        var Material = {
            id: this.state.materialId,
            materialModel:{
                id: this.state.material_Name.value
            },
           unitModel:{
               id:this.state.material_Unit.value
           },
            material_Value: this.state.material_Value,
           surveyorModel:{
               id:this.state.surveyor.value
           },
           
            approved: this.state.approved,
            cr_id: this.state.cr_id,
            cr_date: this.state.cr_date
        }
        if (this.validateForm()){

        axios.put("VesselMaterial/" + this.state.materialId, Material)
            .then(result => {
                // this.setState(result)
                this.setState({
                    message: `Vessel Material Updated Successfully!!`, id: ""
                });
               
              
                this.onClear();
            })
        }
        this.outMessage();

    }
    outMessage = () => {
        $(".alert").show();
        setTimeout(function () {
            $(".alert").hide();
        }, 1000);
        this.setState({
            message: "",
           
        })
    }
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    render() {
        return (
            <div>
                <Container fluid className=" FormWp formWpr">

                    <h6>
                        {this.state.message && (
                            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                                <a
                                    href="/VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;
                                </a>
                                {this.state.message}
                            </div>)}
                    </h6>

                    <Card className="shadow p-4 col-md-8 mx-auto">
                        <Row>
                            <Col className="mb-4">
                                <h3 className="text-center blueText"> Vessel Material</h3>
                            </Col>
                            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                        </Row>
                        <Form
                        //  onSubmit={this.addOrUpdate}
                        onSubmit={ this.updateMaterial }
                        >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>



                                        <Label className="required">Vessel Material</Label>
                                        {/* <DropDown title="Vessel Material" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}

                                        {/* <Select options={this.state.selectedName}
                    name="material_Name"
                    onChange={this.onMaterialChange}
                    selectedVal={this.selectedName}
                  /> */}

                                        <br />
                                        <Select required
                                            value={this.state.material_Name}
                                            onChange={this.onMaterialChange}
                                            options={this.state.selectedName}
                                            name="material_Name"
                                        />
                                        {
                                            this.state.errors.material_Name &&

                                            <div className="errorMsg">{this.state.errors.material_Name} </div>}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label className="required"> Material Value</Label>
                                        <Input type="text"
                                            name="material_Value" value={this.state.material_Value}
                                            placeholder="Enter Material Value"
                                            autoComplete="false"
                                            onChange={e => { this.onInputChange(e); }}
                                        />

                                        {
                                            this.state.errors.material_Value &&

                                            <div className="errorMsg">{this.state.errors.material_Value} </div>}

                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Label className="required">Unit</Label>
                                    {/* <DropDown title="Select Vessel Built Year" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}

                                    {/* <Select options={this.state.selectedUnit}
                  // name="material_Name"
                  onChange={this.onUnitChange} required
                /> */}
                                    <Select required
                                        value={this.state.material_Unit}
                                        onChange={this.onUnitChange}
                                        options={this.state.selectedUnit}
                                        name="material_Unit"
                                    />
                                    {
                                        this.state.errors.material_Unit &&

                                        <div className="errorMsg">{this.state.errors.material_Unit} </div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label className="required"> Surveyors </Label>
                                    {/* <DropDown title="Surveyor " options={VesselYearBuilt} value={this.state.material_Name}
                  onChange={this.handleChange}  options={Surveyors} /> */}

                                    {/* <Select options={this.state.selectedSurveyor}
                  // name="material_Name"
                  onChange={this.onSurveyorChange} required
                /> */}
                                    <Select required
                                        value={this.state.surveyor}
                                        onChange={this.onSurveyorChange}
                                        options={this.state.selectedSurveyor}
                                        name="surveyor"
                                    />
                                    {
                                        this.state.errors.surveyor &&

                                        <div className="errorMsg">{this.state.errors.surveyor} </div>
                                    }

                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Material Approved</Label><br />
                                        <Switch checked={this.state.approved}
                                            onChange={this.SwitchhandleChange} icon={false}
                                        />
                                    </FormGroup>
                                </Col>

                                <Row>
                                    <Col md={6} className="AddBtnCol">

                                        <Button>UPDATE</Button>
                                    </Col>


                                    <Col md={6} className="AddBtnCol">
                                        <div className="center-block text-center">
                                            <Button className="text-center "
                                                onClick={this.onClear}
                                                color="danger">Clear</Button>
                                        </div>
                                    </Col>
                                </Row>

                                {/* <div style={{ color: "red" }}>{this.state.errormessage}</div>
              <div style={{ color: "green" }}>
                <NotificationContainer />
              </div> */}
                            </Row>

                            {/* <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Submit</Button>
                  <Button type="reset" className="text-center " color="danger" onClick={this.onReset}>Clear</Button>
                </div>
              </Col>
            </Row> */}
                        </Form>
                    </Card>

                </Container>

            </div>
        )
    }
}
