import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
//  const [menucollapsed, setmenuCollapsed] = useState(true);
  const [menucollapsed, setmenuCollapsed] = useState([]);
  const toggle = () => setCollapsed(!collapsed);
  //const menutoggle = () => setmenuCollapsed(!menucollapsed);
  const toggleCollapse = (index) => {
    let collapseCopy = [...menucollapsed];
    collapseCopy[index] = !collapseCopy[index];
    setmenuCollapsed(collapseCopy);
  }
  const { icon, title, items } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle">
          <FontAwesomeIcon icon={icon} className="mr-4" />
          {title}
        </NavLink>
      </NavItem>
      <Collapse isOpen={!collapsed} navbar className={classNames("items-menu", { "mb-1": !collapsed })} >
        {items.map((item, index) => (
          <NavItem key={index} onClick={() => item.menu ? toggleCollapse(index) : null}  className={classNames({ " menu-open": menucollapsed })}>
            <NavLink tag={Link} to={item.target} className={item.menu ? "dropdown-toggle" : null}>
              {item.title}
              {item.menu ?
              <Collapse
                isOpen={menucollapsed[index] }  navbar
                className={classNames("items-menu submenucollpse", { "mb-1": menucollapsed })}
              >
              {item.menu.map((m,index) =>
                <NavItem key={index}>
                  <NavLink tag={Link} to={m.target}>{m.title}</NavLink>
                </NavItem>
              )}
              </Collapse> : null}
            </NavLink>
          </NavItem>
        ))}
       
      </Collapse>
    </div>
  );
};

export default SubMenu;