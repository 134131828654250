
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import DeleteConfirm from "../../Containers/Common/deleteComf.js";
import { AxiosConfig } from "../Common/AxiosConfig";

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default class InspectionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Id", field: "inspection_Type_id", hidden: true },
        {
          title: "Sr No",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
        },
        { title: "Inspection Type Name", field: "inspectionTypeName" },
      ],
      inspection_Type_id: this.props.match.params.id,
      cr_id: "",
      cr_date: "",
      inspectionTypeName: "",
      rowData: [],
      show: false,
      close: false,
      dmessage: "",
      Data: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    AxiosConfig.get("InspectionTypeMaster").then((response) => {
      this.setState({ Data: response.data });
    });
  };

  selectOne = (inspection_Type_id) => {
    debugger;
    AxiosConfig.get("InspectionTypeMaster/" + inspection_Type_id).then(
      (response) => {
        this.props.history.push("/InspectionTypeMaster/" + inspection_Type_id);
      }
    );
    // window.location.reload(false);
  };

  delete = (inspection_Type_id) => {
    AxiosConfig.delete(
      "InspectionTypeMaster/" + inspection_Type_id,
      this.state
    ).then((response) => {
      if (response === 201) {
        console.log(response);
      }
      this.getData();
      this.setState({ dmessage: "Inspection Type Deleted Successfully" });
    });
  };

  refresh() {
    setTimeout(function () {
      window.location.reload(false);
    }, 3000);
  }
  // selectedValue = (value) =>{
  //   this.setState({selectedValue:value})
  // }
  render() {
    const { Data, columns, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        {/* delete message */}
        <h6>
          {this.state.dmessage && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="InspectionList"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.dmessage}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Inspection List</h3>
            </Col>
          </Row>
          <Row className="mt-5 mb-3">
            <Col md={8} className="tableWp mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={Data}
                    pageSize={5}
                    paging={true}
                    columns={columns}
                    search={false}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="300px"
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          this.selectOne(rowData.inspection_Type_id);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          this.setState({ show: true, rowData: rowData });
                        },
                      },
                      //,
                      // {
                      //   icon: 'visibility',
                      //   tooltip: 'View',
                      //   onClick: (event, rowData) => {
                      //     this.props.history.push(`/Page?id=${rowData._id}`)
                      //}
                      //}
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <DeleteConfirm
          show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.inspection_Type_id}
          name={rowData.inspection_Type_name}
          close={() => this.setState({ show: false })}
          deleteHandler={this.delete}
        />
      </Container>
    );
  }
}