import React from "react";
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from '../../service/axiosConfig.js';
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import $ from 'jquery';

const springUrl = '/material';
const validateUrl = 'material/name';

export default class MaterialMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      materialName: "",
      crID: "",
      crDate: "",
      message: "",
      title: ""
    }
  }

  changeHandler = (e) => {
    let { name, value } = e.target;
    value = value.replace(/[^A-z\d\s]/ig, '');
    this.setState({ [name]: value });
  }

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    if (this.state.id == null) {
      axios.post(springUrl, this.state)
        .then(response => {
          if (response === 201) {
            console.log(response);
          }
          this.setState({ message: `Material ${this.state.materialName} Added Successfully!!`, materialName: "" });
        })
      console.log("submitted Success");
      this.outMessage();
    }
    else if (this.state.id != null) {
      axios.put(springUrl + "/" + this.state.id, this.state)
        .then(response => {
          if (response === 201) {
            console.log(response);
          }
          this.setState({ message: `Material ${this.state.materialName} Updated Successfully..`, materialName: "" });
        })
      this.outMessage();
    }
    this.setState({});

  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/MaterialMasterList');
  }

  componentDidMount() {
    if (this.state.id != null) {
      this.setState({ title: "Update" });
      axios.get(springUrl + "/" + this.state.id)
        .then(response => {
          let mat = response.data;
          this.setState({
            id: mat.id, materialName: mat.materialName,
            crID: mat.crID, crDate: mat.crDate
          });
        });
    }
    else {
      this.setState({ title: "Add" });
    }
  }

  reset = () => {
    this.setState({
      materialName: "",

    });
  };

  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  render() {
    const { id, materialName, crID, crDate, title } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message && <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
          <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>{this.state.message}</div>}</h6>
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Material</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.submitHandler}>
            <Input type="hidden" required value={id} />
            <Input type="hidden" required value={crID} />
            <Input type="hidden" required value={crDate} />
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label className="required">Material Name</Label>
                  <Input type="text" name="materialName" placeholder=" Enter Material Name" required value={materialName} onChange={this.changeHandler} />
                  <ValidateMaterial url={validateUrl} name={materialName} id={id}></ValidateMaterial>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>
                  <Button className="text-center " color="danger" onClick={this.reset}>Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }

}