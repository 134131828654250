import React from 'react'
import { Container, Card, Row, Col, Button } from 'reactstrap'
import Header from '../../Components/header/Header'
import Banner from './Components/Banner'
import ServicesCard from './Components/ServicesCard'
import privateShip from '../../assets/privateboat.jpg'
import ServiceProviders from './Components/ServiceProviders'
import Ship from '../../assets/ship2.jpg'
import Ship1 from '../../assets/ship.jpg'
import Ship2 from '../../assets/ship3.jpg'
import ShippingLine from '../../assets/ShippingLine.png'
import Navl from '../../assets/Navl.png'
import Logi from '../../assets/Logi.png'
import MarineIsnstitute from '../../assets/MarineIsnstitute.png'
import Custom from '../../assets/Custom.png'
import society from '../../assets/society.png'
import classifiction from '../../assets/classifiction.png'
import cruize from '../../assets/cruize.png'
import PostReq from './Components/PostReq'
import Testimonial from './Components/Testimonial'
import ValuableClient from './Components/ValuableClient'
import Footer from '../../Components/footer/Footer'
export default class Home extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div>
                    <Banner />
                </div>
                {/* <div class="wshipping-content-block mt-3">
                    <Container>
                        <Row>
                            <Col md={12}>
                            <div class="section-title wow fadeInUp">
                                <h2>Ship Maintenance</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <div className="wshipping-content-blockWp">
                    <Container>
                        <Row>
                            <Col>
                                <div className="section-title wow fadeInUp">
                                    <h2>Ship Maintenance</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} xs={12}>
                                <ServicesCard servicesTitle="Ship Hull Material" servicesText="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServicesCard servicesTitle="Power &amp; Propulsion System" servicesText="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServicesCard servicesTitle="Pipe &amp; Piping System" servicesText="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServicesCard servicesTitle="Diving Equipments" servicesText="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                            </Col>

                        </Row>
                        <Row>
                            <Col className="text-center"><Button className="LoadMoreBtn pl-4 pr-4">View More...</Button>
                            </Col>
                        </Row>
                    </Container>

                </div>
                {/* /*We Provide Section start*/}
                <div className="wshipping-content-blockWp provided-block text-center">
                    <div className="PrivateimgWp">
                        <img src={privateShip} />
                    </div>
                    <div className="item-table">
                        <div className="item-tablecell">
                            <Container>
                                <Row>
                                    <div className="col-12 wow fadeInUp" style={{ visibility: 'visible' }}>
                                        <h1 className="text-uppercase">WE OFFER WEB BASED PORTAL TO</h1>
                                        <p className="paratext">MARINE PRODUCTS/EQUIPMENTS ,MANUFACTURER ,SUPPLIERS,DISTRIBUTERS,SERVICE PROVIDERS,SHIPPING AGENCIES,MARITIME AUTHORITIES,INSTITUES ETC</p>
                                        <a href="#" class="wshipping-button cta-btn">Contact WIth Us</a> </div>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
                <div className="wshipping-content-blockWp">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div class="section-title wow fadeInUp">
                                    <h2>Shipping Companies/Service Providers</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col md={3} xs={12}>
                                <ServiceProviders image={ShippingLine} text="Shipping Line" />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={MarineIsnstitute} text="Marine Institutes" />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={classifiction} text="Classification Societies" />
                            </Col>
                            <Col md={3} xs={12} >
                                <ServiceProviders image={privateShip} text="Custom" />
                            </Col>
                            {/* <Col>
                            <ServiceProviders image={Custom} text="Custom House Agent"/> 
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={cruize} text="Cruise Lines" />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={Logi} text="Logistic Companies" />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={Ship} text="Freight Forwarders" />
                            </Col>
                            <Col md={3} xs={12}>
                                <ServiceProviders image={Ship2} text="Naval Architects" />
                            </Col>
                            {/* <Col>
                            <ServiceProviders image={Ship} text="shipping"/> 
                            </Col> */}
                        </Row>
                        <Row className="mt-5 text-center">
                            <Col><Button className="LoadMoreBtn pl-4 pr-4">View More...</Button>
                            </Col>
                        </Row>
                    </Container>

                </div>
                <div className="wshipping-content-blockWp">
                    <div className=" container PostReqWp">
                        <PostReq />
                    </div>
                </div>

                <div>
                    <Testimonial />
                </div>
                <div className="container mb-5">
                    <Row className="mt-5">
                        <Col md={12}>
                            <div class="section-title wow fadeInUp">
                                <h2>Our Valueable Client</h2>

                            </div>
                        </Col>
                    </Row>
                    <ValuableClient />
                </div>
                <Footer />
            </div>
        );
    }
}