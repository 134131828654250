import react from 'react'
import {Input}  from 'reactstrap'
import shipbg from '../../../assets/s.jpg'
const PostReq = () =>{
    return(

    <div>
    <div className="wshipping-content-block home-quote">
        <div className="shipbg">
            <img src={shipbg}/>
        
    <div className="container postreqForm">
      <div className="row">
        <div className="col-md-6 wow fadeInLeft postfrm" >
          <h2>Post Your Requirements</h2>
          <p className="postreqText">If You do not spent time to searching the products you are looking to buy or sell.you can simply post your 
              requirement by filling few details below.
          </p>
          <div className="quote-form">
            <form data-toggle="validator">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Input className="form-control" data-error="Please enter name field." id="inputName" placeholder="Full Name" type="text" required=""/>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <Input type="email" class="form-control" id="inputEmail" placeholder="Email" required=""/>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <Input type="text" class="form-control" id="inputMobile" placeholder="Mobile" required=""/>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <textarea className="form-control text-area" data-error="Please enter Message." id="textMessage" placeholder="Message" required=""></textarea>
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <button className="wshipping-button cta-btn" type="submit">Describe Your Requirements</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    );
}
export default  PostReq;