import React, { useState } from "react";
import MultiSelect from "react-multi-select-component";


const MultiSelectionDropDown = (props) => {
  
  console.log("PropsData",props);
  const optionValue = props.surClass;
  const optionSelected = props.seloption;
  const options =  props.options;



  const [selected, setSelected] = useState([]);

  //console.log("selected",selected);

  const handleChange = (option) =>{
    //console.log(option);
    // setSelected({optionValue : option});
    setSelected(option);
    props.optionHandler(option);
  }

  React.useEffect(() =>{
    console.log("optionSelected", optionSelected);
    setSelected(optionSelected);
  },[optionSelected]);

  
  return (
    <div style={{zindex:'600'}}>
     
      {/* <pre>{JSON.stringify(selected)}</pre> */}
      <MultiSelect
        options={options}
        value={selected}
        onChange={handleChange}
       // onChange={setSelected}
        labelledBy={"Select"}
      />
    </div>
  );
};

export default MultiSelectionDropDown;