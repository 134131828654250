
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';

export default  class SurveyorDetailConfirmation extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }
render(){
 
  const{SelectGender,SelectYear,SelectMonth,Data,columns} =this.state;
    return(

    <Container fluid className=" FormWp">
     <Card className="shadow p-5 col-md-8 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Surveyor Details Confirmation</h3>
         </Col>
       </Row>
       <Form>
       <Row>
           <Col md={6}>
            <FormGroup>
                  <Label className="DetailText">surveyor Name</Label>
              <Label>John</Label>
            </FormGroup>
           </Col>
           <Col md={6}>
            <FormGroup>
              <Label className="DetailText">Address 1</Label>
              <Label>Dombivali,Mumbai</Label>
            </FormGroup>
           </Col>
        </Row>
        <Row>
           <Col md={6}>
            <FormGroup>
              <Label className="DetailText">Address 2</Label>
              <Label>Dombivali,Mumbai</Label>
            </FormGroup>
           </Col>
           <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Address 3</Label>
                    <Label>Dombivali,Mumbai</Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">City</Label>
                <Label>Mumbai</Label>
                </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                <Label className="DetailText">State</Label>
                <Label>MH</Label>
              </FormGroup>
          </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Country</Label>
                    <Label>India</Label>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Office Phone No 1</Label>
                <Label>022343456</Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
           
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Office Phone No 2</Label>
                    <Label>022343456</Label>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Official Website</Label>
                   <Label>www.softlabsgroup.com</Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Email</Label>
                 <Label>test123@gmail.com</Label>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Contact Person</Label>
                <Label>John</Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Contact No</Label>
                <Label>9178786767</Label>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                <Label className="DetailText">Contact Person's Email</Label>
                    <Label>test@gmail.com</Label>
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col className="text-center mt-2">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Back</Button>
            {/* <Button className="text-center " color="danger">Reset</Button> */}
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
       
    </Container>
    );
}
} 
