
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import Select from 'react-select';
const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
  const vesselclass = [
    { value: "IRS", label: 'IRS' },
    { value: "IRS", label: 'IRS' },
    { value: "IRS", label: 'IRS' },

  ];
  const DrwType = [
    { value: "Midship section", label: 'MidShip Section' },
    { value: "Transverse Bulkheads", label: 'Transverse Bulkheads' },
    { value: "Engine Room Layout", label: 'Engine Room Layout' },
    { value: "Typical Transverse Sections", label: 'Typical Transverse Sections' },

  ];


export default  class VesselClassMaster extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
          checked: false ,
          columns: [  
            { title: "Id", field: "id" , hidden: true },
            { title: "Drawing Type", field: "Drawing"},
  
           
          ],
          Data : [
            {Id: "01",Drawing:"Midship Section"},
            {Id: "02",Drawing:"Transverse Bulkheads"},
            {Id: "03",Drawing:"Engine Room Layout"},
            {Id: "04",Drawing:"Typical Transverse Sections"},
          ],
          
        }

  }

   handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
  };
 
  render(){
    const{columns,Data,selectedOption,} =this.state;
   
    return(
    <Container fluid className="formWpr">
       <Card className="shadow p-4 col-md-8 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Class Master</h3>
         </Col>
         {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
       </Row>
       <Form>
        <Row>
            <Col md={12}>
                <FormGroup>
                <Label> Vessel Class Name</Label>
                <Input type="text" placeholder="Enter Class Name"/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col md={11}>
                <FormGroup>
                    <Label>Vessel Drawing Type</Label>
                    <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={DrwType}
                  />
                </FormGroup>
            </Col>
            <Col md={1}>
              <Button className="bluebtn mt-4">+</Button>
            </Col>
        </Row>
       <Row className="mt-4 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={8} paging={false} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='350px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/AddVesselSurveyors?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/AddVesselSurveyors?id=${rowData._id}`)
                        }
                      },
                     
                    ]
                  }
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row>
        <Row>
          <Col className="text-center mt-5">
              <div  className="center-block text-center">
                <Button className="text-center mr-3  btnblue">Submit</Button>
                <Button className="text-center " color="danger">Cancel</Button>
            </div>
          </Col>
        </Row>
       </Form>
       </Card>
      
    </Container>
    );
}

}