
import React from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import Table from '../../Containers/Common/Table'
import '../../Containers/Common/Table.css';
import DeleteConfirm from '../../Containers/Common/deleteConf.js';
// import axios from 'axios';
import axios from "../../service/axiosConfig";
import $ from 'jquery';
const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

const SpringUrl = "drw_type";

export default class VesselDrawingTypeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      columns: [
        // { title: "Id", field: "id" , hidden: true }
        // { title: "Sr No", field: "id" },
        {

          title: 'Sr No', field: 'tableData.id',

          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }

        },
        { title: "Drawing Type Name", field: "drwTypeName" },

      ],
      Data: [
        //{Id: "01",DrawingTypeName:"ABC"},
        //{Id: "02",DrawingTypeName:"PQR"},
        //{Id: "03",DrawingTypeName:"XYZ"},
        //{Id: "04",DrawingTypeName:"GHI"},
      ],
      showMod: false,
      rowData: [],
      message: "",
      

    }
  }
  componentDidMount() {
    this.refreshList();
  }
  refreshList() {
    axios.get(SpringUrl)
      .then(Response => {
        this.setState({ Data: Response.data });
        console.log(Response.data);
      })
  }

  //function for alert suto close

  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }
  selectedValue = (value) => {
    this.setState({ selectedValue: value })
  }

  //for update
  updateDrw_type(id) {
    this.props.history.push("/VesselDrawingTypeMaster/" + id);
  }


  deleteDrw_type = (id) => {
    axios.delete(SpringUrl + '/' + id)
      .then(response => {
        console.log(response);
        this.refreshList();
        this.setState({ message: `Drawing type Deleted Successfully--` });
      })
      .catch(error => {
        console.log(error);
      });
    this.outMessage();
  }

  render() {
    const { SelectParentCategory, Data, columns, rowData } = this.state;
    return (

      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <h6>{this.state.message && (
          <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
            <a
              href="VesselDrawingTypeList" className="close" data-dismiss="alert" aria-label="close"> &times;
            </a>
            {this.state.message}
          </div>)}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Drawing Type List</h3>
            </Col>
          </Row>
          <Row className="col-md-8 mx-auto">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            //this.props.history.push(`/VesselDrawingTypeMaster?id=${rowData._id}`)
                            //call update function
                            this.updateDrw_type(rowData.id)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            //this.props.history.push(`/Page?id=${rowData._id}`)
                            //call delete function
                            this.setState({ showMod: true, rowData: rowData })
                            // this.deleteDrw_type(rowData.id)
                          }
                        }
                      ]
                    }


                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>
        <DeleteConfirm show={this.state.showMod}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.drwTypeName}
          close={() => this.setState({ showMod: false })}
          deleteHandler={this.deleteDrw_type} />
      </Container>
    );
  }
}
