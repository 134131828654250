
import React from "react";

import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import '../../Containers/Common/Table.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from '../../service/axiosConfig.js';
import ValidateType from "../Common/Validation/DoubleEntry.js";
import $ from 'jquery';



const springUrl = '/VesselType';
const validateUrl = '/VesselType/TypeName';

export default class AddvesselType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      id: this.props.match.params.id,
      vesselTypeName: "",
      crID: "",
      crDate: "",
      message: "",
      title: "",
      data:[],
      errorMsg: "", allData: [], isBtnVisible: false

    }
  }

  selectedName = (e) => {
    let { name, value } = e.target;
    value = value.replace(/[^A-Za-z\s]/ig, '');
    if (name === "vesselTypeName") {
      if (name.length < 1) {
        alert("Vessel Type must have more than one character");
      }
    }
    this.setState({ [name]: value });

    for (let i = 0; i <= this.state.allData.length - 1; i++) {
      if (e.target.value === this.state.allData[i].vesselTypeName) {
        this.setState({ errorMsg: "This Data Present already" });
        this.setState({ isBtnVisible: true })
        document.getElementById("submit").disabled = !1;
      }
      else {
        this.setState({ errorMsg: "", isBtnVisible: false });
      }
    }
  }
    
  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/VesselTypeList');
  }

  submitAction = (e) => {
    e.preventDefault();
    console.log(this.state);
    if (this.state.id == null) {
      axios.post(springUrl, this.state)
        .then(response => {
          if (response === 201) {
            console.log(response);
          }
          this.setState({ message: `Vessel Type ${this.state.vesselTypeName} Added Successfully!!`, vesselTypeName: "" });
        })
      console.log("successfully submited");
      this.outMessage();
    }
    else if (this.state.id != null) {
      axios.put(springUrl + "/" + this.state.id, this.state)
        .then(response => {
          if (response === 201) {
            console.log(response);
          }
          this.setState({ message: `Vessel Type ${this.state.vesselTypeName} Updated Successfully..` });
        })
      this.outMessage();
    }
  }

  componentDidMount(event) {
    // console.log(this.state.id);
    if (this.state.id != null) {
      this.setState({ title: 'Update' });
      axios.get(springUrl + "/" + this.state.id)
        .then(response => {
          let type = response.data;
          this.setState({ data: response.data })
          this.setState({
            id: type.id, vesselTypeName: type.vesselTypeName,
            crID: type.crID, crDate: type.crDate
          });
        });
    }
    else {
      this.setState({ title: 'Add' });
    }

    this.getVesselType();

  }

      async getVesselType(){
        await axios.get("VesselType")
          .then(Response => {
            this.setState({ allData: Response.data });
          })
      }

  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  reset = () => {
    this.setState({ vesselTypeName: "" });
  };

  render() {
    const { id, vesselTypeName, crID, crDate, title } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        {/* <Card className="shadow p-4 col-md-12"> */}
        <h6>{this.state.message && <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
          <a href="/AddVesselType" className="close" data-dismiss="alert" aria-label="close">&times;</a>{this.state.message}</div>}</h6>
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Vessel Type</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.submitAction}>
            <Input type="hidden" required value={id} />
            <Input type="hidden" required value={crID} />
            <Input type="hidden" required value={crDate} />
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label className="required">Vessel Type Name</Label>
                  <Input type="text" name="vesselTypeName" placeholder="Enter Vessel Type Name" required value={vesselTypeName} onChange={this.selectedName} />
                  <div className="text-danger">
                    {this.state.errorMsg}
                  </div>
                  <ValidateType url={validateUrl} name={vesselTypeName} id={id}></ValidateType>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="text-center">
                <div className="center-block text-center mt-3">
                  <Button className="text-center mr-3  btnblue" id="submit" type="submit" 
                  // disabled={!this.state.vesselTypeName}
                    disabled={this.state.isBtnVisible ? true : false}

                  >Submit</Button>
                  <Button className="text-center " color="danger" onClick={this.reset}>Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>

      </Container>
    );
  }

}