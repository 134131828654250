
import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button, Select, Alert } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FlashMessage from 'react-flash-message';
import 'react-notifications-component/dist/theme.css'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { AxiosConfig } from "../Common/AxiosConfig";
import $ from 'jquery';
import ValidateParameter from "../Common/Validation/DoubleEntry.js";
import axios from "../../service/axiosConfig";

const validateUrl = 'parameter/name';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

export default class AddParameter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      parameterName: "",
      cr_id: "",
      cr_date: "",

      msessage: "",
      errors: {},
      title: "", allData: [],
      data: [], errorMsg: "",
      isBtnVisible: false
    };
  }
  // selectedValue = (value) =>{
  //   this.setState({selectedValue:value})
  // }

  //handle changes
  changeHandler = (event) => {
    //const {name,value} = event.target;
    let name = event.target.name;
    let value = event.target.value.replace(/[^A-Za-z]/ig, '');
    this.setState({ [name]: value });
    // this.setState({ errorMsg: "", isBtnVisible: true });

    for (let i = 0; i <= this.state.allData.length - 1; i++) {
      if (event.target.value === this.state.allData[i].parameterName) {
        this.setState({ errorMsg: "This Data Present already" });
        this.setState({ isBtnVisible: true })
        document.getElementById("submit").disabled = !1;
      }
      else {
        this.setState({ errorMsg: "", isBtnVisible: false });
      }
    }
  }

  redirection = () => {
    const { history } = this.props;
    //push the input data in list table
    if (history) history.push('/ParameterListTable');
  }

  //refresh function that automatically refresh the page
  refresh = () => {
    setTimeout(function () { window.location.reload(false); }, 1000);//1000 for 1sec
  }

  //function for update data
  updateParameter = () => {
    AxiosConfig.put("parameter/" + this.state.id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
      })
  }

  //function for add data
  addParameter = () => {
    AxiosConfig.post("parameter", this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
      })
  }

  onReset = (e) => {

    this.setState({
      // id: "",
      parameterName: ""
    })
  }
  // //alert
  // updateAlert() {
  //   NotificationManager.success('', 'Successfully Updated!');
  // }
  // addAlert() {
  //   NotificationManager.success('', 'Successfully Added!');
  // }

  //handle the submit button (onSubmit)
  subAct = (value) => {
    value.preventDefault();
    console.log(this.state);
    // this.refresh();
    //update
    if (this.state.id != null) {
      this.updateParameter(this.state.id);
      this.setState({ parameterName: "" });
      // this.updateAlert();
      this.setState({
        message: ` Vessel Parameter Updated Successfully!!`
      });
      // window.location.href = '/AddParameter';
    }
    //add
    else if (this.state.id == null) {
      this.addParameter();
      this.setState({ parameterName: "" });
      this.setState({
        message: ` Vessel Parameter Added Successfully!!`
      });
    }
    this.outMessage();
  }

  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  componentDidMount() {

    if (this.state.id != null) {
      axios.get("parameter/" + this.state.id)
        .then(response => {
          let type = response.data;
          this.setState({ data: response.data })
          this.setState({ id: type.id, parameterName: type.parameterName, cr_id: type.cr_id, cr_date: type.cr_date });
        });
    }

    this.getAllParameter();
    if (this.state.id == null) {
      this.setState({ title: "Add" });
    } else {
      this.setState({ title: "Update" });
    }
  }
  async getAllParameter() {
    await axios.get("parameter").then((res) => {
      this.setState({ allData: res.data })
    }).catch((err) => console.log(err))
  }
  render() {
    const { id, parameterName, cr_id, cr_date, title } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/AddParameter" className="close" data-dismiss="alert" aria-label="close"> &times;
              </a>
              {this.state.message}
            </div>)}
        </h6>

        <Card className="shadow p-4 col-md-7 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Parameter  Master</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.subAct}>
            <Row>
              <Col md={12}>
                <Input type="hidden" name="id" required value={id} />
                <Input type="hidden" name="cr_id" required value={cr_id} />
                <Input type="hidden" name="cr_date" required value={cr_date} />
                <FormGroup>
                  <Label>Parameter Name</Label>
                  <Input type="text" name="parameterName" placeholder="Enter Parameter Name" required
                    value={parameterName} onChange={this.changeHandler} />
                  <div className="text-danger">
                    {this.state.errorMsg}
                  </div>
                  <ValidateParameter url={validateUrl} name={parameterName} id={id}></ValidateParameter>
                  {/* <div style={{ color: "red" }}>{this.state.errormessage}</div> */}

                  <div style={{ color: "green" }}><NotificationContainer /></div>
                </FormGroup>
              </Col>
              {/* <Col md={6}>
            <FormGroup>
              <Label>Value</Label>
                <Input type="text" name="Value"  placeholder="" />
            </FormGroup>
           </Col> */}

            </Row>
            <Row>
              <Col md={6} className="MultiWrp">
                {/* <MultiSubSelect/> */}
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-2">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue" id="submit" type="submit"
                    // disabled={!this.state.parameterName }
                    disabled={this.state.isBtnVisible ? true : false}
                  >Submit</Button>
                  <Button className="text-center " color="danger" type="reset" onClick={this.onReset}>Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>

      </Container>
    );
  }

}
