import React, { useState } from "react";

const Validator = (props) =>{
    console.log("validator", props);
    const name = props.name;
    const value = props.value;
    const errors = props.errors;

    const [errorMsg, setErrorMsg] = useState([]);

    const validFullName = RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/);
    const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validPhoNum = RegExp(/^^[6-9]\d{9}$/);
    const validMobNum = RegExp();
    const validWebSite = RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/)
    switch (name) {
    case 'surveyorName': 
        errors.surveyorName = 
        validFullName.test(value)
            ? ''
            : 'Name is Not Valid';
        break;
    // case 'surveyorOffPh1': 
    //     errors.surveyorOffPh1 = 
    //     validPhoNum.test(value)
    //         ? ''
    //         : 'Phone Number is not valid';
    //     break;
    // case 'surveyorOffPh2': 
    //     errors.surveyorOffPh2 = 
    //     validPhoNum.test(value)
    //         ? ''
    //         : 'Phone Number is not valid';
    //     break;
    // case 'surveyorWebsite': 
    //     errors.surveyorWebsite = 
    //     validWebSite.test(value)
    //         ? ''
    //         : 'Website is not valid';
    //     break;
    // case 'surveyorEmail': 
    //     errors.surveyorEmail = 
    //     validEmail.test(value)
    //         ? ''
    //         : 'Email is not valid';
    //     break;
    // case 'surveyorContactPerson': 
    //     errors.surveyorContactPerson = 
    //     validFullName.test(value)
    //         ? ''
    //         : 'Name is not valid';
    //     break; 
    // case 'surveyorCP_ContactNo': 
    //     errors.surveyorCP_ContactNo = 
    //     validPhoNum.test(value)
    //         ? ''
    //         : 'Phone Number is not valid!';
    //     break;
    // case 'surveyorCP_Email': 
    //     errors.surveyorCP_Email = 
    //     validEmail.test(value)
    //         ? ''
    //         : 'Email is not valid!';
    //     break;
    default:
        break;
    }
    // this.setState({errors, [name]: value}, ()=> {
    //     console.log(errors)
    // })

    const changeHandle=(errors)=>{
        setErrorMsg(errors);
        props.changeHandler(errorMsg);
    }

    return(
        <span className='text-danger' onChange={changeHandle()}>
            {errors}
        </span>
    );
}

export default Validator;