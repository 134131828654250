import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import {Row,Col} from 'react-bootstrap'
import "react-multi-carousel/lib/styles.css";
import ss from '../../../assets/ss.png'
import s2 from '../../../assets/secondImg.png'
import s3 from '../../../assets/ThirdImg.png'
// import Fourth from '../../../assets/Fourth.png'
import s5 from '../../../assets/fifth.png'
import s6 from '../../../assets/sixth.png'
import TestiMan from '../../../assets/TestiMan.png'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={() => onClick()} />;
};

class TestimonialReview extends Component {
  _isMounted = false;

  state = {
    awsApiData: [],
    loading: true,
    selectedIndex: 0,
    lightboxIsOpen: false,
    
  };

  render() {
   
    return (
 
            <Carousel  responsive={responsive} partialVisible={true}>
                <div className="mr-1 testimonial">
                  <div className="owl-item cloned" style={{width:'635px'}}>
                    <div className="testimonial-item">
                      <Row>
                        <Col md={5}>
                          <div className="testimonial-img-bg"><img src={TestiMan}/></div>
                        </Col>
                        <Col md={7}>
                          <div className="testimonial-content">
                            <p><FormatQuoteIcon className="quote"/> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.<FormatQuoteIcon className="quote"/>  </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="mr-1 testimonial">
                  <div className="owl-item cloned" style={{width:'635px'}}>
                    <div className="testimonial-item">
                      <Row>
                        <Col md={5}>
                          <div className="testimonial-img-bg"><img src={TestiMan}/></div>
                        </Col>
                        <Col md={7}>
                          <div className="testimonial-content">
                            <p><FormatQuoteIcon className="quote"/> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.<FormatQuoteIcon className="quote"/> </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                  <div className="mr-1 testimonial">
                  <div className="owl-item cloned" style={{width:'635px'}}>
                    <div className="testimonial-item">
                      <Row>
                        <Col md={5}>
                          <div className="testimonial-img-bg"><img src={TestiMan}/></div>
                        </Col>
                        <Col md={7}>
                          <div className="testimonial-content">
                            <p><FormatQuoteIcon className="quote"/> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.<FormatQuoteIcon className="quote"/> </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="mr-1 testimonial">
                  <div className="owl-item cloned" style={{width:'635px'}}>
                    <div className="testimonial-item">
                      <Row>
                        <Col md={5}>
                          <div className="testimonial-img-bg"><img src={TestiMan}/></div>
                        </Col>
                        <Col md={7}>
                          <div className="testimonial-content">
                            <p><FormatQuoteIcon className="quote"/> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.<FormatQuoteIcon className="quote"/> </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                </Carousel>
       
      
    );
  }
}

export default TestimonialReview;