import React, { Component } from "react";
import {
  Container,
  Card,
  Input,
  Form,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import $ from "jquery";
import axios from "../../service/axiosConfig.js";
import { AxiosConfig } from "../Common/AxiosConfig.js";

export default class UpdateVesselParticular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateVesselParticularId: sessionStorage.getItem("particularId"),
      particularData: [],
      particularsName: "",
      vessel_Flag: "",
      vessel_Year_Built: "",
      vessel_Type: "",
      vessel_GRT: "",
      vessel_DWT: "",
      vessel_class: "",
      vessel_Owner: "",
      selectFlagOptions: "",
      selectTypeOptions: "",
      selectClassOptions: "",
      selectOwnerOptions: "",
      vessel_Commissioned: false,
      errors: {},
      message: "",
      cr_id: "",
      cr_date: "",
    };
  }
  componentDidMount() {
    this.getFlagOptions();
  }
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 1000);

    this.setState({ message: "" });
  };
  async getClassOptions() {
    const res = await axios.get("VesselClass");
    const data = res.data;
    console.log(data);
    const ClassOptions = data.map((d) => ({
      value: d.id,
      label: d.className,
    }));
    this.setState({ selectClassOptions: ClassOptions });
  }
  async getOwnerOptions() {
    const res = await axios.get("vesselOwnerMaster");
    const data = res.data;
    console.log(data);
    const OwnerOptions = data.map((d) => ({
      value: d.id,
      label: d.vessel_Owner_Name,
    }));
    this.setState({ selectOwnerOptions: OwnerOptions });
  }
  async getFlagOptions() {
    await axios.get("flagmaster").then((res) => {
      const data = res.data;
      console.log(data);
      const FlagOptions = data.map((d) => ({
        value: d.id,
        label: d.flagName,
      }));
      this.setState({ selectFlagOptions: FlagOptions });
      
      axios.get("VesselType").then((res1) => {
        const TypeOptions = res1.data.map((type) => ({
          value: type.id,
          label: type.vesselTypeName,
        }));
        this.setState({ selectTypeOptions: TypeOptions });
        axios.get("VesselClass").then((res2) => {
          const ClassOptions = res2.data.map((vclass) => ({
            value: vclass.id,
            label: vclass.className,
          }));
          this.setState({ selectClassOptions: ClassOptions });
          axios.get("vesselOwnerMaster").then((res3) => {
            const OwnerOptions = res3.data.map((owner) => ({
              value: owner.id,
              label: owner.vessel_Owner_Name,
            }));
            this.setState({ selectOwnerOptions: OwnerOptions });
            this.getByParticularId(this.state.updateVesselParticularId);
          });
        });
      });
    });
  }
  async getTypeOptions() {
    const res = await axios.get("VesselType");
    const data = res.data;
    console.log(data);
    const TypeOptions = data.map((d) => ({
      value: d.id,
      label: d.vesselTypeName,
    }));
    this.setState({ selectTypeOptions: TypeOptions });
  }

  async getByParticularId(id) {
    AxiosConfig.get("/VesselParticulars/" + id).then((response) => {
      this.setState({
        particularData: response.data,
        particularsName: response.data.particularsName,
        vessel_Year_Built: response.data.vessel_Year_Built,
        vessel_GRT: response.data.vessel_GRT,
        vessel_DWT: response.data.vessel_DWT,
        vessel_Commissioned: response.data.vessel_Commissioned,
        cr_id: response.data.cr_id,
        cr_date: response.data.cr_date,
      });
      console.log(response);
      const valueToSet = this.state.selectFlagOptions.find((row) => {
        return row.value === response.data.vesselFlagMaster.id;
      });

      if (valueToSet) {
        this.handleChange1(valueToSet);
      }
      const valueToSet1 = this.state.selectTypeOptions.find((row) => {
        return row.value === response.data.vesselTypeModel.id;
      });
      if (valueToSet1) {
        this.handleChange2(valueToSet1);
      }
      const valueToSet3 = this.state.selectClassOptions.find((row) => {
        return row.value === response.data.vesselClassModel.id;
      });
      if (valueToSet3) {
        this.handleChange4(valueToSet3);
      }
      const valueToSet4 = this.state.selectOwnerOptions.find((row) => {
        return row.value === response.data.vesselOwnerMaster.id;
      });
      if (valueToSet4) {
        this.handleChange3(valueToSet4);
      }
    });
  }
  updateParticular = (e) => {
    e.preventDefault();
    let records = {
      id: this.state.updateVesselParticularId,
      particularsName: this.state.particularsName,
      vesselFlagMaster:{
        id:this.state.vessel_Flag.value
      },
     vesselTypeModel:{
       id:this.state.vessel_Type.value
     },
      vessel_Year_Built: this.state.vessel_Year_Built,
      // vessel_Type: this.state.vessel_Type.value,
      vessel_GRT: this.state.vessel_GRT,
      vessel_DWT: this.state.vessel_DWT,
      vesselClassModel:{
        id:this.state.vessel_class.value
      },
     vesselOwnerMaster:{
       vessel_Owner_ID:this.state.vessel_Owner.value
     },
      // vessel_Owner: this.state.vessel_Owner.value,
      vessel_Commissioned: this.state.vessel_Commissioned,
      cr_id: this.state.cr_id,
      cr_date: this.cr_date,
    };
    if (this.validateForm()) {
      axios
        .put(
          "VesselParticulars/" + this.state.updateVesselParticularId,
          records
        )
        .then((result) => {
          console.log(result);
          this.setState({
            message: `Particulars Updated Successfully!!`,
            id: "",
          });

          this.onClear();
        })
        .catch((error) => console.log(error));
    }
  };
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  switchhandleChange = (vessel_Commissioned) => {
    this.setState({ vessel_Commissioned });
  };
  handleChange1(value) {
    this.setState({ vessel_Flag: value });
  }
  handleChange2(value) {
    this.setState({ vessel_Type: value });
  }
  handleChange3(value) {
    this.setState({ vessel_Owner: value });
  }
  handleChange4(value) {
    this.setState({ vessel_class: value });
    this.state.vessel_class = value;
  }
  onClear = () => {
    this.setState({
      particularsName: "",
      vessel_Flag: "",
      vessel_Year_Built: "",
      vessel_Type: "",
      vessel_GRT: "",
      vessel_DWT: "",
      vessel_class: "",
      vessel_Owner: "",
      selectFlagOptions: "",
      selectTypeOptions: "",
      selectClassOptions: "",
      selectOwnerOptions: "",
      vessel_Commissioned: false,
    });
  };
  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.vessel_Flag) {
      formIsValid = false;
      errors["vessel_Flag"] = "*Please choose Vessel Flag";
    }
    if (!this.state.vessel_class) {
      formIsValid = false;
      errors["vessel_class"] = "*Please choose Vessel Class";
    }
    if (!this.state.vessel_Owner) {
      formIsValid = false;
      errors["vessel_Owner"] = "*Please choose Vessel owner";
    }
    if (!this.state.vessel_Type) {
      formIsValid = false;
      errors["vessel_Type"] = "*Please choose Vessel Type";
    }
    if (!this.state.particularsName) {
      formIsValid = false;
      errors["particularsName"] = "*Please choose Vessel name";
    }
    if (!this.state.vessel_Year_Built) {
      formIsValid = false;
      errors["vessel_Year_Built"] = "*Please choose Vessel Year Built";
    }
    if (!this.state.vessel_GRT) {
      formIsValid = false;
      errors["vessel_GRT"] = "*Please choose Vessel GRT";
    }
    if (!this.state.vessel_DWT) {
      formIsValid = false;
      errors["vessel_DWT"] = "*Please choose Vessel DWT";
    }
    this.setState({
      errors: errors,
    });

    return formIsValid;
  };

  render() {
    return (
      <div>
        <Container className="formWpr">
          <h6>
            {this.state.message && (
              <div className="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                <a
                  href="FlagMasterTable"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                >
                  {" "}
                  &times;{" "}
                </a>{" "}
                {this.state.message}{" "}
              </div>
            )}{" "}
          </h6>

          <Card className="shadow p-4 col-md-10 mx-auto">
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">Vessel Particulars</h3>
              </Col>
              {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
            </Row>
            <Form onSubmit={this.updateParticular}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Name</Label>

                    <Input
                      type="text"
                      required="Please enter a class Name"
                      onChange={this.onInputChange.bind(this)}
                      name="particularsName"
                      value={this.state.particularsName}
                    ></Input>
                    {this.state.errors.particularsName && (
                      <div className="errorMsg">
                        {this.state.errors.particularsName}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Flag </Label>
                    <Select
                      onChange={this.handleChange1.bind(this)}
                      value={this.state.vessel_Flag}
                      options={this.state.selectFlagOptions}
                    />
                    {this.state.errors.vessel_Flag && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_Flag}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required"> Vessel Year Built</Label>
                    <Input
                      type="text"
                      required="Please enter a class Name"
                      value={this.state.vessel_Year_Built}
                      onChange={(e) => {
                        this.onInputChange(e);
                      }}
                      name="vessel_Year_Built"
                    />
                    {/* {<span className="error" style={{ color: "red" }}>{this.state.errors.vessel_Year_Built}
                                    </span>} */}
                    {this.state.errors.vessel_Year_Built && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_Year_Built}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Type </Label>
                    <Select
                      onChange={this.handleChange2.bind(this)}
                      value={this.state.vessel_Type}
                      options={this.state.selectTypeOptions}
                    />
                    {this.state.errors.vessel_Type && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_Type}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel GRT</Label>
                    <Input
                      type="text"
                      required="Please enter a class Name"
                      onChange={(e) => {
                        this.onInputChange(e);
                      }}
                      name="vessel_GRT"
                      value={this.state.vessel_GRT}
                    />
                    {/* {<span className="error" style={{ color: "red" }}>{this.state.error.vessel_GRT}
                                    </span>} */}
                    {this.state.errors.vessel_Year_Built && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_GRT}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel DWT</Label>
                    <Input
                      type="text"
                      required="Please enter a class Name"
                      onChange={(e) => {
                        this.onInputChange(e);
                      }}
                      name="vessel_DWT"
                      value={this.state.vessel_DWT}
                    />
                    {/* {<span className="error" style={{ color: "red" }}>{this.state.error.vessel_DWT}
                                    </span>} */}
                    {this.state.errors.vessel_Year_Built && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_DWT}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel class </Label>
                    <Select
                      onChange={this.handleChange4.bind(this)}
                      value={this.state.vessel_class}
                      options={this.state.selectClassOptions}
                    />
                    {this.state.errors.vessel_class && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_class}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Owner </Label>
                    <Select
                      onChange={this.handleChange3.bind(this)}
                      value={this.state.vessel_Owner}
                      options={this.state.selectOwnerOptions}
                    />
                    {this.state.errors.vessel_Owner && (
                      <div className="errorMsg">
                        {this.state.errors.vessel_Owner}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Commissioned</Label>
                    <br />
                    <Switch
                      onChange={this.switchhandleChange}
                      checked={this.state.vessel_Commissioned}
                      icon={false}
                    />
                    <span></span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={1} className="AddBtnCol">
                  <Button>UPDATE</Button>
                </Col>

                <Col md={3} className="AddBtnCol">
                  <div className="center-block text-center">
                    <Button
                      className="text-center "
                      onClick={this.onClear}
                      color="danger"
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}
