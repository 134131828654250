import React from "react";
// import '../Course/CourseMaster.css'
import {
  Form,
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import Table from "../Common/Table";
import "../../Containers/Common/Table.css";
import axios from "axios";
import Select from "react-select";
import ReactDOM from "react-dom";
import $ from "jquery";
//import { useForm } from 'react-hook-form';
import { withRouter } from "react-router";
import DeleteConfirm from "../../Containers/Common/deleteComf.js";
import { AxiosConfig } from "../Common/AxiosConfig";

import { useForm, Controller } from "react-hook-form";

const headerStyle = {
  backgroundColor: "#052754",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

class AddVesselEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      columns: [
        { title: "Id", field: "id", hidden: true },
        { title: "Equipment", field: "vesselEqpname" },
        { title: "Equipment Value", field: "equipmentValue" },
      ],

      drawing_type: [],
      vesselEquipment: "",

      equipmentValue: "",
      vesselEquipments: [],
      vesselEquipments1: [],

      id: this.props.match.params.id,
      rowData: [],
      show: false,

      message1: "",
      message2: "",
      cr_id: "",
      cr_date: "",
      errors: {},
      merged: [],
    };
  }

  async getOptions() {
    const res = await AxiosConfig.get("EquipmentMaster_view");
    const data = res.data;
    const options = data.map((d) => ({
      value: d.id,
      label: d.vesselEqpname,
    }));
    this.setState({ drawing_type: options });
  }

  onInputChange = (obj) => {
    this.setState({ vesselEquipment: obj });
    this.props.inputData.vesselEquipment = obj;
  };

  changeEquipmentValueHandler = (event) => {
    this.setState({ equipmentValue: event.target.value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  componentDidMount() {
    this.getOptions();

    // this.getVesselEquipment();
  }
  outMessage = () => {
    $(".alert").show();

    setTimeout(function () {
      $(".alert").hide();
    }, 2000);

    this.setState({ message1: "" });
    this.setState({ message2: "" });
  };
  saveRecord = (e) => {
    console.log(this.validateForm());

    e.preventDefault();

    let equipments = {
      vesselEquipment: this.state.vesselEquipment.value,
      equipmentValue: this.state.equipmentValue,
    };
    console.log("employee => " + JSON.stringify(equipments));

    // step 5
    if (this.validateForm()) {
      AxiosConfig.post("VesselEquipment", equipments).then((res) => {
        this.getVesselEquipment();

        sessionStorage.setItem("vessel_equipment_id", res.data);

        this.reset();
        this.setState({
          message1: ` Vessel Equipment added Successfully!!`,
        });
      });
    }
    this.outMessage();
  };
  reset = () => {
    this.setState({
      vesselEquipment: "",
      equipmentValue: "",
    });
  };

  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (!this.props.inputData.vesselEquipment) {
      formIsValid = false;
      errors["vesselEquipment"] = "*Please choose vessel equipments";
    }
    if (!this.props.inputData.equipmentValue) {
      formIsValid = false;
      errors["equipmentValue"] = "*Please enter equipment value";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validation = () => {
    if (this.validateForm() === true) {
      this.props.next();
    } else {
      this.validateForm();
    }
  };

  outMessage = () => {
    $(".alert").show();

    setTimeout(function () {
      $(".alert").hide();
    }, 2000);

    this.setState({ message1: "" });
    this.setState({ message2: "" });
  };

  //Update Dimension
  updateRecord = (e) => {
    console.log("update");
    e.preventDefault();
    // e.target.reset();
    var Equipment = {
      id: this.props.inputData.vequipmentid,
      vesselEquipment: this.props.inputData.vesselEquipment.value,
      equipmentValue: this.props.inputData.equipmentValue,
      cr_id: this.props.inputData.cr_id,
      cr_date: this.props.inputData.cr_date,
    };
    if (this.validateForm()) {
      AxiosConfig.put("VesselEquipment/" + this.state.id, Equipment)
        .then((result) => {
          if (result === 201) {
            console.log(result);
          }
          this.getVesselEquipment();

          this.setState({
            message1: "Vessel Equipments Updated sucessfully!!",
            id: "",
          });
          this.props.inputData.vequipmentid = "";
          this.props.inputData.vesselEquipment = "";
          this.props.inputData.equipmentValue = "";
          this.props.inputData.cr_id = "";
          this.props.inputData.cr_date = "";
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //to reset
    this.setState({ vesselEquipment: "" });
    this.setState({ equipmentValue: "" });
    this.outMessage();
  };

  async getVesselEquipment() {
    await AxiosConfig.get("getEquipmentInfo").then((res) => {
      console.log(res.data, "0");
      this.setState({ vesselEquipments: res.data });
    });

    await AxiosConfig.get("VesselEquipment").then((res) => {
      console.log(res.data, "0");
      this.setState({ vesselEquipments1: res.data });
    });

    var getInfo = this.state.vesselEquipments;
    var VesselEquipment = this.state.vesselEquipments1;

    const result = getInfo.map((item) => {
      const obj = VesselEquipment.find((o) => o.id === item.id);

      return { ...item, ...obj };
    });
    console.log(result, "result");

    this.setState({ merged: result });
    console.log(this.state.merged, "merged");
  }

  selectOne(id) {
    AxiosConfig.get("VesselEquipment/" + id).then((response) => {
      this.getData(id);
    });
  }

  getData(id) {
    if (id != null) {
      AxiosConfig.get("VesselEquipment/" + id)
        .then((response) => {
          let vessel = response.data;
          this.props.inputData.vequipmentid = id;
          this.props.inputData.vesselEquipment = vessel.vesselEquipment;
          this.props.inputData.equipmentValue = vessel.equipmentValue;
          this.props.inputData.cr_id = vessel.cr_id;
          this.props.inputData.cr_date = vessel.cr_date;
          this.setState({
            id: vessel.id,
            vesselEquipment: vessel.vesselEquipment,
            equipmentValue: vessel.equipmentValue,
            cr_id: vessel.cr_id,
            cr_date: vessel.cr_date,
          });
          const valueToSet = this.state.drawing_type.find((row) => {
            return row.value === this.props.inputData.vesselEquipment;
          });
          if (valueToSet) {
            this.onInputChange(valueToSet);
          }
          if (valueToSet) {
            this.onInputChange(valueToSet);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  deleteDesign = (id) => {
    AxiosConfig.delete(`VesselEquipment/` + id, this.state).then((response) => {
      if (response === 201) {
        console.log(response);
      }
      this.getVesselEquipment();
      this.setState({ message2: "Vessel Equipment Deleted Successfully" });
    });
    this.outMessage();
  };

  filterArrayElementByEdit(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }

  render() {
    const {
      merged,
      id,
      cr_id,
      cr_date,
      rowData,
      vesselEquipment,
      equipmentValue,
      columns,
    } = this.state;
    return (
      <Container fluid className=" FormWp">
        <h6>
          {this.state.message2 && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="FlagMasterTable"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                {" "}
                &times;{" "}
              </a>{" "}
              {this.state.message2}{" "}
            </div>
          )}{" "}
        </h6>
        <h6>
          {this.state.message1 && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/VesselDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message1}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText"> Vessel Equipments</h3>
            </Col>
            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
          </Row>
          <Form
          // onSubmit={this.state.id ? this.updateRecord : this.saveRecord}
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input type="hidden" name="id" required value={id} />
                  <Input type="hidden" name="cr_id" required value={cr_id} />
                  <Input
                    type="hidden"
                    name="cr_date"
                    required
                    value={cr_date}
                  />
                  <Label className="required">Vessel Equipment</Label>
                  <Select
                    options={this.state.drawing_type}
                    onChange={this.props.onInputChange}
                    value={this.props.inputData.vesselEquipment}
                    placeholder="Select Equipments"
                  />
                  {this.props.errors.vesselEquipment ? (
                    <div className="errorMsg">
                      {this.props.errors.vesselEquipment}
                    </div>
                  ) : (
                    <div className="errorMsg">
                      {this.state.errors.vesselEquipment}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label className="required">Equipment Value</Label>
                  <Input
                    type="text"
                    name="equipmentValue"
                    value={this.props.inputData.equipmentValue}
                    onChange={this.props.test}
                    placeholder="Enter Equipment Value"
                  />

                  {this.props.errors.equipmentValue ? (
                    <div className="errorMsg">
                      {this.props.errors.equipmentValue}
                    </div>
                  ) : (
                    <div className="errorMsg">
                      {this.state.errors.equipmentValue}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {this.props.inputData.vequipmentid ? (
                <Col md={1} className="AddBtnCol">
                  <Button onClick={this.updateRecord}>UPDATE</Button>
                </Col>
              ) : (
                <Col md={1} className="AddBtnCol">
                  <Button onClick={this.validation}>NEXT</Button>
                </Col>
              )}
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  {/* <Button className="text-center mr-3  btnblue">Submit</Button> */}
                  <Button
                    type="reset"
                    className="text-center "
                    color="danger"
                    onClick={this.reset}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="mt-4 mb-3">
              <Col className="tableWp">
                <Row>
                  <Col className="DashboardTbl">
                    {/* <Table data={merged} pageSize={8} paging={false} columns={columns} search={false} headerStyle={headerStyle}
                      rowStyle={rowStyle} maxBodyHeight='350px'
                      actions={
                        [
                          {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                              this.getData(rowData.id)
                            }
                          },
                          // {
                          //   icon: 'delete',
                          //   tooltip: 'Delete',
                          //   onClick: (event, rowData) => {
                          //     this.setState({ show: true, rowData: rowData })
                          //   }
                          // },

                        ]
                      }
                    /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Submit</Button>
                  <Button type="reset" className="text-center " color="danger" onClick={this.reset}>Clear</Button>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Card>
        <DeleteConfirm
          show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.vesselEqpname}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteDesign}
        />
      </Container>
    );
  }
}

// function App1(props) {

//   const form = useForm();

//   return <AddVesselEquipments {...props} {...form} />;

// }

// const rootElement = document.getElementById("root");

// ReactDOM.render(<App1 />, rootElement);

export default withRouter(AddVesselEquipments);
