import React from "react";
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import Select from 'react-select';
import Switch from "react-switch";
import Table from '../Common/Table';
import { withRouter } from 'react-router';
import '../../Containers/Common/Table.css'
import { AxiosConfig } from '../Common/AxiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';
import axios from '../../service/axiosConfig.js';
import AddVesselDesign from "./AddVesselDesign";
import VesselDetails from "./VesselDetails";

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0,

}
const rowStyle = {
    fontSize: '12px',
    padding: '10px',
    color: '#818698',

}
class AddVesselParticulars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            checked: false,
            merged: [],
            val: true,
            columns: [
                {
                    title: "Id", field: "tableData.id", render: (rowData) => {
                        return <p>{rowData.tableData.id + 1}</p>;
                    },
                    hidden: true
                },
                { title: "Vessel Name", field: "particularsName" },
                { title: "Vessel Flag", field: "flagName" },
                { title: "Vessel Year Built", field: "vessel_Year_Built" },
                { title: "Vessel Type", field: "vesselTypeName" },
                { title: "Vessel GRT", field: "vessel_GRT" },
                { title: "Vessel DWT", field: "vessel_DWT" },
                { title: "Vessel Class", field: "className" },
                { title: "Vessel Owner", field: "vessel_Owner_Name" },
                {
                    title: "Vessel Commissioned", field: "vessel_Commissioned", render: rowData => {
                        return (rowData.vessel_Commissioned ? "YES" : "NO")
                    }
                }],
            id: this.props.match.params.id,
            particularsName: '',
            vessel_Flag: '',
            vessel_Year_Built: '',
            vessel_Type: '',
            vessel_GRT: '',
            vessel_DWT: '',
            vessel_class: '',
            vessel_Owner: '',
            VesselParticularsList2: [],
            VesselParticularsList3: [],
            VesselParticularsList4: [],
            vessel_Commissioned: false,
            selectTypeOptions: '',
            selectFlagOptions: '',
            selectOwnerOptions: '',
            selectClassOptions: '',
            VesselParticularsList: [],
            VesselParticularsList1: [],
            show: '',
            rowData: [],
            message1: "",
            message: "",
            cr_id: "",
            cr_date: "",
            dispaly: "",
            errors: {},
            error: {
            },
            VesselParticularsId: "",//send 
            updateVesselParticularId:sessionStorage.getItem('particularId')
        }

    }
    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.props.inputData.vessel_Flag) {
            formIsValid = false;
            errors["vessel_Flag"] = "*Please choose Vessel Flag";
        }
        if (!this.props.inputData.vessel_class) {
            formIsValid = false;
            errors["vessel_class"] = "*Please choose Vessel Class";
        }
        if (!this.props.inputData.vessel_Owner) {
            formIsValid = false;
            errors["vessel_Owner"] = "*Please choose Vessel owner";
        }
        if (!this.props.inputData.vessel_Type) {
            formIsValid = false;
            errors["vessel_Type"] = "*Please choose Vessel Type";
        }
        if (!this.props.inputData.particularsName) {
            formIsValid = false;
            errors["particularsName"] = "*Please choose Vessel name";
        }
        if (!this.props.inputData.vessel_Year_Built) {
            formIsValid = false;
            errors["vessel_Year_Built"] = "*Please choose Vessel Year Built";
        }
        if (!this.props.inputData.vessel_GRT) {
            formIsValid = false;
            errors["vessel_GRT"] = "*Please choose Vessel GRT";
        }
        if (!this.props.inputData.vessel_DWT) {
            formIsValid = false;
            errors["vessel_DWT"] = "*Please choose Vessel DWT";
        }
        this.setState({
            errors: errors
        });

        return formIsValid;
    }


    validation = () => {


        if (this.validateForm() === true) {

            this.props.next();
        }
        else {
            this.validateForm()
        }
    }




    async getParticularsDetails() {

        await axios.get("VesselParticulars")
            .then(res => {
                this.setState({ VesselParticularsList: res.data })

            });
        await axios.get("getInfoFlag")
            .then(res => { 
                this.setState({ VesselParticularsList1: res.data })

            });
        await axios.get("getInfoClass")
            .then(res => {
                this.setState({ VesselParticularsList2: res.data })

            });
        await axios.get("getInfoOwner")
            .then(res => {
                this.setState({ VesselParticularsList3: res.data })

            });
        await axios.get("getInfoType")
            .then(res => {
                this.setState({ VesselParticularsList4: res.data })

            });

        var a1 = this.state.VesselParticularsList;
        var a2 = this.state.VesselParticularsList1;
        var a3 = this.state.VesselParticularsList2;
        var a4 = this.state.VesselParticularsList3;
        var a5 = this.state.VesselParticularsList4;
        const result = a1.map(item => {
            const obj = a2.find(o => o.id === item.id);
            const obj1 = a3.find(p => p.id === item.id);
            const obj2 = a4.find(p => p.id === item.id);
            const obj3 = a5.find(p => p.id === item.id);
            return { ...item, ...obj, ...obj1, ...obj2, ...obj3 };
        });
        // this.result(result);
        this.setState({ merged: result })


    }

    switchhandleChange = (vessel_Commissioned) => {
        this.setState({ vessel_Commissioned })
    }

    handleChange1(value) {
        this.setState({ vessel_Flag: value })
        this.props.inputData.vessel_Flag = value
    }
    handleChange2(value) {
        this.setState({ vessel_Type: value })
        this.props.inputData.vessel_Type = value
    }
    handleChange3(value) {
        this.setState({ vessel_Owner: value })
        this.props.inputData.vessel_Owner = value;

    }
    handleChange4(value) {
        this.setState({ vessel_class: value })
        this.state.vessel_class = value;
        this.props.inputData.vessel_class = value
    }

    changeState = (e) => {

        //validation for material_value
        const { name, value } = e.target;
        let error = this.state.error;
        // let success = e.target.error;
        const validValue = RegExp(/^[0-9\b]+$/);
        const validYear = RegExp(/^\d{4}$/);
        switch (name) {
            case "vessel_GRT":
                error.vessel_GRT = validValue.test(value) ? "" : "Invalid value";
                break;
            case "vessel_DWT":
                error.vessel_DWT = validValue.test(value) ? "" : "Invalid value";
                break;
            case "vessel_Year_Built":
                error.vessel_Year_Built = validYear.test(value) ? "" : "Invalid Year";
                break;
            default:
                break;
        }

        this.setState({ error, [name]: value });
    }
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onInputChange1 = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getFlagOptions() {
        const res = await axios.get('flagmaster')
        const data = res.data
        console.log(data);
        const FlagOptions = data.map(d => ({
            "value": d.id,
            "label": d.flagName
        }))
        this.setState({ selectFlagOptions: FlagOptions })
    }


    async getTypeOptions() {
        const res = await axios.get('VesselType')
        const data = res.data
        console.log(data);
        const TypeOptions = data.map(d => ({
            "value": d.id,
            "label": d.vesselTypeName
        }))
        this.setState({ selectTypeOptions: TypeOptions })
    }

    async getOwnerOptions() {
        const res = await axios.get('vesselOwnerMaster')
        const data = res.data
        console.log(data);
        const OwnerOptions = data.map(d => ({
            "value": d.id,
            "label": d.vessel_Owner_Name
        }))
        this.setState({ selectOwnerOptions: OwnerOptions })
    }
    async getClassOptions() {
        const res = await axios.get('VesselClass')
        const data = res.data
        console.log(data);
        const ClassOptions = data.map(d => ({
            "value": d.id,
            "label": d.className
        }))
        this.setState({ selectClassOptions: ClassOptions })
    }

    componentDidMount() {
        this.getFlagOptions();
        this.getTypeOptions();
        this.getOwnerOptions();
        this.getClassOptions();
        // this.getParticularsDetails();
        this.getData();
        // setTimeout(() => {
        //     this.get();
        // }, 100);
    }
    //function for alert suto close 
    outMessage = () => {
        $(".alert").show();
        setTimeout(function () {
            $(".alert").hide();
        }, 1000);

        this.setState({ message: "" });
        this.setState({ message1: "" });
    }


    saveParticulars = (e) => {
        e.preventDefault();
    
        var Particulars = {
            particularsName: this.state.particularsName,
            vessel_Flag: this.state.vessel_Flag.value,
            vessel_Year_Built: this.state.vessel_Year_Built,
            vessel_Type: this.state.vessel_Type.value,
            vessel_GRT: this.state.vessel_GRT,
            vessel_DWT: this.state.vessel_DWT,
            vessel_class: this.state.vessel_class.value,
            vessel_Owner: this.state.vessel_Owner.value,
            vessel_Commissioned: this.state.vessel_Commissioned,
            cr_id: this.state.cr_id,
            cr_date: this.cr_date
        }
        if (this.validateForm()) {
            axios.post("VesselParticulars", Particulars)
                .then((result) => {
                    alert(result.data)
                    sessionStorage.setItem('vessel_particulars_id', result.data)
                    console.log(result.data, "VesselParticulars")
                    this.setState({
                        message: ` Particulars added Successfully!!`,
                        VesselParticularsId: result.data
                    }); this.getParticularsDetails();
                    // setTimeout(() => {
                    //     this.get();
                    // }, 100);

                    this.setState(result)
                    this.onClear();
                })
        }
        this.outMessage();


    }


    selectOne(id) {
        AxiosConfig.get("/VesselParticulars/" + id).
            then(response => {
                this.getData(id);
            })
    }


    deleteParticulars = (id) => {
        axios.delete(`VesselParticulars/` + id, this.state)
            .then(response => {
                this.setState({ message1: "Vessel particular Deleted Successfully" });
                this.getParticularsDetails();
            })
        this.outMessage();
    }

    getData(id) {

        if (id != null) {
            axios.get("VesselParticulars/" + id)
                .then((response) => {

                    this.setState({ id: response.data.id })
                    this.state.id = response.data.id;
                    this.props.inputData.particularid = response.data.id;
                    let VesselParticular = response.data;
                    this.props.inputData.particularsName = response.data.particularsName
                    this.props.inputData.vessel_Type = response.data.vessel_Type
                    this.props.inputData.vessel_Owner = response.data.vessel_Owner
                    this.props.inputData.vessel_GRT = response.data.vessel_GRT
                    this.props.inputData.vessel_Flag = response.data.vessel_Flag
                    this.props.inputData.vessel_DWT = response.data.vessel_DWT
                    this.props.inputData.vessel_Commissioned = response.data.vessel_Commissioned
                    this.props.inputData.vessel_Year_Built = response.data.vessel_Year_Built
                    this.props.inputData.vessel_class = response.data.vessel_class
                    this.props.inputData.cr_id = response.data.cr_id
                    this.props.inputData.cr_date = response.data.cr_date
                    this.setState({
                        id: VesselParticular.id,
                        particularsName: VesselParticular.particularsName,
                        vessel_Type: VesselParticular.vessel_Type,
                        vessel_Owner: VesselParticular.vessel_Owner,
                        vessel_GRT: VesselParticular.vessel_GRT,
                        vessel_Flag: VesselParticular.vessel_Flag,
                        vessel_DWT: VesselParticular.vessel_DWT,
                        vessel_Commissioned: VesselParticular.vessel_Commissioned,
                        vessel_Year_Built: VesselParticular.vessel_Year_Built,
                        vessel_class: VesselParticular.vessel_class,
                        cr_id: VesselParticular.cr_id,
                        cr_date: VesselParticular.cr_date,
                    });
                    const valueToSet = this.state.selectFlagOptions.find((row) => {
                        return row.value === this.props.inputData.vessel_Flag;
                    });
                    if (valueToSet) {
                        this.handleChange1(valueToSet);
                    }
                    const valueToSet1 = this.state.selectTypeOptions.find((row) => {
                        return row.value === this.props.inputData.vessel_Type;
                    });
                    if (valueToSet1) {
                        this.handleChange2(valueToSet1);
                    }
                    const valueToSet3 = this.state.selectClassOptions.find((row) => {
                        return row.value === this.props.inputData.vessel_class;
                    });
                    if (valueToSet3) {

                        this.handleChange4(valueToSet3);
                    }

                    const valueToSet2 = this.state.selectOwnerOptions.find((row) => {
                        return row.value === this.props.inputData.vessel_Owner;
                    });
                    if (valueToSet2) {

                        this.handleChange3(valueToSet2);
                    }

                    if (valueToSet2) {

                        this.handleChange3(valueToSet2);
                    }




                }).catch((error) => {
                    alert('error ')
                    console.log(error);
                });
        }


    }
    upParticulars = (e) => {
        e.preventDefault();
        var Particulars = {
            id: this.props.inputData.particularid,
            particularsName: this.props.inputData.particularsName,
            vessel_Commissioned: this.props.inputData.vessel_Commissioned,
            vessel_Flag: this.props.inputData.vessel_Flag.value,
            vessel_Year_Built: this.props.inputData.vessel_Year_Built,
            vessel_Type: this.props.inputData.vessel_Type.value,
            vessel_GRT: this.props.inputData.vessel_GRT,
            vessel_DWT: this.props.inputData.vessel_DWT,
            vessel_class: this.props.inputData.vessel_class.value,
            vessel_Owner: this.props.inputData.vessel_Owner.value,
            cr_id: this.props.inputData.cr_id,
            cr_date: this.props.inputData.cr_date

        }
        axios.put("VesselParticulars/" + this.props.inputData.particularid, Particulars)
            .then((result) => {
                console.log(result)
                this.setState({
                    message: `Particulars Updated Successfully!!`, id: ""
                });
                this.getParticularsDetails();

                // setTimeout(() => {
                //     this.get();
                // }, 100);
                this.setState(result)
                this.onClear();
            }).catch((error) =>
                console.log(error))
        this.outMessage();
    }

    onClear() {
        this.setState({
            particularsName: "",
            vessel_Flag: "",
            vessel_Year_Built: '',
            vessel_Type: '',
            vessel_GRT: '',
            vessel_DWT: '',
            vessel_class: '',
            vessel_Owner: '',
            vessel_Commissioned: '',

        })
        this.props.inputData.particularsName = "";
        this.props.inputData.vessel_Flag = "";
        this.props.inputData.vessel_Year_Built = "";
        this.props.inputData.vessel_Type = "";
        this.props.inputData.vessel_GRT = "";
        this.props.inputData.vessel_DWT = "";
        this.props.inputData.vessel_class = "";
        this.props.inputData.vessel_Owner = "";
        this.props.inputData.vessel_Commissioned = "";

    }

    // nextPage=()=> {
    //     updateEvent = (id) => {
    //         this.props.history.push("/AdminAddEvent/" + id)
    //         sessionStorage.setItem("eventId", id)
    //     }
    // }
    // hi() {
    //     alert("hi")
    //     sessionStorage.setItem("vessel_particulars_id", this.state.VesselParticularsId)
    //     console.log(this.state.VesselParticularsId, "sda");
    // }
    // hello() {
    //     alert("hello")
    // }



    render() {
        const { columns, merged, Data, rowData, cr_id, cr_date, id, vessel_Commissioned, particularsName, selectOwnerOptions, vessel_GRT, vessel_class, vessel_Owner, vessel_Type, selectClassOptions, selectFlagOptions, selectTypeOptions, vessel_Flag, selectedOption } = this.state;

        return (
            <Container className="formWpr">
                <h6>{this.state.message && (<div className="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                    <a href="FlagMasterTable" className="close" data-dismiss="alert" aria-label="close"
                    > &times; </a> {this.state.message}  </div>)} </h6>
                <h6>{this.state.message1 && (<div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
                    <a href="FlagMasterTable" className="close" data-dismiss="alert" aria-label="close"
                    > &times; </a> {this.state.message1}  </div>)} </h6>
                <Card className="shadow p-4 col-md-10 mx-auto">
                    <Row>
                        <Col className="mb-4">
                            <h3 className="text-center blueText">Vessel Particulars</h3>
                        </Col>
                        {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                    </Row>
                    <Form
                    // onSubmit={id ? this.upParticulars : this.saveParticulars} 
                    >
                        <Input type="hidden" name="cr_id" required value={cr_id} />
                        <Input type="hidden" name="cr_date" required value={cr_date} />
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required" >Vessel Name</Label>

                                    <Input type="text" required="Please enter a class Name"
                                        // value={this.state.particularsName} name="particularsName" onChange={this.onInputChange1.bind(this)}
                                        name="particularsName"
                                        value={this.props.inputData.particularsName}
                                        onChange={this.props.test}
                                    ></Input>
                                    {this.props.errors.particularsName ?
                                        <div className="errorMsg">{this.props.errors.particularsName}</div> :
                                        <div className="errorMsg">{this.state.errors.particularsName}</div>
                                    }

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required">Vessel Flag </Label>
                                    <Select
                                        // value={vessel_Flag}
                                        // onChange={this.handleChange1.bind(this)}
                                        value={this.props.inputData.vessel_Flag}
                                        onChange={this.props.handleChange1}
                                        options={selectFlagOptions}
                                    />
                                    {
                                        this.props.errors.vessel_Flag ?
                                            <div className="errorMsg">{this.props.errors.vessel_Flag}</div> :
                                            <div className="errorMsg">{this.state.errors.vessel_Flag}</div>
                                    }

                                </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required"> Vessel Year Built</Label>
                                    <Input type="text" required="Please enter a class Name"
                                        // value={this.state.vessel_Year_Built}
                                        // name="vessel_Year_Built"
                                        // onChange={e => {
                                        //     this.onInputChange(e);
                                        //     this.changeState(e);
                                        // }} 
                                        name="vessel_Year_Built"
                                        value={this.props.inputData.vessel_Year_Built}
                                        onChange={this.props.test}
                                    />
                                    {/* {<span className="error" style={{ color: "red" }}>{this.state.errors.vessel_Year_Built}
                                    </span>} */}
                                    {
                                        this.props.errors.vessel_Year_Built ?
                                            <div className="errorMsg">{this.props.errors.vessel_Year_Built}</div> :
                                            <div className="errorMsg">{this.state.errors.vessel_Year_Built}</div>
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={6}>

                                <FormGroup>
                                    <Label className="required">Vessel Type </Label>
                                    <Select
                                        // value={vessel_Type}
                                        // onChange={this.handleChange2.bind(this)}
                                        value={this.props.inputData.vessel_Type}
                                        onChange={this.props.handleChange2}
                                        options={selectTypeOptions}
                                    />
                                    {this.props.errors.vessel_Type ?
                                        <div className="errorMsg">{this.props.errors.vessel_Type}</div> :
                                        <div className="errorMsg">{this.state.errors.vessel_Type}</div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required">Vessel GRT</Label>
                                    <Input type="text" required="Please enter a class Name"
                                        // value={this.state.vessel_GRT} name="vessel_GRT"
                                        // onChange={e => { this.onInputChange(e); this.changeState(e); }} 
                                        name="vessel_GRT"
                                        value={this.props.inputData.vessel_GRT}
                                        onChange={this.props.test}
                                    />
                                    {/* {<span className="error" style={{ color: "red" }}>{this.state.error.vessel_GRT}
                                    </span>} */}
                                    {
                                        this.props.errors.vessel_Year_Built ?
                                            <div className="errorMsg">{this.props.errors.vessel_GRT}</div> :
                                            <div className="errorMsg">{this.state.errors.vessel_GRT}</div>
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required">Vessel DWT</Label>
                                    <Input type="text" required="Please enter a class Name"
                                        //  value={this.state.vessel_DWT} name="vessel_DWT" onChange={e => { this.onInputChange(e); this.changeState(e); }}
                                        name="vessel_DWT"
                                        value={this.props.inputData.vessel_DWT}
                                        onChange={this.props.test}
                                    />
                                    {/* {<span className="error" style={{ color: "red" }}>{this.state.error.vessel_DWT}
                                    </span>} */}
                                    {
                                        this.props.errors.vessel_Year_Built ?
                                            <div className="errorMsg">{this.props.errors.vessel_DWT}</div> :
                                            <div className="errorMsg">{this.state.errors.vessel_DWT}</div>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required">Vessel class </Label>
                                    <Select
                                        // value={vessel_class}
                                        // onChange={this.handleChange4.bind(this)}

                                        value={this.props.inputData.vessel_class}
                                        onChange={this.props.handleChange4}
                                        options={selectClassOptions}
                                    />
                                    {this.props.errors.vessel_class ?
                                        <div className="errorMsg">{this.props.errors.vessel_class}</div> :
                                        <div className="errorMsg">{this.state.errors.vessel_class}</div>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required">Vessel Owner </Label>
                                    <Select
                                        // value={vessel_Owner}
                                        // onChange={this.handleChange3.bind(this)}
                                        value={this.props.inputData.vessel_Owner}
                                        onChange={this.props.handleChange3}
                                        options={selectOwnerOptions}
                                    />
                                    {this.props.errors.vessel_Owner ?
                                        <div className="errorMsg">{this.props.errors.vessel_Owner}</div> :
                                        <div className="errorMsg">{this.state.errors.vessel_Owner}</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="required" >Vessel Commissioned</Label><br />
                                    <Switch onChange={this.props.switchhandleChange} checked={this.props.inputData.vessel_Commissioned} icon={false} />
                                    <span></span>
                                </FormGroup>
                            </Col>
                            <Col md={1} className="AddBtnCol">
                                {this.props.inputData.particularid ?
                                    <Button
                                        //  onClick={this.props.next}
                                        onClick={this.upParticulars}
                                    >UPDATE</Button> :
                                    <Button
                                        //  onClick={this.props.next}
                                        onClick={this.validation}
                                    >NEXT</Button>
                                }


                                {/* {this.state.VesselParticularsId > 0 && <VesselDetails /> } */}

                            </Col><div style={{ color: "red" }}>{this.state.errormessage}</div>

                            <Col className="text-center mt-3">
                                <div className="center-block text-center">
                                    <Button className="text-center " onClick={this.onClear.bind(this)} color="danger">Clear</Button>
                                </div>
                            </Col>

                        </Row>
                        <Row className="mt-4 mb-3">
                            <Col className="tableWp">
                                <Row>
                                    <Col className="DashboardTbl">
                                        {/* <Table data={this.state.merged} pageSize={8} paging={false} columns={columns} search={false} headerStyle={headerStyle}
                                            rowStyle={rowStyle} maxBodyHeight='350px'
                                            actions={
                                                [
                                                    {
                                                        icon: 'edit',
                                                        tooltip: 'Edit',
                                                        onClick: (event, rowData) => {
                                                            console.log(rowData, "row data");
                                                            this.selectOne(rowData.id);
                                                        },
                                                    },
                                                    // {
                                                    //     icon: 'delete',
                                                    //     tooltip: 'Delete',
                                                    //     onClick: (event, rowData) => {
                                                    //         this.setState({ show: true, rowData: rowData });
                                                    //         console.log(rowData, "roe")
                                                    //     }
                                                    // },

                                                ]
                                            }
                                        /> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className="text-center mt-3">
                                <div className="center-block text-center">
                                    <Button className="text-center mr-3  btnblue">Submit</Button>
                                    <Button className="text-center " onClick={this.onClear.bind(this)} color="danger">Clear</Button>
                                </div>
                            </Col>
                        </Row> */}
                    </Form>
                </Card>
                <DeleteConfirm
                    show={this.state.show}
                    animation={true}
                    rowData={rowData}
                    id={rowData.id}
                    name={rowData.particularsName}
                    close={() => this.setState({ show: false })}
                    deleteHandler={this.deleteParticulars}
                />
            </Container>
        );
    }

}
export default withRouter(AddVesselParticulars);