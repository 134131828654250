
import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import Select from 'react-select';
import axios from '../../service/axiosConfig.js';
import { AxiosConfig } from "../Common/AxiosConfig";
import { withRouter } from "react-router";
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

class AddVesselDimensions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      columns: [
        { title: "Id", field: "id", hidden: true },
        // {
        //   title: 'Sr No', field: 'tableData.id',
        //   render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        // },
        { title: "Parameter", field: "parameterName" },
        { title: "Parameter Value", field: "parameter_value" },
        { title: "Unit", field: "unitName" },

      ],
      id: this.props.match.params.id,
      vessel_parameter: "",
      parameter_value: "",
      unit: "",

      selectedParameter: [],
      selectedUnit: [],
      DimensionData: [],
      DimensionData1: [],
      DimensionData2: [],

      message: "",
      message1: "",
      show: false,
      rowData: [],
      error: {},

      cr_id: "",
      cr_date: "",

      merged: [], vesselDetails: []
    }
  }

  //to get parameters from database
  async getVesselParameters() {
    const res = await axios.get("parameter")
    const data = res.data
    const parameterOptions = data.map(d => ({
      "value": d.id,
      "label": d.parameterName
    }))
    this.setState({ selectedParameter: parameterOptions })
  }

  //to get unit in dropdown 
  async getUnit() {
    const res = await axios.get("unit")
    const data = res.data
    const unitOptions = data.map(d => ({
      "value": d.id,
      "label": d.unitName
    }))
    this.setState({ selectedUnit: unitOptions })
  }

  //get all records 
  async getVesselDimension() {

    await AxiosConfig.get('/VesselDimension').then(response => {
      this.setState({ DimensionData: response.data })
    });

    await AxiosConfig.get('/getInfo').then(response => {
      this.setState({ DimensionData1: response.data })
    });

    await AxiosConfig.get('/getUnitInfo').then(response => {
      this.setState({ DimensionData2: response.data })
    });

    var data = this.state.DimensionData;
    var paraData = this.state.DimensionData1;
    var unitData = this.state.DimensionData2;

    const result = data.map(item => {
      const obj = paraData.find(o => o.id === item.id);
      const obj1 = unitData.find(p => p.id === item.id);

      return { ...item, ...obj, ...obj1 };
    });
    this.setState({ merged: result })

  }

  //to update
  updateVesselDimension(id) {
    AxiosConfig.get("VesselDimension/" + id)
      .then(response => {
        this.getData(id);
      })
  }

  //get single dimension data for update
  getData(id) {
    if (id != null) {
      // console.log("getData id is: ", + id);
      AxiosConfig.get("VesselDimension/" + id)
        .then(response => {
          console.log("response is: ", response)

          let dimension = response.data;
          this.props.inputData.dimensionid = id;
          this.props.inputData.vessel_parameter = dimension.vessel_parameter;
          this.props.inputData.parameter_value = dimension.parameter_value;
          this.props.inputData.unit = dimension.unit;
          this.props.inputData.cr_id = dimension.cr_id;
          this.props.inputData.cr_date = dimension.cr_date;

          this.setState({
            id: dimension.id,
            vessel_parameter: dimension.vessel_parameter,
            parameter_value: dimension.parameter_value,
            unit: dimension.unit,
            cr_id: dimension.cr_id,
            cr_date: dimension.cr_date,
          });

          //for vessel_particular
          const Parameter = this.state.selectedParameter.find((row) => {
            return row.value === this.props.inputData.vessel_parameter;
          });
          if (Parameter) {
            this.onParameterChange(Parameter);
          }

          //unit
          const unit = this.state.selectedUnit.find((row) => {
            return row.value === this.props.inputData.unit;
          });
          if (unit) {

            this.onUnitChange(unit);
          }

          if (unit) {

            this.onUnitChange(unit);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  componentDidMount() {
    this.getData()
    this.getVesselParameters();
    this.getUnit();
    // this.getVesselDimension();
    this.getVesselDetails();
  }

  onParameterChange = (obj) => {
    this.setState({ vessel_parameter: obj })
    this.props.inputData.vessel_parameter = obj
  }

  onUnitChange = (obj) => {
    this.setState({ unit: obj })
    this.props.inputData.unit = obj
  }

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  //validation

  validateForm = () => {
    let error = {};
    let formIsValid = true;
    if (!this.props.inputData.vessel_parameter) {
      formIsValid = false;
      error["vessel_parameter"] = "*Please Choose Vessel Parameter";
    }

    if (!this.props.inputData.unit) {
      formIsValid = false;
      error["unit"] = "*Please Choose unit";
    }
    if (!this.props.inputData.parameter_value) {
      formIsValid = false;
      error["parameter_value"] = "*Please Choose Parameter Value";
    }

    this.setState({
      error: error
    });
    return formIsValid;
  }
  validation = () => {
    // alert(this.validateForm())
    if (this.validateForm() === true) {

      this.props.next();
    }
    else {
      this.validateForm()
    }
  }

  getVesselDetails() {
    axios.get("VesselDetails").then((res) => {
      this.setState({ vesselDetails: res.data })
      console.log(this.state.vesselDetails, "vesselDetails")
    })
      .catch((error) => console.log(error))
  }
  //to save Dimension
  saveDimension = (e) => {
    e.preventDefault();
    e.target.reset();

    // var Dimension = {
    //   vessel_parameter: this.state.vessel_parameter.value,
    //   parameter_value: this.state.parameter_value,
    //   unit: this.state.unit.value
    // }

    var Dimension = {
      vessel_parameter: this.state.vessel_parameter.value,
      parameter_value: this.state.parameter_value,
      unit: this.state.unit.value,




    }
    console.log(Dimension, "Dimension")
    if (this.validateForm()) {
      AxiosConfig.post('VesselDimension', Dimension)
        .then((result) => {
          // alert("save")
          sessionStorage.setItem('dimension_id', result.data)
          console.log(result.data, "dimension_id");
          this.getVesselDimension();
          this.setState({
            message: 'Vessel Dimension Added sucessfully!!'
          })
          this.onReset();
        })
    }
    // .catch((error) => {
    //   console.log(error)
    // });
    this.outMessage();
  }

  //Update Dimension 

  updateDimension = (e) => {
    e.preventDefault();
    // e.target.reset();

    var Dimension = {
      id: this.props.inputData.dimensionid,

      vessel_parameter: this.props.inputData.vessel_parameter.value,
      parameter_value: this.props.inputData.parameter_value,
      unit: this.props.inputData.unit.value,
      cr_id: this.props.inputData.cr_id,
      cr_date: this.props.inputData.cr_date
    }
    if (this.validateForm()) {

      AxiosConfig.put('VesselDimension/' + this.state.id, Dimension)
        .then(result => {
          this.setState({
            message: 'Vessel Dimension Updated sucessfully!!',
            id: ''
          });
          this.getVesselDimension();
          this.onReset();
        })
    }
    // .catch((error) => {
    //   console.log(error);
    // });
    this.outMessage();
  }
  //delete 
  deleteDimension = (id) => {
    AxiosConfig.delete('VesselDimension/' + id, this.state)
      .then(response => {
        console.log(id)
        if (response === 201) {
          console.log(response);
        }
        this.getVesselDimension();
        this.setState({ message1: "Vessel Dimension  Deleted Successfully" });
      })
    this.outMessage();
  }

  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
    this.setState({
      message: "", message1: ""
    })
  }

  //to reset
  onReset = (e) => {
    this.setState({
      vessel_parameter: "",
      parameter_value: "",
      unit: ""
    })
    this.props.inputData.vessel_parameter = "";
    this.props.inputData.parameter_value = "";
    this.props.inputData.unit = "";
    this.props.inputData.dimensionid = "";
  }
  render() {
    const { merged, id, cr_id, cr_date, parameter_value, unit, selectedUnit, vessel_parameter, selectedParameter, DimensionData, rowData, VesselYearBuilt, columns, Data, selectedOption, } = this.state;
    return (
      <Container fluid className="formWpr">
        <h6>{this.state.message1 && (
          <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
            <a
              href="VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;
            </a>
            {this.state.message1}
          </div>)}
        </h6>
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;
              </a>
              {this.state.message}
            </div>)}
        </h6>

        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Dimensions</h3>
            </Col>
            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
          </Row>
          <Form
          //  onSubmit={this.state.id ? this.updateDimension : this.saveDimension}
          >
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Input
                    type="hidden"
                    name="id"
                    required
                    value={id}
                  />
                  <Input type="hidden" name="cr_id" required value={cr_id} />
                  <Input
                    type="hidden"
                    name="cr_date"
                    required
                    value={cr_date}
                  />

                  <Label className="required">Vessel Parameter</Label>
                  <Select
                    value={this.props.inputData.vessel_parameter}
                    onChange={this.props.onParameterChange}
                    options={selectedParameter}
                    name="vessel_parameter"
                  />
                  {this.props.errors1.vessel_parameter ?
                    <div className="errorMsg">{this.props.errors1.vessel_parameter} </div> :
                    <div className="errorMsg">{this.state.error.vessel_parameter} </div>
                  }

                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="required">Parameter Value</Label>
                  <Input type="text" required
                    name="parameter_value"
                    value={this.props.inputData.parameter_value}
                    placeholder="Enter Parameter Value"
                    onChange={this.props.test} />
                  {this.props.errors1.parameter_value ?
                    <div className="errorMsg">{this.props.errors1.parameter_value} </div> :
                    <div className="errorMsg">{this.state.error.parameter_value} </div>
                  }
                </FormGroup>
              </Col>
              <Col md={2}>
                <Label className="required">Unit</Label>
                <Select
                  value={this.props.inputData.unit}
                  onChange={this.props.onUnitChange}
                  options={selectedUnit}
                  name="unit"
                />
                {this.props.errors1.unit ?
                  <div className="errorMsg">{this.props.errors1.unit} </div> :
                  <div className="errorMsg">{this.state.error.unit} </div>}
              </Col>
            </Row>
            <Row>
              {this.props.inputData.dimensionid ?
                <Col md={2} className="AddBtnCol">
                  <Button onClick={this.updateDimension}>UPDATE</Button>
                </Col> :
                <Col md={2} className="AddBtnCol">
                  <Button onClick={this.validation}>NEXT</Button>
                </Col>}
              <Col md={2} npm startclassName="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center " color="danger" type="reset" onClick={this.onReset}>Clear</Button>
                </div>
              </Col>
            </Row>
            <Row className="mt-4 mb-3">
              <Col className="tableWp">
                <Row>
                  <Col className="DashboardTbl">
                    {/* <Table data={merged} pageSize={8} paging={false} columns={columns} search={false} headerStyle={headerStyle}
                      rowStyle={rowStyle} maxBodyHeight='350px'
                      actions={
                        [
                          {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                              this.getData(rowData.id)
                              // this.props.history.push(`/AddVesselParameters?id=${rowData._id}`)
                            }
                          },
                          // {
                          //   icon: 'delete',
                          //   tooltip: 'Delete',
                          //   onClick: (event, rowData) => {
                          //     //  this.props.history.push(`/Page?id=${rowData._id}`)
                          //     this.setState({ show: true, rowData: rowData })
                          //   }
                          // },
                        ]
                      }
                    /> */}
                  </Col>
                </Row>

              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Submit</Button>
                  <Button className="text-center " color="danger" type="reset" onClick={this.onReset}>Clear</Button>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Card>

        <DeleteConfirm show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.parameterName}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteDimension} />

      </Container>
    );
  }

}
export default withRouter(AddVesselDimensions);