import React from 'react'
import MaterialTable from "material-table";


 const Table= ({data, columns, headerStyle, rowStyle, search, actions,editable,pageSize,paging,maxBodyHeight,selection,onSelectionChange}) => {
  return (
    <div className="MTable">
      <MaterialTable style={{boxShadow:'none'}} stickyHeader
        options={{
          headerStyle: headerStyle,
          rowStyle: rowStyle, 
          pageSize:pageSize,
          paging:paging,       
          actionsColumnIndex: -1,
          search:search,
          maxBodyHeight:maxBodyHeight,
          searchFieldAlignment:"right",
          searchFieldStyle:{
          // border: "2px solid #e2e2e4" ,
          marginbottom:'20px'
          },
          selection :selection
        }}
        title={null}
        columns={columns}
        data={data}
        //editable={editable}
        className="wrap"
        actions={actions}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
}

export default Table;