
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import CheckIcon from '@material-ui/icons/Check';
import FileUploader from '../Common/FileUploader'
import Switch from "react-switch";
const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default  class VesselMaterialDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        
        columns: [  
            // { title: "Id", field: "id" , hidden: true }
            { title: "Vessel Id", field: "Id"},
            { title: "Material Id", field: "MaterialId"},
            { title: "Material Value", field: "MaterialValue"},
            { title: "Unit Id", field: "UnitId"},
            { title: "Approved", field: "Approved"},
            { title: "Serveyor Id", field: "ServeyorId"},
            { title: "Attachment 1", field: "Attachment1" , render: rowData => <CheckIcon className="CheckIcn" status={rowData.Attachment1} />},
            { title: "Attachment 2", field: "Attachment2"  , render: rowData => <CheckIcon className="CheckIcn"  status={rowData.Attachment2} />},
            { title: "Attachment 3", field: "Attachment3"  , render: rowData => <CheckIcon className="CheckIcn"  status={rowData.Attachment3} />},
            { title: "Creation Id", field: "CreationId"},
            { title: "Creation Date", field: "CreationDate", type:"Date"},
            { title: "Updation Id", field: "UpdationId"},
            { title: "Updation Date", field: "UpdationDate",type:"Date"},
          ],
          Data : [
            {Id: "01",MaterialId:"010", MaterialValue: "0876",UnitId: "01/10/2020",Approved:'Yes',ServeyorId:'011',CreationId: "0876",CreationDate: "01/10/2020",UpdationId: "0901",UpdationDate: "01/10/2020"},
            {Id: "01",MaterialId:"010", MaterialValue: "0876",UnitId: "01/10/2020",Approved:'Yes',ServeyorId:'011',CreationId: "0876",CreationDate: "01/10/2020",UpdationId: "0901",UpdationDate: "01/10/2020"},
            {Id: "01",MaterialId:"010", MaterialValue: "0876",UnitId: "01/10/2020",Approved:'Yes',ServeyorId:'011',CreationId: "0876",CreationDate: "01/10/2020",UpdationId: "0901",UpdationDate: "01/10/2020"},
            {Id: "01",MaterialId:"010", MaterialValue: "0876",UnitId: "01/10/2020",Approved:'Yes',ServeyorId:'011',CreationId: "0876",CreationDate: "01/10/2020",UpdationId: "0901",UpdationDate: "01/10/2020"},
            {Id: "01",MaterialId:"010", MaterialValue: "0876",UnitId: "01/10/2020",Approved:'Yes',ServeyorId:'011',CreationId: "0876",CreationDate: "01/10/2020",UpdationId: "0901",UpdationDate: "01/10/2020"},
          ],
          checked: false ,
         
        }
        this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  
  render(){
    const{Data,columns} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
        <Card className="shadow p-4 col-md-12">
       <Card className="shadow p-4 col-md-8 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Material Details Master</h3>
         </Col>
       </Row>
       <Form>
       {/* <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Vessel Id</Label>
                <Input type="Number" name="VesselId"  placeholder=""/>
            </FormGroup>
           </Col>
           <Col  md={6}>
            <FormGroup>
                <Label>Material Id</Label>
                <Input type="Number" name="MaterialId" placeholder=""/>
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Material Value</Label>
                <Input type="text" name="MaterialValue"  placeholder=""/>
            </FormGroup>
           </Col>
           <Col  md={6}>
            <FormGroup>
                <Label>Unit Id</Label>
                <Input type="Number" name="UnitId" placeholder=""/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Approved</Label><br/>
                <Switch onChange={this.handleChange} checked={this.state.checked}  icon={false}/>
            </FormGroup>
           </Col>
           <Col  md={6}>
            <FormGroup>
                <Label>Surveyor Id</Label>
                <Input type="Number" name="SurveyorId" placeholder=""/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Attachment 1</Label>
               <FileUploader/>
            </FormGroup>
           </Col>
           <Col  md={6}>
            <FormGroup>
                <Label>Attachment 2</Label>
                <FileUploader/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Attachment 3</Label>
                <FileUploader/>
            </FormGroup>
           </Col>
        </Row>
        
        <Row>
          <Col className="text-center mt-2">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
       <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'visibility',
                        tooltip: 'View',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row>
       </Card>
    </Container>
    );
}

}