
import React from "react";
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import CheckIcon from '@material-ui/icons/Check';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AxiosConfig } from '../Common/AxiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,
}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',
}

export default class FlagMasterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Id", field: "id ", hidden: true },
        {
          title: "Sr No",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
        },
        { title: "Flag Name", field: "flagName" },
      
      ],
      FlagData: [],
      rowData: [],
      show: false,
      close: false,
      message: "",
    };
  }

  getFlags() {
    AxiosConfig.get('flagmaster').then(response => {
      this.setState({ FlagData: response.data })
    });
  }

  componentDidMount() {
    this.getFlags();
  }

  deleteFlag = (id) => {
    AxiosConfig.delete('flagmaster/' + id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.getFlags();
        this.setState({ message: "Flag Deleted Successfully" });
      })
    this.outMessage();
  }


  updateFlag(id) {

    AxiosConfig.get("flagmaster/" + id).
      then(response => {
        console.log(response);
        this.props.history.push("/FlagMaster/" + id);
        // window.location.href =('/FlagMaster/'+id );
      })

  }
  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }

  render() {
    const { FlagData, columns, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <h6>
          {this.state.message && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="FlagMasterTable"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>

        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="">
              <h3 className="text-center blueText">Flag List</h3>
            </Col>
          </Row>
          <Row className="">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={FlagData}
                    pageSize={5}
                    paging={true}
                    columns={columns}
                    search={true}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="300px"
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          this.updateFlag(rowData.id);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          this.setState({ show: true, rowData: rowData });
                          // this.deleteFlag(rowData.id )
                          // this.props.history.push(`/Page?id=${rowData.id }`)
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <DeleteConfirm
          show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.flagName}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteFlag}
        />
      </Container>
    );
  }
}
