import React, { Component } from 'react'
// import jumpTo,{go} from '../../modules/Navigation'
//import device, { size } from '../../modules/mediaQuery'
// import logo from '../../assets/images/Logo.png'
// import { Container ,Col,Row,Card} from 'react-bootstrap'
// import InstagramIcon from '@material-ui/icons/Instagram';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import PhonePausedIcon from '@material-ui/icons/PhonePaused';
// import RoomIcon from '@material-ui/icons/Room';
// import EmailIcon from '@material-ui/icons/Email';
// import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
// import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
// import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
// import {InputGroup ,FormControl ,Button} from 'react-bootstrap'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import './footer.css'
import Logo from '../../assets/Shiplogo.png'
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
export default class Footer extends Component {
  render() {
    return (
      <div style={{ width:'100%'}} className="footerWp border-top pt-5">
       <footer class="site-footer"> 
    <div className="footer-top-area">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-wiz">
              <h3 className="footer-logo pl-0 ml-0">
                <img src={Logo} alt="footer logo"/>
                </h3>
             
              <ul class="footer-contact">
                <li><PhoneIcon className="phnIcn"/> +88 01911 837404</li>
                <li><EmailIcon className="EmailIcn"/>info@companyname.com</li>
              
              </ul>
            </div>
            <div className="top-social bottom-social"> <a href="#"><FacebookIcon/></a>
             <a href="#"><YouTubeIcon/></a>
             <a href="#"><TwitterIcon/></a> </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-wiz footer-menu">
              <h3 className="footer-wiz-title">CUSTOMIZED SEARCH</h3>
              <ul>
                <li><a href="#"><ArrowForwardIosIcon/>vessels on Sale</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>Shipping Companies</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>Maritime Events</a></li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-wiz footer-menu">
              <h3 className="footer-wiz-title">OUR SERVICES</h3>
              <ul>
                <li><a href="#"><ArrowForwardIosIcon/>Post Your Requirements</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>List Your Products</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>Advertise with Us</a></li>
              
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-wiz footer-menu">
              <h3 className="footer-wiz-title">HELP &amp; Assistance</h3>
              <ul>
                <li><a href="#"><ArrowForwardIosIcon/>Companies &amp; Gravience</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>Send Feedbacks</a></li>
                <li><a href="#"><ArrowForwardIosIcon/>Customer Care</a></li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom-area">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 wow fadeInLeft">Copyright © 2017 <span>Shiplords</span>. All Rights Reserved</div>
        </div>
      </div>
    </div>
  </footer>
    </div>
    )
  }
}