import react from 'react'
import TestimonialReview from './TestimonialReview'

const Testimonial = () =>{
    return(

    <div>
        <div class="wshipping-content-block news-testimonial-block">
    <div class="container wow fadeInUp">
      <div class="row"> 
        <div class="col-12 col-lg-5">
          <h3 class="heading3-border text-uppercase">Latest News</h3>
          <div class="latest-news-section mt0 wow fadeInUp">
            <div class="news-date"> 28<span>Feb</span> </div>
            <h4>News Title</h4>
            <div class="news-post-by">By <span>Admin</span></div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
          <div class="latest-news-section wow fadeInUp">
            <div class="news-date"> 23<span>Mar</span> </div>
            <h4>News Title</h4>
            <div class="news-post-by">By <span>Admin</span></div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
        </div>
        <div class="col-12 col-lg-7 home-testimonial">
          <h3 class="heading3-border text-uppercase mb-5">Testimonial</h3>
          <TestimonialReview/>
        </div>
      </div>
    </div>
  </div>
    </div>
    );
}

export default Testimonial;