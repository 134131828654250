import React from "react";
import { Card, Col, Row, Container } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import { AxiosConfig } from '../Common/AxiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,
}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',
}
export default class VesselClassList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Sr no", field: "Id", hidden: true },
        {
          title: "Sr No",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
        }, { title: "Class Name", field: "className" },],
      classData: [],
      rowData: [],
      show: false,
      close: false,
      message: "",
    }
  }
  getClass() {
    AxiosConfig.get('VesselClass').then(response => {
      this.setState({ classData: response.data })
    });
  }

  componentDidMount() {
    this.getClass();
  }

  deleteClass = (id) => {
    AxiosConfig.delete('VesselClass/' + id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.getClass();
        this.setState({ message: "Class Deleted Successfully" });
      })

    this.outMessage();
  }
  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  selectOne(id) {
    AxiosConfig.get("VesselClass/" + id).
      then(response => {
        console.log(response, "dataaa")
        this.props.history.push("/AddClassDetails/" + id);
      })
  }

  render() {
    const { rowData, columns, classData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
        <h6>
          {this.state.message && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="AddClassDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >&times;
              </a>
              {this.state.message}
            </div>
          )} </h6>
        <Card className="shadow p-4 col-md-12 ">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Class List</h3>
            </Col>
          </Row>
          <Row className="">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={classData} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.selectOne(rowData.id);
                          },
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ show: true, rowData: rowData });
                          }
                        }
                      ]
                    } />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <DeleteConfirm
          show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.className}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteClass}
        />
      </Container>
    );
  }
}
