import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ss from '../../../assets/ss.png'
import s2 from '../../../assets/secondImg.png'
import s3 from '../../../assets/ThirdImg.png'
// import Fourth from '../../../assets/Fourth.png'
import s5 from '../../../assets/fifth.png'
import s6 from '../../../assets/sixth.png'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={() => onClick()} />;
};

class ValuableClient extends Component {
  _isMounted = false;

  state = {
    awsApiData: [],
    loading: true,
    selectedIndex: 0,
    lightboxIsOpen: false,
    
  };

  render() {
   
    return (
 
            <Carousel  responsive={responsive} partialVisible={true}  >
                <div className="mr-1">
                    <img src={ss}/>
                  </div>
                  <div  className="mr-1">
                    <img src={s2}/>
                  </div>
                  <div  className="mr-1">
                  <img src={s3}/>
                  </div>
                  <div  className="mr-1">
                  <img src={s6}/>
                  </div>
                  <div  className="mr-1">
                  <img src={s5}/>
                  </div>
                  <div  className="mr-1">
                  <img src={s6}/>
                  </div>
                  <div  className="mr-1">
                  <img src={s6}/>
                  </div>
                </Carousel>
       
      
    );
  }
}

export default ValuableClient;