
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label,Col,Row,Container ,Button} from 'reactstrap';
import {AxiosConfig} from '../Common/AxiosConfig.js';

export default  class VesselOwnerDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      Data:[]
      
    }
  this.getOwner = this.getOwner.bind(this)
  }

  getOwner(){
    AxiosConfig.get('vesselOwnerMaster')
    .then(response =>{
      this.setState({Data: response.data});
    });
}

componentDidMount(){
  this.getOwner();
}

selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }
render(){
  const {
    vessel_Owner_Id,
    vessel_Owner_Name,
    vesselOwnerRegNo,
    vessel_Owner_Add1,
    vessel_Owner_Add2,
    vessel_Owner_Add3,
    vessel_Owner_City,
    vessel_Owner_State,
    vessel_Owner_Country,
    vessel_Owner_OffPh1,
    vessel_Owner_OffPh2,
    vessel_Owner_Website,
    vesselOwnerEmail,
    vessel_Owner_ContactPerson,
    vessel_Owner__CP_ContactNo,
    vessel_Owner__CP_Email,
    cr_ID,
    cr_Date,
  } = this.state;
 
  
    return (
      <Container fluid className=" FormWp">
        <Card className="shadow p-5 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Owner Details</h3>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Owner Name :</Label>
                  <Label className="normalTxt" value={vessel_Owner_Name}>
                    {vessel_Owner_Name}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Register No:</Label>
                  <Label className="normalTxt" value={vesselOwnerRegNo}>
                    {vesselOwnerRegNo}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Address 1:</Label>
                  <Label className="normalTxt" value={vessel_Owner_Add1}>
                    {vessel_Owner_Add1}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Address 2:</Label>
                  <Label className="normalTxt" value={vessel_Owner_Add2}>
                    {vessel_Owner_Add2}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">State:</Label>
                  <Label className="normalTxt" value={vessel_Owner_State}>
                    {vessel_Owner_State}
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="DetailText">Country:</Label>
                  <Label className="normalTxt" value={vessel_Owner_Country}>
                    {vessel_Owner_Country}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Office Phone No 1:</Label>
                  <Label className="normalTxt" value={vessel_Owner_OffPh1}>
                    {vessel_Owner_OffPh1}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Office Phone No 2:</Label>
                  <Label className="normalTxt" value={vessel_Owner_OffPh2}>
                    {vessel_Owner_OffPh2}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Official Website:</Label>
                  <Label className="normalTxt" value={vessel_Owner_Website}>
                    {vessel_Owner_Website}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Email:</Label>
                  <Label className="normalTxt" value={vesselOwnerEmail}>
                    {vesselOwnerEmail}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Contact Person:</Label>
                  <Label
                    className="normalTxt"
                    value={vessel_Owner_ContactPerson}
                  >
                    {vessel_Owner_ContactPerson}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Contact No:</Label>
                  <Label
                    className="normalTxt"
                    value={vessel_Owner__CP_ContactNo}
                  >
                    {vessel_Owner__CP_ContactNo}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="DetailText">Contact Person's Email:</Label>
                  <Label
                    className="normalTxt"
                    value={vessel_Owner__CP_Email}
                  ></Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-2">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Back</Button>
                  {/* <Button className="text-center " color="danger">Reset</Button> */}
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
}
} 
