import React from 'react'

import { Container} from 'react-bootstrap'
import TopNav from './components/TopNav'
import Menu from './components/Menu'


export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {

    return (
      <Container fluid className="pl-0 pr-0">
        <TopNav />
        <Menu />
      </Container>
    )
  }
}



