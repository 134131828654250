import React from 'react'
import { Navbar,Nav, NavItem, NavDropdown, MenuItem ,Container,Col,Row,Form,FormControl,Button} from 'react-bootstrap'
import ListIcon from '@material-ui/icons/List';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import Logo from '../../../assets/Shiplogo.png'
import SearchIcon from '@material-ui/icons/Search';
export default function Menu({
  //types,
  getProductsByCategory,
  getAllProducts
}) {
  return (
   <div className="MenuWp">
     <Navbar collapseOnSelect expand="lg">
     <Navbar.Brand href="#home"><img src={Logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#features">Home</Nav.Link>
            <Nav.Link href="#pricing">Marine Equipment</Nav.Link>
            <Nav.Link href="#pricing">Ship For Sale</Nav.Link>
            <Nav.Link href="#pricing">Shipping Companies</Nav.Link>
            <Nav.Link href="#pricing">Maritime Events</Nav.Link>
          </Nav>
          <Nav>
              <Button  className="MyaccntBtn" > <SearchIcon/></Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
   </div>
  )
}
