import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route } from "react-router-dom";

import Topbar from "../Topbar/Topbar";
import Home from '../../Containers/Home/Home'
import VesselClassList from '../../Containers/VesselClass/VesselClassList'
import AddClassDetails from '../../Containers/VesselClass/AddClassDetails'
import VesselOwnerDetails from '../../Containers/VesselOwner/VesselOwnerDetails'
import VesselOwnerListTable from '../../Containers/VesselOwner/VesselOwnerListTable'
import VesselOwnerDetailInfo from '../../Containers/VesselOwner/VesselOwnerDetailInfo'
import VesselOwnerListUpdateTable from '../../Containers/VesselOwner/VesselOwnerListUpdateTable'
import FlagMaster from '../../Containers/VesselFlag/FlagMaster'
import FlagMasterTable from '../../Containers/VesselFlag/FlagMasterTable'
import SignUp from '../../Containers/Login/Signup'
import Login from '../../Containers/Login/Login'
import SurveyorMaster from '../../Containers/VesselSurveyor/SurveyorMaster'
import SurveyorDetailConfirmation from '../../Containers/VesselSurveyor/SurveyorDetailConfirmation'
import AddParameter from '../../Containers/VesselParameter/AddParameter'
import AddVesselType from '../../Containers/VesselType/AddVesselType'
// import AddVesselDetails from '../../Containers/VesselDetails/AddVesselDetails'
import VesselEquipmentMaster from '../../Containers/VesselEquipmentMaster/VesselEquipmentMaster'
import VesselEquipmentDetails from '../../Containers/VesselEquipmentDetails/VesselEquipmentDetails'
import UnitMaster from '../../Containers/UnitMaster/UnitMaster'
import VesselDrawingTypeMaster from '../../Containers/VesselDrawingTypeMaster/VesselDrawingTypeMaster'
import VesselDesignMaster from '../../Containers/VesselDesignMaster/VesselDesignMaster'
import VesselClassWiseDrawingMaster from '../../Containers/VesselClassWiseDrawingMaster/VesselClassWiseDrawingMaster'
import MaterialMaster from '../../Containers/MaterialMaster/MaterialMaster'
import VesselMaterialDetails from '../../Containers/VesselMaterialDetails/VesselMaterialDetails'
import AddClassSurveyors from '../../Containers/VesselSurveyor/AddClassSurveyors'
import InspectionTypeMaster from '../../Containers/InspectionTypeMaster/InspectionTypeMaster'
import AddVesselInspectionDetails from '../../Containers/VesselIspectionDetails/AddVesselInspectionDetails'
import AddVesselDrawingDetails from '../../Containers/VesselDrawingDetails/AddVesselDrawingDetails'
import VesselSurveyorsListTable from '../../Containers/VesselSurveyor/VesselSurveyorsListTable'
//import VesselDetailList from '../../Containers/VesselDetails/VesselDetailList'
import InspectionList from '../../Containers/InspectionTypeMaster/InspectionList'
import ParameterListTable from '../../Containers/VesselParameter/ParameterListTable'
import VesselTypeList from '../../Containers/VesselType/VesselTypeList'
import VesselDrawingTypeList from '../../Containers/VesselDrawingTypeMaster/VesselDrawingTypeList'
import VesselDesignMasterList from '../../Containers/VesselDesignMaster/VesselDesignMasterList'
import VesselClasswiseDrawingList from '../../Containers/VesselClassWiseDrawingMaster/VesselClasswiseDrawingList'
import MaterialMasterList from '../../Containers/MaterialMaster/MaterialMasterList'
import VesselDrawingList from '../../Containers/VesselDrawingDetails/VesselDrawingList'
import AddVesselDesignDetails from '../../Containers/VesselDesignMaster/AddVesselDesignDetails'
import AddVesselDesignDetailList from '../../Containers/VesselDesignMaster/AddVesselDesignDetailList'
import AddVesselDimensions from '../../Containers/VesselDetails/AddVesselDimensions'
import AddVesselEquipments from '../../Containers/VesselDetails/AddVesselEquipments'
import AddVesselMaterial from '../../Containers/VesselDetails/AddVesselMaterial'
import AddVesselDrawing from '../../Containers/VesselDetails/AddVesselDrawing'
import AddVesselDesign from '../../Containers/VesselDetails/AddVesselDesign'
import AddVesselParticulars from '../../Containers/VesselDetails/AddVesselParticulars'
import VesselDetails from '../../Containers/VesselDetails/VesselDetails'
import VesselDetailsList from '../../Containers/VesselDetails/VesselDetailsList'
import AllVesselDetailsList from '../../Containers/VesselDetails/AllVesselDetailsList'

import VesselClassMaster from '../../Containers/VesselClass/VesselClassMaster'
import FileUploader from "../../Containers/Common/FileUploader";
import UpdateVesselParticular from "../../Containers/VesselDetails/UpdateVesselParticular";
import UpdateVesselDimension from "../../Containers/VesselDetails/UpdateVesselDimension";
import UpdateVesselEquipment from "../../Containers/VesselDetails/UpdateVesselEquipment";
import UpdateVesselMaterial from "../../Containers/VesselDetails/UpdateVesselMaterial";
import UpdateVesselDrawing from "../../Containers/VesselDetails/UpdateVesselDrawing";
import UpdateVesselDesign from "../../Containers/VesselDetails/UpdateVesselDesign";

const Content = ({ isLoginPage,
  // sidebarIsOpen, toggleSidebar
}) => (
  <Container
    fluid
    className={isLoginPage ? "is-open content pl-0 pr-0" : "content"}>

    {
      !isLoginPage ? <Topbar
      // toggleSidebar={toggleSidebar} 
      /> : null
    }
    <Switch>

      <Route exact path="/Home" component={Home} />
      <Route path="/Login" component={Login} />
      <Route exact path="/SignUp" component={SignUp} />
      <Route exact path="/VesselClassList" component={VesselClassList} />
      <Route exact path="/AddClassDetails" component={AddClassDetails} />
      <Route exact path="/AddClassDetails/:id" component={AddClassDetails} />
      <Route exact path="/VesselOwnerDetails" component={VesselOwnerDetails} />
      <Route exact path="/VesselOwnerDetails/:id" component={VesselOwnerDetails} />
      <Route exact path="/VesselOwnerListTable" component={VesselOwnerListTable} />
      <Route exact path="/VesselOwnerDetailInfo" component={VesselOwnerDetailInfo} />
      <Route exact path="/VesselOwnerListUpdateTable" component={VesselOwnerListUpdateTable} />
      <Route exact path="/FlagMaster" component={FlagMaster} />
      <Route exact path="/FlagMaster/:id" component={FlagMaster} />
      <Route exact path="/FlagMasterTable" component={FlagMasterTable} />
      <Route exact path="/SurveyorMaster" component={SurveyorMaster} />
      <Route exact path="/SurveyorDetailConfirmation" component={SurveyorDetailConfirmation} />
      <Route exact path="/AddParameter" component={AddParameter} />
      <Route exact path="/AddParameter/:id" component={AddParameter} />
      <Route exact path="/AddVesselType" component={AddVesselType} />
      {/* <Route exact path="/AddVesselDetails" component={AddVesselDetails} /> */}
      <Route exact path="/VesselEquipmentMaster" component={VesselEquipmentMaster} />
      <Route exact path="/VesselEquipmentDetails" component={VesselEquipmentDetails} />
      <Route exact path="/UnitMaster" component={UnitMaster} />
      <Route exact path="/VesselDrawingTypeMaster" component={VesselDrawingTypeMaster} />
      <Route exact path="/VesselDrawingTypeMaster/:id" component={VesselDrawingTypeMaster} />
      <Route exact path="/VesselDesignMaster" component={VesselDesignMaster} />
      <Route exact path="/VesselDesignMaster/:id" component={VesselDesignMaster} />

      <Route exact path="/VesselClassWiseDrawingMaster" component={VesselClassWiseDrawingMaster} />
      <Route exact path="/MaterialMaster" component={MaterialMaster} />
      <Route exact path="/VesselMaterialDetails" component={VesselMaterialDetails} />
      <Route exact path="/AddClassSurveyors" component={AddClassSurveyors} />
      <Route exact path="/InspectionTypeMaster" component={InspectionTypeMaster} />
      <Route exact path="/InspectionTypeMaster/:id" component={InspectionTypeMaster} />
      <Route exact path="/AddVesselInspectionDetails" component={AddVesselInspectionDetails} />
      <Route exact path="/AddVesselDrawingDetails" component={AddVesselDrawingDetails} />
      <Route exact path="/VesselSurveyorsListTable" component={VesselSurveyorsListTable} />
      {/* <Route exact path="/VesselDetailList" component={VesselDetailList} /> */}
      <Route exact path="/InspectionList" component={InspectionList} />
      <Route exact path="/ParameterListTable" component={ParameterListTable} />
      <Route exact path="/VesselTypeList" component={VesselTypeList} />
      <Route exact path="/VesselDrawingTypeList" component={VesselDrawingTypeList} />
      <Route exact path="/VesselDesignMasterList" component={VesselDesignMasterList} />
      <Route exact path="/VesselClasswiseDrawingList" component={VesselClasswiseDrawingList} />
      <Route exact path="/MaterialMasterList" component={MaterialMasterList} />
      <Route exact path="/VesselDrawingList" component={VesselDrawingList} />
      <Route exact path="/AddVesselDesignDetails" component={AddVesselDesignDetails} />
      <Route exact path="/AddVesselDesignDetailList" component={AddVesselDesignDetailList} />
      <Route exact path="/VesselDetails" component={VesselDetails} />
      <Route exact path="/VesselDetailsList" component={VesselDetailsList} />
      <Route exact path="/AllVesselDetailsList" component={AllVesselDetailsList} />
      
      
      <Route exact path="/AddVesselDimensions" component={AddVesselDimensions} />
      <Route exact path="/AddVesselEquipments" component={AddVesselEquipments} />
      <Route exact path="/AddVesselMaterial" component={AddVesselMaterial} />
      <Route exact path="/AddVesselDrawing" component={AddVesselDrawing} />
      <Route exact path="/AddVesselDesign" component={AddVesselDesign} />
      <Route exact path="/AddVesselParticulars" component={AddVesselParticulars} />
      <Route exact path="/UpdateVesselParticular" component={UpdateVesselParticular} />
      <Route exact path="/UpdateVesselDimension" component={UpdateVesselDimension} />
      <Route exact path="/UpdateVesselEquipment" component={UpdateVesselEquipment} />
      <Route exact path="/UpdateVesselMaterial" component={UpdateVesselMaterial} />
      <Route exact path="/UpdateVesselDrawing" component={UpdateVesselDrawing} />
      <Route exact path="/UpdateVesselDesign" component={UpdateVesselDesign} />
      
      
      <Route exact path="/VesselClassMaster" component={VesselClassMaster} />
      <Route exact path="/AddVesselDesign/:vessel_id" component={AddVesselDesign} />
      <Route exact path="/UpdateVesselType/:id" component={AddVesselType} />
      <Route exact path="/MaterialMaster/:id" component={MaterialMaster} />
      <Route exact path="/SurveyorMaster/:id" component={SurveyorMaster} />
      <Route exact path="/FileUploader" component={FileUploader} />




    </Switch>
  </Container>
);


export default Content;

