
import React from "react";
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import {AxiosConfig} from '../Common/AxiosConfig.js'

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top:0,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}
export default  class VesselOwnerListUpdateTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      columns: [  
        // { title: "Id", field: "id" , hidden: true },
        { title: "Sr No", field: "Id"},
        { title: "Owner Name", field: "OwnerName"},
        { title: "Register No", field: "RegisterNo"},
        { title: "Created By", field: "CreatedBy" },
        { title: "Updated By", field: "UpdatedBy"}        
      ],
      Data : [ ]
    }
    this.getOwner = this.getOwner.bind(this)
  }
  getOwner(){
    AxiosConfig.get('vesselOwnerMaster')
    .then(response =>{
      this.setState({Data: response.data});
    });
}

componentDidMount(){
  this.getOwner();
}

deleteOwner(vessel_Owner_ID){
  AxiosConfig.delete('vesselOwnerMaster/'+vessel_Owner_ID)
  .then(response =>{
    if(response === 201){
      console.log(response);
    }
    this.getOwner();
  })
}

selectOne(vessel_Owner_ID){
  AxiosConfig.get("/vesselOwnerMaster/"+vessel_Owner_ID).
  then(response =>{
    this.props.history.push("/VesselOwnerDetails/"+vessel_Owner_ID);
  })
}
render(){
  const{SelectParentCategory,Data,columns} =this.state;
    return(

    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
       <Card className="shadow p-4 col-md-12">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Owner List Updated</h3>
         </Col>
       </Row>
        <Row className="">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.selectOne(rowData.vessel_Owner_ID)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.deleteOwner(rowData.vessel_Owner_ID)
                        }
                      },
                      {
                        icon: 'visibility',
                        tooltip: 'View',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                 
                   
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row>
       </Card>
    </Container>
    );
}
} 
