import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
  Card,
} from "reactstrap";
import classnames from "classnames";
import Table from "../Common/Table";
import { Modal } from "react-bootstrap";
import axios from "../../service/axiosConfig.js";
import DeleteConfirm from "../../Containers/Common/deleteComf.js";
import { AxiosConfig, ThumbnailImageUrl } from "../Common/AxiosConfig";
import VisibilityIcon from "@material-ui/icons/Visibility";

const headerStyle = {
  backgroundColor: "#052754",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

export default class VesselDetailsList extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      vesselParticular: [],
      vesselDimension: [],
      vesselEquipment: [],
      vesselMaterial: [],
      dataByVesselId: [],
      particularRowData: [],
      vesselDrawing: [],
      vesselDesign: [],
      url: "",
      vesselId: sessionStorage.getItem("vesselId"),

      showHide: false,
      vesselDesignColumns: [
        {
          title: "Design",
          field:
            "value.vesselDesignModel.vesselDesignTypeMaster.designTypeName",
        },
        {
          title: "Availble",
          field: "value.vesselDesignModel.available",
          render: (rowData) => {
            return rowData.availble ? "YES" : "NO";
          },
        },
        {
          title: "Attachment 1",
          field: "value.vesselDesignModel.thumbnail1Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer"
              onClick={() =>
                this.handleModalShowHide(
                  rowData.value.vesselDesignModel.id,
                  rowData.value.vesselDesignModel.thumbnail1Name
                )
              }
              status={rowData.value.vesselDesignModel.id}
            />
          ),
        },
        {
          title: "Attachment 2",
          field: "value.vesselDesignModel.thumbnail2Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer "
              onClick={() =>
                this.handleModalShowHide1(
                  rowData.value.vesselDesignModel.id,
                  rowData.value.vesselDesignModel.thumbnail2Name
                )
              }
              status={rowData.value.vesselDesignModel.id}
            />
          ),
        },
        {
          title: "Attachment 3",
          field: "value.vesselDesignModel.thumbnail3Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer"
              onClick={() =>
                this.handleModalShowHide2(
                  rowData.value.vesselDesignModel.id,
                  rowData.value.vesselDesignModel.thumbnail3Name
                )
              }
              status={rowData.value.vesselDesignModel.id}
            />
          ),
        },
      ],
      vesselDrawingColumns: [
        { title: "Id", field: "id", hidden: true },

        {
          title: "Drawing",
          field: "value.vesselDrawingModel.drawing_typeModel.drwTypeName",
        },
        {
          title: "Available",
          field: "value.vesselDrawingModel.vesselDrawing_available",

          render: (rowData) => {
            return rowData.value.vesselDrawingModel.vesselDrawing_available
              ? "YES"
              : "NO";
          },
        },
        {
          title: "Attachment 1",
          field: "value.vesselDrawingModel.thumbnail1Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer"
              onClick={() =>
                this.handleModalShowHide(
                  rowData.value.vesselDrawingModel.id,
                  rowData.value.vesselDrawingModel.thumbnail1Name
                )
              }
              status={rowData.value.vesselDrawingModel.id}
            />
          ),
        },
        {
          title: "Attachment 2",
          field: "value.vesselDrawingModel.thumbnail2Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer"
              onClick={() =>
                this.handleModalShowHide1(
                  rowData.value.vesselDrawingModel.id,
                  rowData.value.vesselDrawingModel.thumbnail2Name
                )
              }
              status={rowData.value.vesselDrawingModel.id}
            />
          ),
        },
        {
          title: "Attachment 3",
          field: "value.vesselDrawingModel.thumbnail3Name",
          render: (rowData) => (
            <VisibilityIcon
              className="visiblityIcnViewer"
              onClick={() =>
                this.handleModalShowHide2(
                  rowData.value.vesselDrawingModel.id,
                  rowData.value.vesselDrawingModel.thumbnail3Name
                )
              }
              status={rowData.value.vesselDrawingModel.id}
            />
          ),
        },
      ],

      show: "",
      vesselParticularColumns: [
        {
          title: "Id",
          field: "tableData.id",
          render: (rowData) => {
            return <p>{rowData.tableData.id + 1}</p>;
          },
          hidden: true,
        },
        {
          title: "Vessel Name",
          field: "value.vesselParticularsModel.particularsName",
        },
        {
          title: "Vessel Flag",
          field: "value.vesselParticularsModel.vesselFlagMaster.flagName",
        },
        {
          title: "Vessel Year Built",
          field: "value.vesselParticularsModel.vessel_Year_Built",
        },
        {
          title: "Vessel Type",
          field: "value.vesselParticularsModel.vesselTypeModel.vesselTypeName",
        },
        {
          title: "Vessel GRT",
          field: "value.vesselParticularsModel.vessel_GRT",
        },
        {
          title: "Vessel DWT",
          field: "value.vesselParticularsModel.vessel_DWT",
        },
        {
          title: "Vessel Class",
          field: "value.vesselParticularsModel.vesselClassModel.className",
        },
        {
          title: "Vessel Owner",
          field:
            "value.vesselParticularsModel.vesselOwnerMaster.vessel_Owner_Name",
        },
        {
          title: "Vessel Commissioned",
          field: "value.vesselParticularsModel.vessel_Commissioned",
          render: (rowData) => {
            return rowData.vessel_Commissioned ? "YES" : "NO";
          },
        },
      ],

      vesselDimensionColumns: [
        // { title: "Id", field: "id", hidden: true },
        // {
        //   title: 'Sr No', field: 'tableData.id',
        //   render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        // },
        {
          title: "Parameter",
          field: "value.vesselDimensionModal.parameterModel.parameterName",
        },
        {
          title: "Parameter Value",
          field: "value.vesselDimensionModal.parameter_value",
        },
        {
          title: "Unit",
          field: "value.vesselDimensionModal.unitModel.unitName",
        },
      ],
      vesselEquipmentColumns: [
        // { title: "Id", field: "id", hidden: true },
        {
          title: "Equipment",
          field:
            "value.vesselEquipmentModel.vesselEquipmentMaster.vesselEqpname",
        },
        {
          title: "Equipment Value",
          field: "value.vesselEquipmentModel.equipmentValue",
        },
      ],
      vesselMaterialColumns: [
        { title: "Id", field: "material_id", hidden: true },
        // {
        //   title: 'Sr No', field: 'tableData.id',
        //   render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        // },
        {
          title: "Material",
          field: "value.vesselMaterialModel.materialModel.materialName",
        },
        {
          title: "Material Value",
          field: "value.vesselMaterialModel.material_Value",
        },
        {
          title: "Unit",
          field: "value.vesselMaterialModel.unitModel.unitName",
        },
        {
          title: "Surveyor",
          field: "value.vesselMaterialModel.surveyorModel.surveyorName",
        },
        {
          title: "Approved",
          field: "value.vesselMaterialModel.approved",
          render: (rowData) => {
            return rowData.value.vesselMaterialModel.approved ? "YES" : "NO";
          },
        },
      ],

      // particularsName: "",
    };
  }
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  componentDidMount() {
    axios.get("VesselDetails/" + this.state.vesselId).then((res1) => {
      const singleItemList = [{ key: 0, value: res1.data }];
      this.setState({ dataByVesselId: singleItemList });
      console.log(this.state.dataByVesselId);
    });
  }
  // getDataByVesselId1() {

  // }
  getDataByVesselId() {
    axios.get("VesselDetails/" + this.state.vesselId).then((res1) => {
      this.setState({ dataByVesselId: res1.data });

      axios.get("flagmaster").then((res2) => {
        axios.get("VesselClass").then((res3) => {
          axios.get("vesselOwnerMaster").then((res4) => {
            axios.get("VesselType").then((res5) => {
              AxiosConfig.get("/getInfo").then((res6) => {
                AxiosConfig.get("/getUnitInfo").then((res7) => {
                  AxiosConfig.get("getEquipmentInfo").then((res8) => {
                    AxiosConfig.get("/getMaterialNameInfo").then((res9) => {
                      AxiosConfig.get("/getMaterialUnitInfo").then((res10) => {
                        AxiosConfig.get("/getSurveyorInfo").then((res11) => {
                          AxiosConfig.get("getDrawingInfo").then((res12) => {
                            axios.get("getInfoDesign").then((res13) => {
                              var a1 = res1.data;

                              const obj = res2.data.find(
                                (o) =>
                                  o.id === a1.vesselParticularsModel.vessel_Flag
                              );
                              const obj1 = res3.data.find(
                                (p) =>
                                  p.id ===
                                  a1.vesselParticularsModel.vessel_class
                              );
                              const obj2 = res4.data.find(
                                (p) =>
                                  p.id ===
                                  a1.vesselParticularsModel.vessel_Owner
                              );
                              const obj3 = res5.data.find(
                                (p) =>
                                  p.id === a1.vesselParticularsModel.vessel_Type
                              );
                              const obj4 = res6.data.find(
                                (o) =>
                                  o.id ===
                                  a1.vesselDimensionModal.vessel_parameter
                              );
                              const obj5 = res7.data.find(
                                (p) => p.id === a1.vesselDimensionModal.unit
                              );
                              const obj6 = res8.data.find(
                                (o) =>
                                  o.id ===
                                  a1.vesselEquipmentModel.vesselEquipment
                              );
                              const obj7 = res9.data.find(
                                (o) =>
                                  o.id === a1.vesselMaterialModel.material_Name
                              );
                              const obj8 = res10.data.find(
                                (p) =>
                                  p.id === a1.vesselMaterialModel.material_Unit
                              );
                              const obj9 = res11.data.find(
                                (q) => q.id === a1.vesselMaterialModel.surveyor
                              );
                              const obj10 = res12.data.find(
                                (o) =>
                                  o.id ===
                                  a1.vesselDrawingModel.vesselDrawing_name
                              );
                              const obj11 = res13.data.find(
                                (o) =>
                                  o.id ===
                                  a1.vesselDesignModel.vessel_Design_name
                              );

                              let designarr = a1.vesselDesignModel;

                              delete Object.assign(designarr, {
                                designthumbnail1Name:
                                  a1.vesselDesignModel.thumbnail1Name,
                              })["thumbnail1Name"];
                              delete Object.assign(designarr, {
                                designthumbnail2Name:
                                  a1.vesselDesignModel.thumbnail2Name,
                              })["thumbnail2Name"];
                              delete Object.assign(designarr, {
                                designthumbnail3Name:
                                  a1.vesselDesignModel.thumbnail3Name,
                              })["thumbnail3Name"];
                              delete Object.assign(designarr, {
                                vesseldesignid: a1.vesselDesignModel.id,
                              })["id"];

                              let particulararr = a1.vesselParticularsModel;
                              delete Object.assign(particulararr, {
                                vesselParticularId:
                                  a1.vesselParticularsModel.id,
                              })["id"];
                              let equipmentarr = a1.vesselEquipmentModel;
                              delete Object.assign(equipmentarr, {
                                vesselEquipmentId: a1.vesselEquipmentModel.id,
                              })["id"];
                              let drawingarr = a1.vesselDrawingModel;
                              delete Object.assign(drawingarr, {
                                vesselDrawingId: a1.vesselDrawingModel.id,
                              })["id"];
                              let result = {
                                ...a1,
                                ...designarr,
                                ...equipmentarr,
                                ...drawingarr,
                                ...a1.vesselDrawingModel,
                                ...particulararr,
                                ...a1.vesselEquipmentModel,
                                ...obj,
                                ...obj1,
                                ...obj2,
                                ...obj3,
                                ...obj4,
                                ...obj5,
                                ...obj6,
                                ...obj7,
                                ...obj8,
                                ...obj9,
                                ...obj10,
                                ...obj11,
                              };

                              let array = [];
                              array.push(result);
                              console.log(array);
                              this.setState({ dataByVesselId: array });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  handleModalShowHide(id, thumbnailName) {
    this.setState({ showHide: !this.state.showHide });

    this.setState({
      url: thumbnailName,
    });
  }
  handleModalShowHide1(id, thumbnailName) {
    this.setState({ showHide: !this.state.showHide });

    this.setState({
      url: thumbnailName,
    });
  }
  handleModalShowHide2(id, thumbnailName) {
    this.setState({ showHide: !this.state.showHide });

    this.setState({
      url: thumbnailName,
    });
  }

  deleteParticulars = (id) => {
    axios.delete(`VesselParticulars/` + id, this.state).then((response) => {
      this.setState({ message1: "Vessel particular Deleted Successfully" });
      this.getParticularsDetails();
    });
    this.outMessage();
  };
  render() {
    return (
      <div className="TablistWp card p-2" style={{ border: "none" }}>
        <Nav tabs className="NavTabs">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
              // onClick={() => { this.validation() }}
            >
              Particulars
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
              // onClick={() => { this.validation('2') }}
            >
              Dimensions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
              // onClick={() => { this.validation('3') }}
            >
              Equipments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "4" })}
              onClick={() => {
                this.toggle("4");
              }}
              // onClick={() => { this.validation('4') }}
            >
              Material
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "5" })}
              onClick={() => {
                this.toggle("5");
              }}
              // onClick={() => { this.validation('5') }}
            >
              Drawings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "6" })}
              onClick={() => {
                this.toggle("6");
              }}
              // onClick={() => { this.validation('6') }}
            >
              Designs
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12" className="mt-5">
                {/* <AddVesselParticulars test={this.handleParticular} handleChange1={this.handleChange1} handleChange2={this.handleChange2} handleChange3={this.handleChange3} handleChange4={this.handleChange4} switchhandleChange={this.switchhandleChange} inputData={this.state} next={() => this.toggle('2')} errors={this.state.errors} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  columns={this.state.vesselParticularColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        sessionStorage.setItem(
                          "particularId",
                          rowData.value.vesselParticularsModel.id
                        );
                        this.props.history.push("/UpdateVesselParticular");
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col className="mt-5">
                {/* <AddVesselDimensions onParameterChange={this.onParameterChange} test={this.handleDimension} onUnitChange={this.onUnitChange} inputData={this.state} next={() => this.toggle('3')} errors1={this.state.errors1} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  columns={this.state.vesselDimensionColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        // this.getData(rowData.id)
                        this.props.history.push("/UpdateVesselDimension");
                        console.log(
                          rowData.value.vesselDimensionModal.id,
                          "row data"
                        );
                        sessionStorage.setItem(
                          "dimensionId",
                          rowData.value.vesselDimensionModal.id
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col className="mt-5">
                {/* <AddVesselEquipments test={this.handleEquipments} onInputChange={this.onInputChange} inputData={this.state} next={() => this.toggle('4')} errors={this.state.errors} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  columns={this.state.vesselEquipmentColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        this.props.history.push("/UpdateVesselEquipment");
                        sessionStorage.setItem(
                          "equipmentid",
                          rowData.value.vesselEquipmentModel.id
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col className="mt-5">
                {/* <AddVesselMaterial inputData={this.state} test={this.handleMaterial} onMaterialChange={this.onMaterialChange} onUnitChange={this.onUnitChange1} onSurveyorChange={this.onSurveyorChange} SwitchhandleChange={this.SwitchhandleChange1} next={() => this.toggle('5')} errors={this.state.errors} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  Approved
                  columns={this.state.vesselMaterialColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        this.props.history.push("/UpdateVesselMaterial");
                        sessionStorage.setItem(
                          "materialId",
                          rowData.value.vesselMaterialModel.id
                        );
                        // this.props.history.push(`/AddVesselMaterial?id=${rowData._id}`)
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col className="mt-5">
                {/* <AddVesselDrawing inputData={this.state} test={this.handleDrawings} onInputChange={this.onInputChange1} switchhandleChange={this.switchhandleChange2} onDropAttachment1={this.onDropAttachment1} onDropAttachment2={this.onDropAttachment2} onDropAttachment3={this.onDropAttachment3} next={() => this.toggle('6')} errors={this.state.errors} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  columns={this.state.vesselDrawingColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                       
                        this.props.history.push("/UpdateVesselDrawing");
                        sessionStorage.setItem(
                          "drawingId",
                          rowData.value.vesselDrawingModel.id
                        );

                        // this.selectOne(rowData.id)
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col className="mt-5">
                {/* <AddVesselDesign inputData={this.state} test={this.handleDesigns} handleChange={this.handleChange} switchhandleChange={this.switchhandleChange3} onDropAttachment1={this.onDropAttachment11} onDropAttachment2={this.onDropAttachment22} onDropAttachment3={this.onDropAttachment33} onSubmit={this.onSubmit} next={() => this.toggle('7')} errors={this.state.errors} /> */}
                <Table
                  data={this.state.dataByVesselId}
                  pageSize={8}
                  paging={false}
                  columns={this.state.vesselDesignColumns}
                  search={false}
                  headerStyle={headerStyle}
                  rowStyle={rowStyle}
                  maxBodyHeight="350px"
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        console.log(rowData, "row data");
                        this.props.history.push("/UpdateVesselDesign");
                        sessionStorage.setItem(
                          "designid",
                          rowData.value.vesselDesignModel.id
                        );
                        // this.selectOne(rowData.id);
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <Modal show={this.state.showHide} className="ViewModal">
          <Modal.Header
            closeButton
            onClick={() => {
              this.handleModalShowHide();
              this.handleModalShowHide1();
              this.handleModalShowHide2();
            }}
          >
            <Modal.Title>Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <img className="photo" src={this.state.url} /> */}
            <img
              className="photo"
              src={ThumbnailImageUrl + `${this.state.url}`}
            />
          </Modal.Body>
        </Modal>
        {/* <Row>
          <Col className="text-center mt-5">
            <div className="center-block text-center">
              <Button className="text-center mr-3  btnblue">Submit</Button>
              <Button className="text-center " color="danger">Cancel</Button>
            </div>
          </Col>
        </Row> */}
      </div>
    );
  }
}
