import React, { Component } from 'react'
import { Navbar,Nav, NavItem, NavDropdown, MenuItem ,Container,Col,Row} from 'react-bootstrap'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrainIcon from '@material-ui/icons/Train';
import PhoneIcon from '@material-ui/icons/Phone';
import ListIcon from '@material-ui/icons/List';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
const TopNav =() =>{
    return(
        <div className="TopNav">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="#"> <TrainIcon className="TrainIcn"/> How we Work </Nav.Link>
                    <div  className="verticalDivider"></div>
                    <Nav.Link href="#"><PhoneIcon className="PhnIcn"/>Technical Assistance</Nav.Link>
                    <div  className="verticalDivider"></div>
                    <Nav.Link href="#"><ListIcon className="ListIcon"/>List Your Product</Nav.Link>
                    <div  className="verticalDivider"></div>
                    <Nav.Link href="#"><MailIcon className="MailIcon"/>Post Your Requirement</Nav.Link>
                </Nav>
                <Nav>
                <Nav.Link eventKey={2} href="#memes">
                  <ExitToAppIcon className="mr-2 loginIcn"/>
                  Login
                </Nav.Link>
                <div  className="verticalDivider"></div>
                <Nav.Link eventKey={2} href="#memes">
                  <EmojiEventsIcon className="mr-2 upgradePremiumIcn"/>
                 Become a vendor
                </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
    </div>
    );
    
}
export default TopNav;