import React from "react";
import { Card, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table';
import '../../Containers/Common/Table.css';
import DeleteConfirm from '../../Containers/Common/deleteConf.js';
import axios from '../../service/axiosConfig.js';
import $ from 'jquery';
const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

const springUrl = 'VesselType';

export default class VesselTypeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectParameter: [
        { value: 'Dimension' },
        { value: 'Width' }
      ],
      multiSelctParameter: [{ value: 'Dimensions', menu: [{ value: "length" }, { value: "width" }] }],
      columns: [
        { title: "Id", field: "id", hidden: true },
        {
          title: 'Sr No', field: 'tableData.id',
          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        },
        { title: "Vessel Type Name", field: "vesselTypeName" },
      ],

      rowData: [],
      show: false,
      close: false,
      message: ""
    }
  }
  componentDidMount() {
    this.refreshList();
  }
  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/AddVesselType');
  }
  refreshList() {
    axios.get(springUrl)
      .then(Response => {
        this.setState({ Data: Response.data });
        console.log(Response.data);
      })
  }
  deleteType = (id) => {
    // this.setState({message:`Vessel Type Deleted Successfully--`});
    axios.delete(springUrl + '/' + id, this.state)
      .then(response => {
        console.log(response);
        if (response === 201) {
          console.log(response);
        }
        this.refreshList();
        this.setState({ message: `Vessel Type Deleted Successfully--` });
        this.outMessage();
      })
  }
  selectedName = (value) => {
    this.setState({ selectedValue: value })
  }
  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }

  render() {
    const { Data, columns, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message && <div class="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
          <a href="VesselTypeList" className="close" data-dismiss="alert" aria-label="close">&times;</a>
          {this.state.message}</div>}</h6>
        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="">
              <h3 className="text-center blueText">vessel Type List</h3>
            </Col>
          </Row>
          <Col md={5}><Button onClick={this.redirection}>Add Type</Button></Col>
          <Row className=" mb-3">
            <Col className="tableWp col-md-8 mx-auto">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.props.history.push('/UpdateVesselType/' + `${rowData.id}`)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ show: true, rowData: rowData })

                          }
                        }

                      ]
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <DeleteConfirm show={this.state.show} animation={true}
          rowData={rowData} id={rowData.id} name={rowData.vesselTypeName}
          close={() => this.setState({ show: false })} deleteHandler={this.deleteType} />
      </Container>
    );
  }

}
