import React, { Component } from "react";
import Select from "react-select";

// import "./App.css";

const styles = {
  multiValue: styles => {
    return {
      ...styles, 
      backgroundColor: "papayawhip"
    };
  }
};

// const options = [
//     { value: "React", label: "React" },
//     { value: "React Native", label: "React Native" },
//     { value: "Vue", label: "Vue" },
//     { value: "Angular", label: "Angular" },
//     { value: "RxJS", label: "RxJS" },
//     { value: "jQuery", label: "jQuery" }
//   ];
  
class MultiSelection extends Component {
  constructor(props){
    super(props);
    this.state={
      options : this.props.options
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.options !== this.props.options){
      await this.setState({ options : nextProps.options });
    }
    if(nextProps.optionValue !== this.props.surClass){
      await this.setState({ optionValue : nextProps.optionValue });
    }
  }

  handleChange = (option) =>{
    debugger
      //console.log(option);
      this.setState(option)
      this.props.optionHandler(option);
  }

  3  
  render() {
    const {options} = this.state;
    const optionValue = this.props.surClass;
    console.log("props", this.props);
    console.log("options",options);
    console.log("optionValue",optionValue);
    return (
      <Select
        styles={styles}
        closeMenuOnSelect={false}
        isMulti
        options={options}
        defaultValue={optionValue}
        onChange={this.handleChange}
      />
    );
  }
}

export default MultiSelection;