import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import {Modal } from 'react-bootstrap';

export default  class deleteConfirm extends React.Component{
    constructor(props) {
      super(props); 
      this.state = {
          rowData: this.props.rowData,
          show: false,
          name: this.props.name,
          id: this.props.id
      }
    }
    close = async(show) =>{
        this.props.close(show);
    }
    deleteHandler = (e) =>{
        this.props.deleteHandler(this.props.id);
        this.close();
    }
    onHide = () =>{
        this.setState({show: false});
    }
    render(){
        const{show, close, rowdata, name, id}=this.state;
        return(
            <Modal
                show={this.props.show}
                onHide={this.onHide}
                animation={true} className="shadow border">
                <Modal.Header closeButton  onClick={this.close}>
                    <Modal.Title>Are You Sure ?</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <Row>
                <Col>
                    <Label className="DetailText">You want to delete {this.props.name} </Label>
                </Col>
                </Row>
                </Modal.Body>
                <Modal.Footer className="py-1 d-flex justify-content-center mt-2 mb-2">
                <Button variant="primary"  onClick={this.deleteHandler}>
                    Yes
                </Button>
                <Button  color="danger" onClick={this.close}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>
        )};
}