import React from "react";
import $ from 'jquery';
import { Card, Label, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import { Modal } from 'react-bootstrap'
import axios from '../../service/axiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteConf.js';


const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

//surveyor master API
const springUrl = 'surveyor';
//Vessel Class master API
const springUrl2 = 'VesselClass';
var dataarray = [];
export default class VesselSurveyorsListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      columns: [
        { title: "Id", field: "id", hidden: true },
        {
          title: 'Sr No', field: 'tableData.id',
          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        },
        { title: "Surveyor Name", field: "surveyorName" },
        { title: "Surveyor Email", field: "surveyorEmail" },
        // { title: "Created By", field: "crID", type: "Date" },
        // { title: "UpdatedBy", field: "upID", type: "Date" },
      ],
      Data: [],
      showMod: false,
      show: false,
      close: false,
      message: "",
      rowData: [],
      surClass: [],
      Class: []
    }
  }



  deleteSurveyor = (id) => {
    axios.delete(springUrl + '/' + id)
      .then(response => {
        console.log(response);
        this.refreshList();
        this.setState({ message: `Surveyor Deleted Successfully--` });
      })
      .catch(error => {
        console.log(error);
      })
    this.outMessage();
  }

  outMessage = () => {

    $(".alert").show();

    setTimeout(function () {

      $(".alert").hide();

    }, 2000);

  }

  refreshList() {
    axios.get(springUrl)
      .then(response => {
        let surveyors = response.data;
        this.setState({ Data: surveyors });
        // this.setState({surclassName: response.data});
        // this.setState({surClass: surveyors.surClass});

        // var dataarray = [];
        // surClass.map(data=>{
        //     dataarray.push({
        //         label: data.id,
        //     })
        // })
        // this.setState({surclassName:dataarray});
      })
    axios.get(springUrl2)
      .then(response => {
        let Class = response.data;
        Class.map(data => {
          dataarray.push({
            value: data.id,
            label: data.className,
          })
        })
        this.setState({ Class: dataarray });
        console.log(this.state.Class);
      })
    // this.setState({surClass: rowData.surClass});
  }

  viewModal = (event, rowData) => {
    this.setState({ show: true, rowData: rowData });

    var seloption = [];
    rowData.surClass.map(data => {
      seloption.push({
        value: data.id

      })
    })
    // this.setState({surClass: seloption});
    let result1 = dataarray;
    console.log(result1, "result1");
    let result2 = seloption;
    let result = result1.filter(o1 => result2.some(o2 => o1.value === o2.value));
    var label = [];
    result.map(data => {
      label.push(
        data.label ,
      )
    })
    this.setState({ surClass: label });
    console.log("surClass", this.state.surClass);
  }

  componentDidMount() {
    this.refreshList();
  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/SurveyorMaster');
  }

  render() {

    const { Data, columns, rowData, showMod } = this.state;

    console.log("rowData", this.state.rowData);
    console.log("surClass", this.state.surClass);
    return (

      <Container fluid className="CouseMasterWp FormWp">

        <h6>{this.state.message && (
          <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
            <a
              href="VesselSurveyorsListTable" className="close" data-dismiss="alert" aria-label="close"> &times;
            </a>
            {this.state.message}
          </div>)}
        </h6>

        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Surveyor List</h3>
            </Col>
          </Row>
          <Col md={5}><Button onClick={this.redirection}>Add Surveyor</Button></Col>
          <Row className=" mb-3">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.props.history.push(`/SurveyorMaster/${rowData.id}`)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ showMod: true, rowData: rowData })
                            // this.deleteSurveyor(`${rowData.id}`)
                          }
                        },
                        {
                          icon: 'visibility',
                          tooltip: 'View',
                          onClick: (event, rowData) => {
                            this.viewModal(event, rowData)
                          }
                        }
                      ]
                    }
                  />

                </Col>
              </Row>

            </Col>
          </Row>
        </Card>

        <DeleteConfirm show={this.state.showMod}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.surveyorName}
          close={() => this.setState({ showMod: false })}
          deleteHandler={this.deleteSurveyor} />

        <Modal
          show={this.state.show}
          animation={true}
          size="lg" className="shadow-lg border">
          <Modal.Header closeButton onClick={() => this.setState({ show: false })}>
            <Modal.Title>Surveyor Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={3}><Label className="DetailText">Name :</Label></Col>
              <Col md={9}><Label className="normalTxt">{rowData.surveyorName}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Address :</Label></Col>
              <Col md={9}><Label className="normalTxt">{rowData.surveyorAdd1}, {rowData.surveyorAdd2}, {rowData.surveyorAdd3}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">City :</Label></Col>
              <Col md={3}> <Label className="normalTxt">{rowData.surveyorCity}</Label></Col>
              <Col md={3}><Label className="DetailText">State :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorState}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Country :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorCountry}</Label></Col>
              <Col md={3}><Label className="DetailText">Email :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorEmail}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Office Phone 1 :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorOffPh1}</Label></Col>
              <Col md={3}><Label className="DetailText">Office Phone 2 :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorOffPh2}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Contact Person's :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorContactPerson}</Label></Col>
              <Col md={3}><Label className="DetailText">Official Website :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorWebsite}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Contact Person's No :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorCpContactNo}</Label></Col>
              <Col md={3}><Label className="DetailText">Contact Person's Email :</Label></Col>
              <Col md={3}><Label className="normalTxt">{rowData.surveyorCpEmail}</Label></Col>
            </Row>
            <Row>
              <Col md={3}><Label className="DetailText">Class :</Label></Col>
              <Col md={3}><Label className="normalTxt">{this.state.surClass} </Label></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="py-1 d-flex justify-content-center mt-2 mb-2">
            <Button color="danger" onClick={() => this.setState({ show: false })}>
              Cancel
            </Button>
            {/* <Button variant="primary"  onClick={() => this.setState({ show: false })}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
