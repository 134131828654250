
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import MultiSelectionDropDown from '../Common/MultiSelectionDropDown'
const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default  class AddClassSurveyors extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      
        // columns: [  
        //     // { title: "Id", field: "id" , hidden: true },
        //     { title: "Class Id", field: "Id"},
        //     { title: "Surveyor Id", field: "SurveyorsId"},
        //     { title: "Creation Id", field: "CreationId"},
        //      { title: "Creation Date", field: "CreationDate"},
        //      { title: "Updation Id", field: "UpdationId"},
        //     { title: "Update Date", field: "UpdatedBy"}
        //   ],
        //   Data : [
        //     {Id: "01", SurveyorsId: "0230",CreationId: "01",CreationDate: "11/01/2020",UpdationId:"001",UpdatedBy:"Admin4 11/01/2020"},
        //     {Id: "02", SurveyorsId: "0231",CreationId: "02",CreationDate: "11/01/2020",UpdationId:"001",UpdatedBy:"Admin4 11/01/2020"},
        //     {Id: "03", SurveyorsId: "0232",CreationId: "03",CreationDate: "11/01/2020",UpdationId:"001",UpdatedBy:"Admin4 11/01/2020"},
        //     {Id: "04", SurveyorsId: "0233",CreationId: "04",CreationDate: "11/01/2020",UpdationId:"001",UpdatedBy:"Admin4 11/01/2020"},
        //     {Id: "04", SurveyorsId: "0233",CreationId: "04",CreationDate: "11/01/2020",UpdationId:"001",UpdatedBy:"Admin4 11/01/2020"}
        

        //   ]
       }
  }
 
  render(){
    const{Data,columns,options} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
        {/* <Card className="shadow p-4 col-md-12"> */}
       <Card className="shadow p-4 col-md-6 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Add New Class Surveyors</h3>
         </Col>
       </Row>
       <Form>
        <Row>
           <Col md={12}>
            <FormGroup>
              <Label>Add New Class</Label>
             <Input type="text" name="AddNewClass" placeholder="Add New Class"/>
            </FormGroup> 
           </Col>
        </Row>
        <Row>
          <Col className="text-center">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
       {/* <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'visibility',
                        tooltip: 'View',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                 
                   
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row> */}
       {/* </Card> */}
    </Container>
    );
}

}