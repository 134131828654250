import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import $ from 'jquery';
import DropDown from '../Common/DropDown'
import MultiSelectionDropDown from '../Common/MultiSelectionDropDown'
import MultiSelection from '../Common/MultiSelection'
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from '../../service/axiosConfig.js';
import Flashmessage from 'react-flash-message';
import Validation from '../Common/Validation/regExp.js';
import ValidSurveyor from '../Common/Validation/DoubleEntry.js';
import data from "../../Containers/Common/Data.json";

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}

//surveyor master API
const springUrl = 'surveyor';
//Vessel Class master API
const springUrl2 = 'VesselClass';
//classwise Surveyor API
const springUrl3 = 'class';

export default class SurveyorMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectCountry: [],
      SelectState: [],
      SelectCity: [],
      columns: [
        { title: "Class Name", field: "vesselClassName" },
      ],
      Data: [],
      id: this.props.match.params.id,
      surveyorName: "",
      surveyorAdd1: "",
      surveyorAdd2: "",
      surveyorAdd3: "",
      surveyorCity: "",
      surveyorState: "",
      surveyorCountry: "",
      surveyorOffPh1: "",
      surveyorOffPh2: "",
      surveyorWebsite: "",
      surveyorEmail: "",
      surveyorContactPerson: "",
      surveyorCpContactNo: "",
      surveyorCpEmail: "",
      crID: "",
      crDate: "",
      surClass: [],
      option: [],
      message: "",
      selectedOption: [],
      errors: {
        surveyorName: "",
        surveyorAdd1: "",
        surveyorAdd2: "",
        surveyorAdd3: "",
        surveyorCity: "",
        surveyorState: "",
        surveyorCountry: "",
        surveyorOffPh1: "",
        surveyorOffPh2: "",
        surveyorWebsite: "",
        surveyorEmail: "",
        surveyorContactPerson: "",
        surveyorCpContactNo: "",
        surveyorCpEmail: ""
      },
      title: "",
      error: {},
    }
  }
  selectedCity = (value) => {
    this.setState({ surveyorCity: value });
  }
  selectedState = (value) => {
    this.setState({ surveyorState: value });
    let stats = this.state.SelectCountry.find(
      (cntry) => cntry.name === this.state.surveyorCountry
    ).states;
    this.setState({
      SelectCity: stats.find((stats) => stats.name === value)
        .cities,
    });

  }
  selectedCountry = (value) => {
    this.setState({ surveyorCountry: value });
    this.setState({
      SelectState: this.state.SelectCountry.find(
        (cntry) => cntry.name === value
      ).states,
      // SelectPhone: this.state.SelectCountry.find(
      //   (cntry) => cntry.name === value
      // ).phone_code,
    });

  }
  // selectedClass = (value) =>{
  //   this.setState({surClass:value});
  // }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/VesselSurveyorsListTable');
  }

  //function for alert suto close

  outMessage = () => {

    $(".alert").show();

    setTimeout(function () {

      $(".alert").hide();

    }, 2000);

  }
  optionHandler = (selectedValue) => {
    console.log("selectedValue", selectedValue);
    var dataset = [];
    selectedValue.map(data => {
      dataset.push({
        value: data.value,
        label: data.label
      })
    })
    this.setState({ selectedOption: dataset });
    console.log("selectedOption", this.state.selectedOption);

    var setSurClass = [];
    selectedValue.map(data => {
      setSurClass.push({ id: data.value })
    })
    this.setState({ surClass: setSurClass });
    console.log("class", this.state.surClass);
    debugger
  }

  validateForm() {

    let error = {};
    let formIsValid = true;

    if (this.state.surveyorCountry == "") {
      formIsValid = false;
      error["surveyorCountry"] = "*Please choose Country";
    }
    if (this.state.surveyorState == "") {
      formIsValid = false;
      error["surveyorState"] = "*Please choose State";
    }
    if (this.state.surveyorCity == "") {
      formIsValid = false;
      error["surveyorCity"] = "*Please choose City";
    }



    this.setState({
      error: error
    });
    return formIsValid;

  }


  changeHandler = (e) => {
    // let{name, value} = e.target;
    // // value = value.replace(/[^A-z\d\s]/ig, '');
    // this.setState({[name]:value});


    const { name, value } = e.target;
    let errors = this.state.errors;
    // <Validation errors={errors} name={e.target.name} value={e.target.value} />

    const validFullName = RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/);
    const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validPhoNum = RegExp(/^^[6-9]\d{9}$/);
    const validMobNum = RegExp();
    const validWebSite = RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/)
    switch (name) {
      case 'surveyorName':
        errors.surveyorName =
          validFullName.test(value)
            ? ''
            : 'Name is Not Valid';
        break;
      case 'surveyorOffPh1':
        errors.surveyorOffPh1 =
          validPhoNum.test(value)
            ? ''
            : 'Phone Number is not valid';
        break;
      case 'surveyorOffPh2':
        errors.surveyorOffPh2 =
          validPhoNum.test(value)
            ? ''
            : 'Phone Number is not valid';
        break;
      case 'surveyorWebsite':
        errors.surveyorWebsite =
          validWebSite.test(value)
            ? ''
            : 'Website is not valid';
        break;
      case 'surveyorEmail':
        errors.surveyorEmail =
          validEmail.test(value)
            ? ''
            : 'Email is not valid';
        break;
      case 'surveyorContactPerson':
        errors.surveyorContactPerson =
          validFullName.test(value)
            ? ''
            : 'Name is not valid';
        break;
      case 'surveyorCpContactNo':
        errors.surveyorCpContactNo =
          validPhoNum.test(value)
            ? ''
            : 'Phone Number is not valid!';
        break;
      case 'surveyorCpEmail':
        errors.surveyorCpEmail =
          validEmail.test(value)
            ? ''
            : 'Email is not valid!';
        break;
      default:
        break;
    }


    this.preventSameData(e);

    this.setState({ errors, [name]: value }, () => {
      console.log(errors)
    })

  }

  // setSurveyorClass=()=>{
  //   var dataset = [];
  //   this.state.selectedOption.map(data=>{
  //     dataset.push({id: data.value})
  //   })
  //   this.setState({surClass:dataset});
  //   console.log("class",this.state.surClass)
  // }

  submitHandle = (e) => {
    e.preventDefault();

    console.log(this.state);
    const validateForm = (errors) => {
      let valid = true;
      Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
      );
      return valid;
    }
    if (validateForm(this.state.errors) && this.validateForm()) {

      if (this.state.id == null) {
        axios.post(springUrl, this.state)
          .then(response => {
            console.log(response);
            this.setState({ message: `Surveyor ${this.state.surveyorName} Submitted Successfully !!`, surveyorName: "", });
            this.reset();
          })
          .catch(error => {
            console.log(error);
          })
        // this.setState({message:`Surveyor ${this.state.surveyorName} Submitted Successfully !!`});
        // console.log("submit state",this.state);

      }
      else if (this.state.id != null) {
        axios.put(springUrl + "/" + this.state.id, this.state)
          .then(response => {
            console.log(response);
            this.setState({ message: `Surveyor ${this.state.surveyorName} Updated Successfully..` });
            this.reset();
          })
          .catch(error => {
            console.log(console.error);
          })
      }
      console.info('Valid Form')
    } else {
      console.error('Invalid Form')
    }
    this.outMessage();
  }

  preventSameData = (e) => {
    if (this.state.surveyorEmail === this.state.surveyorCpEmail) {
      this.state.errors.surveyorCpEmail = "email should not be same";
    }
    else {
      this.state.errors.surveyorCpEmail = "";
    }
  }
  reset
    = () => {
      this.setState({
        surveyorName: "",
        surveyorAdd1: "",
        surveyorAdd2: "",
        surveyorAdd3: "",
        surveyorCity: "",
        surveyorState: "",
        surveyorCountry: "",

        surveyorOffPh1: "",
        surveyorOffPh2: "",
        surveyorWebsite: "",
        surveyorEmail: "",
        surveyorContactPerson: "",
        surveyorCpContactNo: "",
        surveyorCpEmail: ""

      });
    };

  componentDidMount() {
    this.setState({ SelectCountry: data });
    var dataarray = [];
    axios.get(springUrl2)
      .then(response => {
        let Class = response.data;
        this.setState({ Data: Class });

        this.state.Data.map(data => {

          dataarray.push({
            value: data.id,
            label: data.className,
          })
        })
        this.setState({ option: dataarray });
        console.log("option", this.state.option);
      })
    if (this.state.id != null) {
      this.setState({ title: "Update" });
      axios.get(springUrl + '/' + this.state.id)
        .then(async (response) => {
          console.log("Surveyor", response.data);
          let surveyor = response.data;
          this.setState({
            id: surveyor.id, surveyorName: surveyor.surveyorName,
            surveyorAdd1: surveyor.surveyorAdd1,
            surveyorAdd2: surveyor.surveyorAdd2,
            surveyorAdd3: surveyor.surveyorAdd3,
            surveyorCity: surveyor.surveyorCity,
            surveyorState: surveyor.surveyorState,
            surveyorCountry: surveyor.surveyorCountry,
            surveyorOffPh1: surveyor.surveyorOffPh1,
            surveyorOffPh2: surveyor.surveyorOffPh2,
            surveyorWebsite: surveyor.surveyorWebsite,
            surveyorEmail: surveyor.surveyorEmail,
            surveyorContactPerson: surveyor.surveyorContactPerson,
            surveyorCpContactNo: surveyor.surveyorCpContactNo,
            surveyorCpEmail: surveyor.surveyorCpEmail,
            crID: surveyor.crID,
            crDate: surveyor.crDate,
            surClass: surveyor.surClass
          })
          var seloption = [];
          this.state.surClass.map(data => {
            seloption.push({
              value: data.id

            })
          })
          // this.setState({surClass: seloption});
          debugger
          let result1 = dataarray;
          let result2 = seloption;
          let result = result1.filter(o1 => result2.some(o2 => o1.value === o2.value));
          await this.setState({ selectedOption: result });
          console.log("seloption", this.state.selectedOption);
          console.log(result);


          this.selectedCountry(this.state.surveyorCountry);
          this.selectedState(this.state.surveyorState);
        })
    }
    else {
      this.setState({ title: "Add" });
    }
  }

  render() {
    const { SelectCountry, SelectState, SelectCity, Data, columns, option, errors, title } = this.state;
    const { id, surveyorName, surveyorAdd1, surveyorAdd2, surveyorAdd3,
      surveyorCity, surveyorState, surveyorCountry, surveyorOffPh1, surveyorOffPh2,
      surveyorWebsite, surveyorEmail, surveyorContactPerson, surveyorCpContactNo,
      surveyorCpEmail, crID, crDate, surClass, selectedOption } = this.state;
    return (

      <Container fluid className="CouseMasterWp FormWp">
        <validation></validation>
        <h6>{this.state.message && <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto" role="alert">
          <a href="/SurveyorMaster" className="close" data-dismiss="alert" aria-label="close">&times;</a>{this.state.message}</div>}</h6>
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} surveyor Details</h3>
            </Col>
            <Col md={1}><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.submitHandle}>
            <Row>
              <Input type="hidden" name={id} value={id} />
              <Input type="hidden" name={crID} value={crID} />
              <Input type="hidden" name={crDate} value={crDate} />

              <Col md={6}>
                <FormGroup>
                  <Label className="required">surveyor Name</Label>
                  <Input type="text" name="surveyorName" placeholder="Full Name of Surveyor" required value={surveyorName} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorName}</span>
                  <ValidSurveyor url="/surveyor/name" name={surveyorName} id={id}></ValidSurveyor>
                  {/* <Validation name="surveyorName" value={surveyorName} errors={errors}>{errors.surveyorName}</Validation> */}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Address Line 1</Label>
                  <Input type="textarea" name="surveyorAdd1" placeholder="House No / Flat No / Floor" required value={surveyorAdd1} onChange={this.changeHandler} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Line 2</Label>
                  <Input type="textarea" name="surveyorAdd2" placeholder="Society / Street Name" value={surveyorAdd2} onChange={this.changeHandler} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Line 3</Label>
                  <Input type="textarea" name="surveyorAdd3" placeholder="Optional" value={surveyorAdd3} onChange={this.changeHandler} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Country</Label>
                  <DropDown title="Select Country" name="surveyorCountry" options={SelectCountry} required selectedVal={this.selectedCountry}  value={surveyorCountry} />
                  <div className="errorMsg">{this.state.error.surveyorCountry}</div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="required">State</Label>
                  <DropDown title="Select State" name="surveyorState" options={SelectState} required selectedVal={this.selectedState}  value={surveyorState} />
                  <div className="errorMsg">{this.state.error.surveyorState}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">City</Label>
                  <DropDown title="Select City" name="surveyorCity" options={SelectCity} required selectedVal={this.selectedCity}  value={surveyorCity} />
                  <div className="errorMsg">{this.state.error.surveyorCity}</div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Office Phone No 1</Label>
                  <Input type="text" name="surveyorOffPh1" pattern="^[6-9]\d{9}$" placeholder="Office Phone No 1" required value={surveyorOffPh1} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorOffPh1}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>

              <Col md={6}>
                <FormGroup>
                  <Label>Office Phone No 2</Label>
                  <Input type="text" name="surveyorOffPh2" pattern="^[6-9]\d{9}$" placeholder="Office Phone No 2" value={surveyorOffPh2} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorOffPh2}</span>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Official Website</Label>
                  <Input type="text" name="surveyorWebsite" placeholder="OfficialWebsite" value={surveyorWebsite} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorWebsite}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Email</Label>
                  <Input type="text" name="surveyorEmail" placeholder="Email" required value={surveyorEmail} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorEmail}</span>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person's</Label>
                  <Input type="text" name="surveyorContactPerson" placeholder="contact Person" required value={surveyorContactPerson} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorContactPerson}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person's No</Label>
                  <Input type="text" name="surveyorCpContactNo" pattern="^[6-9]\d{9}$" required placeholder="Contact No" value={surveyorCpContactNo} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorCpContactNo}</span>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person's Email</Label>
                  <Input type="text" name="surveyorCpEmail" placeholder="Contact Persons Email" required value={surveyorCpEmail} onChange={this.changeHandler} />
                  <span className='text-danger'>{errors.surveyorCpEmail}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Select Class </Label>
                  <MultiSelectionDropDown name="surClass" required surClass={surClass} seloption={selectedOption}
                    //  selectedVal={this.selectedClass} onChange={this.setSurveyorClass}
                    options={option} optionHandler={this.optionHandler} />
                </FormGroup>
              </Col>
              {/* <Col md={3}><Label></Label><br/><Button className="mt-2">Add Items</Button></Col> */}
            </Row>
            {/* <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="required">Select Class </Label>
              <MultiSelection name="surClass" required value={surClass} 
              selectedVal={this.selectedClass} options={option} optionHandler={this.optionHandler} />
            </FormGroup>
          </Col>
        </Row> */}
            {/* <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      },
                    ]
                  }
                   />
                 </Col>
             </Row>            
         </Col>
       </Row> */}
            <Row>
              <Col className="text-center">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>
                  <Button className="text-center " color="danger" type="reset" onClick={this.reset}>Reset</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>


      </Container>
    );
  }
}