import react from 'react'

import React from 'react';
import {Card, CardImg, CardText,CardTitle,Row,Col} from 'reactstrap';
import {Button} from 'reactstrap'
import Ship from '../../../assets/ship2.jpg'
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
const ServicesCard = (props,{servicesTitle,servicesText}) => {
  return (
    <div>
        <Card className="cardWp mediaCard mb-5" >
          <Row>
            <Col>
              <CardImg top   src={Ship} alt="Card image cap" className="service-item-bg"/>
              <CardTitle className="pl-3  pt-2 pr-3 servicesTitle">{props.servicesTitle}</CardTitle>
                <CardText className="pl-3 pr-3 cardTextelipse text-left">{props.servicesText}</CardText>
                <CardText className="text-left pl-3 pr-3 "><a href="" title="" class="readmore-btn">Read more <NavigateNextIcon/></a></CardText> 
                <div className="location_map"></div>
                <AddIcon className="AddIcn"/>
            </Col>
          </Row>
      </Card>
      </div>
    );
};

export default ServicesCard;
