import react from 'react'
import {Card, CardImg, CardText,CardTitle,Row,Col} from 'reactstrap';
import Ship from '../../../assets/ship2.jpg'
const ServiceProviders =(props,{image,text}) => {
    return(

        <div>
           <Card className="serviceProviderCard">
              <CardImg top   src={props.image} alt="Card image cap" className=" service-item-bg ProviderImg"/>
              <div className="BtmContent">
                  <h3>{props.text}</h3>
              </div>
            </Card>
        </div>
    );
}
export default ServiceProviders;