
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AxiosConfig } from "../Common/AxiosConfig";
import 'react-notifications-component/dist/theme.css'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }

export default  class InspectionTypeMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspection_Type_id: this.props.match.params.id,
      cr_id: "",
      cr_date: "",
      inspectionTypeName: "",
      message: "",
      title: "",
    };
  }

  componentDidMount() {
    this.getName();
    this.props.history.push("/InspectionTypeMaster");
    if (this.state.inspection_Type_id == null) {
      this.setState({ title: "Add" });
    } else {
      this.setState({ title: "Update" });
    }
  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push("/InspectionList");
  };
  //Get single data for update
  getName() {
    if (this.state.inspection_Type_id != null) {
      AxiosConfig.get("InspectionTypeMaster/" + this.state.inspection_Type_id)
        .then((response) => {
          debugger;
          let inspection = response.data;
          console.log(inspection);
          this.setState({
            inspection_Type_id: inspection.inspection_Type_id,
            inspectionTypeName: inspection.inspectionTypeName,
            cr_id: inspection.cr_id,
            cr_date: inspection.cr_date,
          });
          console.log(this.state.inspection_Type_id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // //alerts
  // updateAlert() {
  //   NotificationManager.success("", "Updated");
  // }
  // addAlert() {
  //   NotificationManager.success("", "Added");
  // }

  addOrUpdate = (e) => {
    e.preventDefault();
    console.log(this.state);
    AxiosConfig.get("InspectionTypeMasterName/" + this.state.inspectionTypeName)
    .then((response) => {
      let inspectN = response.data.inspectionTypeName;
      let err = e.target.error;
      if(inspectN == undefined) {
        //update
        if (this.state.inspection_Type_id != null) {
          this.setState({
            message: `${this.state.inspectionTypeName} Updated Successfully!!`
          });
          this.update(this.state.inspection_Type_id);
        }
        //add
        else if (this.state.inspection_Type_id == null) {
          this.setState({
            message: `${this.state.inspectionTypeName} Added Successfully!!`
          });
          this.add();
          // this.addAlert();
        }
      } else {
        err = <strong>Inspection Type already exists</strong>;
        this.setState({ errormessage: err });
      }
      this.setState({inspectionTypeName:""})
    });
    // window.location.href='/InspectionTypeMaster';
    // this.setState({message: mssg});
  };

  update(inspection_Type_id) {
    AxiosConfig.put(
      "InspectionTypeMaster/" + this.state.inspection_Type_id,
      this.state
    )
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  add() {
    AxiosConfig.post("InspectionTypeMaster", this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  refresh() {
    setTimeout(function () {
      window.location.reload(false);
    }, 3000);
  }

  changeState = (e) => {
    let nam = e.target.name;
    let val = e.target.value.replace(/[^A-Za-z0-9_]\s/gi, "");
    let err = e.target.error;
    if ((nam = "inspectionTypeName")) {
      if (Number(val)) {
        err = <strong>Digits not allowed</strong>;
        document.getElementById("button").disabled = !0;
      } else if (e.target.value.length < 4) {
        err = <strong>Inspection Type name too Short!</strong>;
        document.getElementById("button").disabled = !0;
      }else if (e.target.value.length > 30) {
        err = <strong>Inspection Type name too Short!</strong>;
        document.getElementById("button").disabled = !0;
      }  else {
        document.getElementById("button").disabled = !1;
      }
    }
    this.setState({ errormessage: err });
    this.setState({ [nam]: val });
  };

  // selectedValue = (value) =>{
  //   this.setState({selectedValue:value})
  // }
  render() {
    const {
      inspection_Type_id,
      inspectionTypeName,
      cr_id,
      cr_date,
      title,
    } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        {/* Add message */}
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/InspectionTypeMaster"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>

        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">
                {title} Inspection Type Master
              </h3>
            </Col>
            <Col md={1} className="pl-0">
              <Button className="pt-0 pb-0" onClick={this.redirection}>
                <VisibilityIcon />
              </Button>
            </Col>
          </Row>
          <Form onSubmit={this.addOrUpdate}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Inspection Type Name</Label>
                  <Input
                    type="hidden"
                    name="inspection_Type_id"
                    required
                    value={inspection_Type_id}
                  />
                  <Input type="hidden" name="cr_id" required value={cr_id} />
                  <Input
                    type="hidden"
                    name="cr_date"
                    required
                    value={cr_date}
                  />
                  <Input
                    type="text"
                    name="inspectionTypeName"
                    onChange={this.changeState}
                    required
                    value={inspectionTypeName}
                    placeholder="Enter Inspection Type Name"
                  />
                  <div style={{ color: "red" }}>{this.state.errormessage}</div>
                  <div style={{ color: "green" }}>
                    <NotificationContainer />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button
                    className="text-center mr-3  btnblue"
                    type="submit"
                    id="button"
                  >
                    Submit
                  </Button>
                  <Button className="text-center " color="danger">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}