import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "./Components/Sidebar/SideBar";
import Content from "./Components/Content/Content";
import "./App.css";
import Login from "./Containers/Login/Login";
import AuthService from "./Containers/Login/Auth.Service";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // sidebarIsOpen:true,
      // setSidebarOpen:true,
      // isSignupPage:true,
      // isLoginPage: false
      currentUser: {}
    }
  }

  // componentDidMount() {
  //   if (window.location.pathname === '/Home' || window.location.pathname === '/Login'
  //     || window.location.pathname === '/Signup') {
  //     this.setState({ isLoginPage: true })
  //   } else {
  //     this.setState({ isLoginPage: false })
  //   }

  // }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    console.log(currentUser);
    this.setState({ currentUser: currentUser })
    this.state.currentUser = currentUser;
  }
  render() {
    const { isLoginPage } = this.state;
    return (
      <div>
        <Router>
          <div className="App">

            {
              !this.state.currentUser ? <Login /> : <SideBar toggle={this.toggleSidebar} isOpen={this.props.sidebarIsOpen} isLoginPage={this.state.isLoginPage} />
            }
            <Content
              isLoginPage={this.state.isLoginPage}
              // isSignupPage ={ this.state.isSignupPage}
              toggleSidebar={this.toggleSidebar}
              sidebarIsOpen={this.props.sidebarIsOpen} />
          </div>
        </Router>
      </div>
    );
  }
};

export default App;