import axios from "axios";

export const ImageUrl = "https://media.shiplords.com/ImageService/"
export const ThumbnailImageUrl = "https://media.shiplords.com/ImageService/ImageService/"
// export const ImageUrl = "http://localhost:8089/ImageService/";
// export const ThumbnailImageUrl =
  // "http://localhost:8089/ImageService/ImageService/";

export const AxiosConfig = axios.create({
  baseURL: "https://api.shiplords.com/ShipForSaleService",
  // baseURL: 'http://localhost:8087/ShipForSaleService'
});
//  http://localhost:8087/ShipForSaleService/flagmaster
// baseURL: 'http://193.34.144.157:7000/ShipForSaleService'
// baseURL: 'https://api.shiplords.com/ShipForSaleService'

export const ImageService = axios.create({
  // baseURL: 'http://193.34.144.157:7000/ImageService'
  // baseURL: 'https://38.242.236.130:8089/ImageService'
  baseURL: "https://media.shiplords.com/ImageService",
  // baseURL: 'https://api.shiplords.com/ImageService' //38
  // baseURL: 'http://localhost:8089/ImageService'
});
