import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../../src/Containers/Login/Auth.Service";
import { withRouter } from "react-router-dom";


import InputIcon from '@material-ui/icons/Input';
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import '../Sidebar/Sidebar.css'
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const Topbar = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  return (
    <Navbar
      color="light"
      light
      className="navbar shadow-sm mb-3 bg-white rounded TopbarWp "
      expand="md"
    >
      {/* <Button color="info" onClick={toggleSidebar}>
          <FontAwesomeIcon  /> 
      </Button> */}
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            {/* <NavLink tag={Link} to={"/page-4"}>
            <Badge badgeContent={4} color="secondary">
                <NotificationsIcon/>
            </Badge>
            </NavLink> */}
          </NavItem>
          <NavItem>
            <NavLink  >
              <Button className=" btnblue LogoutWp btntgle" onClick={() => { AuthService.logout(); window.location.href = "/Login" }}><InputIcon /></Button>
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;  