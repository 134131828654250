
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import FileUploader from '../Common/FileUploader'
import Switch from "react-switch";
import CheckIcon from '@material-ui/icons/Check';
const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default  class AddVesselInspectionDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
          checked: false ,
        }
        this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  
  render(){
    const{Data,columns} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-8 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Inspection Details</h3>
         </Col>
       </Row>
       <Form>
        {/* <Row>
        <Col md={6}>
           <FormGroup>
                <Label>Vessel Id</Label>
                  <Input type="Number" name="VesselId"  placeholder="Vessel Id" />
                </FormGroup>
           </Col>
           <Col md={6}>
            <FormGroup>
              <Label>Inspection Type Id</Label>
                <Input type="Number" name="Inspection Type Id"  placeholder="Inspection Type Id" />
            </FormGroup>
           </Col>
           
        </Row>
        <Row>
            <Col md={6}>  
                <FormGroup>
                    <Label>Class Id</Label>
                    <Input type="Number" name="ClassId" placeholder="Class Id"/>
                </FormGroup>
                </Col>
            <Col md={6}> 
                <FormGroup>
                    <Label>Surveyors ID</Label>
                    <Input type="Number" name="SurveyorsId" placeholder="Surveyors Id"/>
                </FormGroup>
            </Col>
        </Row> */}
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label>Date Of Inspection</Label>
                    <Input type="Date" name="Inspection Date" placeholder="Inspection Date"/>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Inspection Remarks</Label>
                    <Input type="text" name="Inspection Remark" placeholder="Inspection Remark"/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
            <FormGroup>
                    <Label>Certified</Label><br/>
                    <Switch onChange={this.handleChange} checked={this.state.checked}  />
                </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup>
                    <Label>Certificate Attachment</Label>
                   <FileUploader/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col className="text-center mt-5">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
    </Container>
    );
}

}