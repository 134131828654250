
import React from "react";
import axios from "axios";
import $ from 'jquery';
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import { Modal } from 'react-bootstrap'
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import { AxiosConfig } from '../Common/AxiosConfig.js';


const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
const validateUrl = 'EquipmentMaster/name';
export default class VesselEquipmentMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      columns: [
        // { title: "Id", field: "id" , hidden: true },
        { title: "Vessel Equipment Id", field: "Id", hidden: true },
        { title: "Equipment Name", field: "vesselEqpname" },
        { title: " Value Type", field: "valueType" },

      ],
      Data: [
        { Id: "01", EquipmentName: "ABCD", ValueType: "ABC" },
        { Id: "01", EquipmentName: "ABCD", ValueType: "ABC" },
        { Id: "01", EquipmentName: "ABCD", ValueType: "ABC" },
        { Id: "01", EquipmentName: "ABCD", ValueType: "ABC" }


      ],
      checked: false,
      vesselEqpname: "",
      valueType: "",
      message: "",
      message1: "",
      vesselEquipments: [],
      id: this.props.match.params.id,
      rowData: [],
      showMod: false,
      errors: {},
      cr_id: "",
      cr_date: "",

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
  }
  validateForm() {

    let errors = {};
    let formIsValid = true;

    if (this.state.vesselEqpname == "") {
      formIsValid = false;
      errors["vesselEqpname"] = "*Please enter equipment name";
    }
    if (this.state.valueType == "") {
      formIsValid = false;
      errors["valueType"] = "*Please enter equipment value";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }
  selectedValue = (event) => {
    // this.setState({ vesselEqpname: event.target.value });
    // this.setState({ valueTypeg: event.target.value });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
    // this.setState({ vesselEqpname: event.target.value });
    // this.setState({ valueType: event.target.value });
  }
  handleChangeValue(event) {
    this.setState({ valueType: event.target.value });
    // this.setState({ valueType: event.target.value });
  }

  //function for alert suto close

  outMessage = () => {

    $(".alert").show();

    setTimeout(function () {

      $(".alert").hide();

    }, 2000);
    this.setState({ message: "" })
    this.setState({ message1: "" })

  }

  saveRecord = (e) => {

    e.preventDefault();
    let equipments = { vesselEqpname: this.state.vesselEqpname, valueType: this.state.valueType };
    console.log('employee => ' + JSON.stringify(equipments));

    // step 5
    if (this.validateForm()) {
      AxiosConfig.post("EquipmentMaster_add", equipments)

        .then(res => {
          this.getVesselEquipment();
          this.reset();

          this.setState({
            message: ` Vessel Equipment added Successfully!!`,
          });


        })
    }
   
    this.outMessage();


   
  }

  getVesselEquipment() {
    AxiosConfig.get("EquipmentMaster_view")
      .then((res) => {
        this.setState({ vesselEquipments: res.data });

      });
  }

  onReset = (e) => {

    this.setState({
      vesselEqpname: "",
      valueType: "",

    })

  }

  componentDidMount() {

    this.getVesselEquipment();
  }
  selectOne(id) {
    AxiosConfig.get("EquipmentMaster_findone/" + id).
      then(response => {
        this.getData(id);
      })
  }

  getData(id) {
    if (id != null) {
      AxiosConfig.get("EquipmentMaster_findone/" + id)
        .then((response) => {
          let vesselOwner = response.data;

          this.setState({
            id: vesselOwner.id,
            vesselEqpname: vesselOwner.vesselEqpname,
            valueType: vesselOwner.valueType,
            cr_id: vesselOwner.cr_id,
            cr_date: vesselOwner.cr_date,
          });

        })
        .catch((error) => {
        
          console.log(error);
        });
    }
  }

  reset
    = () => {
      this.setState({
        vesselEqpname: "",
        valueType: ""


      });
    };


  updateRecord = (e) => {
 
    console.log("update");
    e.preventDefault();
    e.target.reset();
    var Equipment = {
      id: this.state.id,
      vesselEqpname: this.state.vesselEqpname,
      valueType: this.state.valueType,
      cr_id: this.state.cr_id,
      cr_date: this.state.cr_date,

    }
    AxiosConfig.put("EquipmentMaster_up/" + this.state.id, Equipment)
      .then((result) => {
        if (result === 201) {
          console.log(result);
        }
        this.getVesselEquipment();
        this.setState({
          message: ` Vessel equipment updated Successfully!!`, id: ""
        });

      })
      .catch((error) => {
        console.log(error);
      });
    //to reset
    this.setState({ vesselEqpname: "" })
    this.setState({ valueType: "" })

  
    this.outMessage();



  }

  deleteDesign = (id) => {

    AxiosConfig.delete(`EquipmentMaster_delete/` + id, this.state)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.getVesselEquipment();
        this.setState({ message1: "Vessel Equipment Deleted Successfully" });
      })

    this.outMessage();
  }

  viewModal = (event, rowData) => {
    this.setState({ show: true, rowData: rowData });
    
   



  }




  render() {
    const { cr_id, cr_date, id, Data, columns, VesselYearBuilt, vesselEqpname, valueType, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message1 && (<div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
          <a href="FlagMasterTable" className="close" data-dismiss="alert" aria-label="close"
          > &times; </a> {this.state.message1}  </div>)} </h6>

        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/VesselDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Card className="shadow p-4 col-md-8 mx-auto">
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">vessel Equipment Master</h3>
              </Col>
            </Row>
            <Form onSubmit={this.state.id ? this.updateRecord : this.saveRecord}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Input

                      type="hidden"

                      name="id"

                      required

                      value={id}

                    />

                    <Input type="hidden" name="cr_id" required value={cr_id} />

                    <Input

                      type="hidden"

                      name="cr_date"

                      required

                      value={cr_date}

                    />
                    <Label className="required">Vessel Equipment Name</Label>
                    <Input type="text" name="vesselEqpname" value={vesselEqpname} onChange={this.handleChange} placeholder="Vessel Equipment Name" />
                    <ValidateMaterial url={validateUrl} name={vesselEqpname} id={id}></ValidateMaterial>
                    <div className="errorMsg">{this.state.errors.vesselEqpname}</div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Value Type</Label>
                    <Input type="text" name="valueType" value={valueType} onChange={this.handleChangeValue} placeholder="Value Type" />
                    <div className="errorMsg">{this.state.errors.valueType}</div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-2">
                  <div className="center-block text-center">
                    <Button className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>
                    <Button type="reset" className="text-center " color="danger"
                      onClick={this.onReset}>Cancel</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>

          <Row className="mt-5 mb-3">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table data={this.state.vesselEquipments} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px'
                    actions={
                      [
                        {
                          icon: 'edit',
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            this.selectOne(rowData.id)
                          }
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            this.setState({ showMod: true, rowData: rowData })
                          }
                        },
                        {
                          icon: 'visibility',
                          tooltip: 'View',
                          onClick: (event, rowData) => {
                            this.viewModal(event, rowData)
                          }
                        }
                      ]
                    }


                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </Card>
        <DeleteConfirm show={this.state.showMod}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.vesselEqpname}
          close={() => this.setState({ showMod: false })}
          deleteHandler={this.deleteDesign} />

        <Modal
          show={this.state.show}
          animation={true}
          size="lg" className="shadow-lg border">
          <Modal.Header closeButton onClick={() => this.setState({ show: false })}>
            <Modal.Title>Veseel Equipment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={5}><Label className="DetailText">Vessel Equipment Name: </Label></Col>
              <Col md={7}><Label className="normalTxt">{rowData.vesselEqpname}</Label></Col>
            </Row>

            <Row>
              <Col md={5}><Label className="DetailText">Value Type: </Label></Col>
              <Col md={7}> <Label className="normalTxt">{rowData.valueType}</Label></Col>

            </Row>

            {/* <Row>
          <Col md={3}><Label className="DetailText">Class :</Label></Col>
            <Col  md={3}><Label className="normalTxt">{this.state.surClass} </Label></Col>
          </Row> */}
          </Modal.Body>
          <Modal.Footer className="py-1 d-flex justify-content-center mt-2 mb-2">
            <Button color="danger" onClick={() => this.setState({ show: false })}>
              Cancel
            </Button>
            {/* <Button variant="primary"  onClick={() => this.setState({ show: false })}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }

}