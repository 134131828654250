
import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import DropDown from '../Common/DropDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AxiosConfig } from '../Common/AxiosConfig.js';
import 'react-notifications-component/dist/theme.css'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import $ from 'jquery';
import axios from '../../service/axiosConfig.js';


export default class FlagMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id, 
      flagName: "",
      cr_id: "",
      cr_date: "",
      message: "",
      title: ""
    };
  }

  componentDidMount() {

    this.getData();
    this.props.history.push("/FlagMaster");
    if (this.state.id == null) {
      this.setState({ title: "Add" });
    } else {
      this.setState({ title: "Update" });
    }
  }

  //refreshes whole page
  refresh() {
    setTimeout(function () {
      window.location.reload(false);
    }, 2000);
  }

  //Get single flag data for update
  getData() {

    if (this.state.id != null) {
      axios.get("flagmaster/" + this.state.id)
        .then((response) => {
          let flag = response.data;
          this.setState({
            id: flag.id,
            flagName: flag.flagName,
            cr_id: flag.cr_id,
            cr_date: flag.cr_date,
          });
          console.log(this.state.flagName, "4444444");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  //if else if condition for adding or updating data
  addOrUpdate = (e) => {
    e.preventDefault();
    console.log(this.state);
    AxiosConfig.get("flagmasterName/" + this.state.flagName).then((response) => {
      let flagN = response.data.flagName;
      let err = e.target.error;
      if (flagN == undefined) {
        //Update
        if (this.state.id != null) {
          this.updateFlag(this.state.id);
          // this.updateAlert();
          this.setState({
            message: `Flag ${this.state.flagName} Updated Successfully!!`, flagName: ""
          });
        }
        //add
        else if (this.state.id == null) {
          this.setState({
            message: `Flag ${this.state.flagName} Added Successfully!!`
          });
          this.addFlag();
          this.setState({ flagName: "" });

          // this.addAlert();
        }
      } else {
        err = <strong>Flag already exists</strong>;
        this.setState({ errormessage: err, flagName: "" });
      }
    });

    this.outMessage();
  };


  //updat function
  updateFlag(id) {
    AxiosConfig.put("flagmaster/" + this.state.id, this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //add function
  addFlag() {
    AxiosConfig.post("flagmaster", this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 1000);
  }
  //change state function
  changeState = (e) => {

    let nam = e.target.name;
    let val = e.target.value.replace(/[^A-Za-z\d\s]/gi, "");
    let err = e.target.error;
    if ((nam = "flagName")) {
      if (Number(val)) {
        err = <strong>Digits not allowed</strong>;
        document.getElementById("button").disabled = !0;
      } else if (e.target.value.length < 4) {
        err = <strong>Flag name too Short!</strong>;
        document.getElementById("button").disabled = !0;
      } else if (e.target.value.length > 20) {
        err = <strong>Flag name too Long!</strong>;
        document.getElementById("button").disabled = !0;
      } else if (val == !e.target.value.replace(/[^A-Za-z\d\s]/gi, "")) {
        err = <strong>Enter Flag name!</strong>;
        document.getElementById("button").disabled = !0;
      } else {
        document.getElementById("button").disabled = !1;
      }
    }
    this.setState({ errormessage: err });
    this.setState({ [nam]: val });
  };
  //redirect to list
  redirection = () => {
    const { history } = this.props;
    if (history) history.push("/FlagMasterTable");
  };

  reset = () => {
    this.setState({ flagName: "" });
  };

  render() {

    const { id, flagName, cr_id, cr_date, title } = this.state;
    console.log(flagName);
    return (
      <Container fluid className="CouseMasterWp FormWp">

        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/FlagMaster"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>
        <div className="mb-4"></div>
        <Card className="shadow p-4 col-md-7 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Flag</h3>
            </Col>
            <Col md={1} className="pl-0">
              <Button className="pt-0 pb-0" onClick={this.redirection}>
                <VisibilityIcon />
              </Button>
            </Col>
          </Row>
          <Form onSubmit={this.addOrUpdate}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label> Flag Name</Label>
                  <Input
                    type="hidden"
                    name="id"
                    required
                    value={id}
                  />
                  <Input type="hidden" name="cr_id" required value={cr_id} />
                  <Input
                    type="hidden"
                    name="cr_date"
                    required
                    value={cr_date}
                  />
                  <Input
                    type="text"
                    name="flagName"
                    placeholder="Enter Flag Name"
                    value={flagName}
                    onChange={this.changeState}
                    required="Please enter  a Flag Name"
                  />
                  <div style={{ color: "red" }}>{this.state.errormessage}</div>
                  <div style={{ color: "green" }}>
                    <NotificationContainer />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-2">
                <div className="center-block text-center">
                  <Button
                    className="text-center mr-3  btnblue"
                    id="button"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    className="text-center "
                    onClick={this.reset}
                    type="reset"
                    color="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}