import axios from "axios";
import { v4 as uuid } from "uuid";

const API_URL = "https://api.shiplords.com/api/auth/";
// const API_URL = "http://localhost:8086/api/auth/";

export const authServiceURL = axios.create({
  baseURL: "https://api.shiplords.com/api/auth/",
  // baseURL: 'http://localhost:8086/api/auth/login'
});
class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "permit/signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          console.log(response.data);
          console.log(typeof JSON.stringify(response.data), "type");
          localStorage.clear();
          localStorage.setItem("admin", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("admin");
  }

  register(
    username,
    email,
    password,
    confirmPassword,
    contactNo,
    role,
    registerDate,
    customerId,
    status,
    vendorId,
    isVendor
  ) {
    return axios.post(API_URL + "permit/signup", {
      username,
      email,
      password,
      confirmPassword,
      contactNo,
      role,
      registerDate,
      customerId,
      status,
      vendorId,
      isVendor,
    });
  }
  registerupdate(
    id,
    username,
    email,
    password,
    confirmPassword,
    contactNo,
    role,
    registerDate,
    customerId,
    status,
    vendorId,
    isVendor
  ) {
    return axios.put(API_URL + "updateprofile/" + id, {
      id,
      username,
      email,
      password,
      confirmPassword,
      contactNo,
      role,
      registerDate,
      customerId,
      status,
      vendorId,
      isVendor,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("admin"));
  }
}

export default new AuthService();
