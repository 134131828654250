
import React from "react";
import { Card,Col,Row,Container } from 'reactstrap';
import Table from  '../../Containers/Common/Table'
import   '../../Containers/Common/Table.css'
const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top:0,

}
const rowStyle={
  fontSize:'12px',
  padding:'10px',
  color:'#818698',

}
export default  class VesselClassWiseDrawingList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
         
        columns: [  
            // { title: "Id", field: "id" , hidden: true }
            { title: "Sr No", field: "Id"},
            { title: "Drawing Type Name", field: "DrawingTypeId"},
           
          ],
          Data : [
            {Id: "01",DrawingTypeId:"ABC"},
            {Id: "02",DrawingTypeId:"ABCD"},
            {Id: "03",DrawingTypeId:"PQRS"},
            {Id: "04",DrawingTypeId:"SDFG"},
          ],
         
    }
  }
  
render(){
  const{Data,columns} =this.state;
    return(

    <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">
       <Card className="shadow p-4 col-md-12">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Class Drawing List</h3>
         </Col>
       </Row>
        <Row className="">
         <Col className="tableWp col-md-8 mx-auto">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/VesselClassWiseDrawingMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                 
                   
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row>
       </Card>
    </Container>
    );
}
} 
