
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import Switch from "react-switch";
import FileUploader from '../Common/FileUploader'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DropDown from '../Common/DropDown'

export default  class AddVesselDesignDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
          checked: false ,
          DesignTypeName:[
            { value: 'Design 1'},
            { value: 'Design 2' }
          ],
        }
        this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }

  redirection = () => {
    const { history } = this.props;
    if(history) history.push('/AddVesselDesignDetailList');
   }
  render(){
    const{DesignTypeName} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
       <Card className="shadow p-4 col-md-8 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Add Vessel Design Details</h3>
         </Col>
         <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col>
       </Row>
       <Form>
         <Row>
           <Col>
           <FormGroup>
           <Label>Design Type Name</Label>
           <DropDown title="Select Design Type Name" options={DesignTypeName} selectedVal={this.selectedValue}/>
           </FormGroup>
           </Col>
         </Row>
        <Row>
            <Col  md={12} className="mb-2">
            <FormGroup>
                <Label>Attachment 1</Label>
                <FileUploader/>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-2">
        <Col md={12}>
            <Label>Attachment 2</Label>
            <FileUploader/>
          </Col>
          <Col md={12}>
            <Label>Attachment 3</Label>
            <FileUploader/>
          </Col>
        </Row>
            <Row>
        <Col md={6}>
            <FormGroup>
                <Label>Available </Label><br/>
                <Switch onChange={this.handleChange} checked={this.state.checked}  />
            </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
    </Container>
    );
}

}