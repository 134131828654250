import React from "react";
import ReactDOM from "react-dom";
import RBCarousel from "react-bootstrap-carousel";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Ship from '../../../assets/ship2.jpg'
import ship2 from '../../../assets/ship.jpg'
import ship3 from '../../../assets/ship3.jpg'

const styles = { height: 400, width: "100%" };
const icon_glass = <span className="fa fa-glass" />;
const icon_music = <span className="fa fa-music" />;

export default class Banner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.state = {
      autoplay: true,
    };
  }
  _onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };
  _visiableOnSelect = (active) => {
    console.log(`visiable onSelect active=${active}`);
  };
  _slideNext = () => {
    this.slider.current.slideNext();
  };
  _slidePrev = () => {
    this.slider.current.slidePrev();
  };
  _goToSlide = () => {
    this.slider.current.goToSlide(1);
  };
  _autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    leftIcon = leftIcon ? undefined : icon_glass;
    rightIcon = rightIcon ? undefined : icon_music;
    this.setState({ leftIcon, rightIcon });
  };
  render() {
    return (
      <div className="container-fluid CourosalWp">
        <Row>
          <Col span={12} style={{ marginTop: 20 }}>
            <RBCarousel
              animation={true}
              autoplay={this.state.autoplay}
              slideshowSpeed={2000}
              defaultActiveIndex={0}
              leftIcon={this.state.leftIcon}
              rightIcon={this.state.rightIcon}
              onSelect={this._onSelect}
              ref={this.slider}
              version={4}
            >
              <div style={{ height: 500 }} className="single-slide-item ">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={Ship}
                />
                <div className="OverlayWp"></div>
                <div className="item-table">
                    <div className="item-tablecell">
                        <div className="container text-center">
                        <div className="row">
                            <div class="col-lg-10 offset-lg-1">
                            <h3 style={{opacity: '1'}} className="animated fadeInUp">One Stop Destination For All Your </h3>
                            <h2 style={{opacity: '1'}} className="animated fadeInDown">Maritime Needs</h2>
                            <a href="#" className="wshipping-button slide-btn animated fadeInDown" style={{opacity: '1'}}>Contact Weth Us</a> </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              <div  className="single-slide-item ">
              <img
                  style={{ width: "100%" }}
                  src={ship3}
                />
                <div className="OverlayWp"></div>
              </div>
              <div>
                <img
                  style={{ width: "100%"}}
                  src={ship2}
                />
                <div className="OverlayWp"></div>
              </div>
            </RBCarousel>
          </Col>
         
        </Row>
      </div>
    );
  }
}