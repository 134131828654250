
import React from "react";
import $ from 'jquery';
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
//import FlashMessage from 'react-flash-message';
import 'react-notifications-component/dist/theme.css'
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { AxiosConfig } from "../Common/AxiosConfig";

const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
const validateUrl = 'drw_type/name';

export default class VesselDrawingTypeMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      drwTypeName: "",
      cr_id: "",
      cr_date: "",
      errors: {},
      msg: {},
      message: "",
      title: ""
    };

  }
  // selectedValue = (value) =>{
  //   this.setState({selectedValue:value})
  // }

  //handle the changes
  changeHandler = (e) => {
    // let name = event.target.name;
    // let value = event.target.value.replace(/[^A-Za-z]/ig, '');
    // this.setState({ [name]: value });
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  redirection = () => {
    const { history } = this.props;
    //push the input data in list table
    if (history) history.push('/VesselDrawingTypeList');
  }

  //refresh function that automatically refresh the page
  // refresh = () => {
  //   setTimeout(function () { window.location.reload(false); }, 3000);//1000 for 1sec
  // }

  //function for update data
  updateDrw_type = () => {

    AxiosConfig.put("drw_type/" + this.state.id, this.state)
      .then(response => {
        this.setState({ message: `Drawing Type Updated Successfully..` });

      })
  }
  onReset = (e) => {

    this.setState({
      drwTypeName: "",
      

    })

  }

  //function for add data
  addDrw_type = () => {
    AxiosConfig.post("drw_type", this.state)
      .then(response => {

        console.log(response);
        this.setState({ message: `Drawing Type Submitted Successfully !!`, drwTypeName: "", });


      })
  }
  outMessage = () => {

    $(".alert").show();

    setTimeout(function () {

      $(".alert").hide();

    }, 2000);

    this.setState({ message: "" })
    this.setState({ message1: "" })

  }

  //alert
  updateAlert() {
    NotificationManager.success('', 'Successfully Updated!');
  }
  addAlert() {
    NotificationManager.success('', 'Successfully Added!');
  }

  //handle the submit button (onSubmit)
  subAct = (value) => {
    value.preventDefault();
    console.log(this.state);
    //update
    if (this.state.id != null) {
      this.updateDrw_type(this.state.id);
      this.setState({ drwTypeName: "" });
      this.updateAlert();
      //  window.location.href = '/VesselDrawingTypeMaster';
    }
    //add
    else if (this.state.id == null) {
      this.addDrw_type();
      this.setState({ drwTypeName: "" });
      this.addAlert();
      //this.refresh();
    }
    //  this.refresh();
    // this.setState({message:msg});
   
    this.outMessage();


  }

  componentDidMount() {
    console.log(this.state.id);
    if (this.state.id != null) {
      AxiosConfig.get("drw_type/" + this.state.id)
        .then(response => {
          let type = response.data;
          this.setState({ id: type.id, drwTypeName: type.drwTypeName, cr_id: type.cr_id, cr_date: type.cr_date });
        });

    }

    if (this.state.id == null) {
      this.setState({ title: "Add" });
    } else {
      this.setState({ title: "Update" });
    }
  }

  render() {
    const { id, drwTypeName, cr_id, cr_date, title } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        {/* <Card className="shadow p-4 col-md-12"> */}

        <h6>{this.state.message && <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto" role="alert">
          <a href="/VesselDrawingTypeMaster" className="close" data-dismiss="alert" aria-label="close">&times;</a>{this.state.message}</div>}</h6>


        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Vessel Drawing Type Master</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.subAct}>

            <Row>
              <Col md={12}>
                <Input type="hidden" name="id" required value={id} />
                <Input type="hidden" name="cr_id" required value={cr_id} />
                <Input type="hidden" name="cr_date" required value={cr_date} />

                <FormGroup>
                  <Label>Drawing Type Name</Label>
                  <Input type="text" name="drwTypeName" placeholder="Enter Drawing Type Name" required value={drwTypeName} onChange={this.changeHandler} />
                  <ValidateMaterial url={validateUrl} name={drwTypeName} id={id}></ValidateMaterial>
                  {/* <div style={{color:"green"}}><NotificationContainer/></div> */}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="text-center mt-2">
                <div className="center-block text-center">
                  <Button id="button" className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>
                  <Button className="text-center " color="danger" onClick={this.onReset}>Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
        {/* <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'visibility',
                        tooltip: 'View',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row> */}
        {/* </Card> */}
      </Container>
    );
  }

}