import React, { Component } from "react";
import {
  Container,
  Card,
  Input,
  Form,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import {
  AxiosConfig,
  ImageService,
  ThumbnailImageUrl,
} from "../Common/AxiosConfig.js";
import Switch from "react-switch";
import FileDropzone from "../Common/FileDropzone";
import DeleteConfirm from "../../Containers/Common/deleteComf.js";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "../../service/axiosConfig.js";
import $ from "jquery";

export default class UpdateVesselDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      selectOptions: [],
      vessel_Design_name: "",
      errors: {},
      availble: false,
      attachment1: "",
      attachment2: "",
      attachment3: "",
      thumbnail1Name: "",
      thumbnail2Name: "",
      thumbnail3Name: "",
      name1: "",
      name2: "",
      name3: "",
      cr_id: "",
      cr_date: "",
      designid: sessionStorage.getItem("designid"),
      vesselDesign_attachment1: "",
      vesselDesign_attachment2: "",
      vesselDesign_attachment3: "",
    };
  }
  componentDidMount() {
    this.getOptions();
  }
  getDesignById() {
    AxiosConfig.get("/VesselDetailDesign/" + this.state.designid).then(
      (res) => {
        console.log(res.data);
        this.setState({
          vessel_Design_name: res.data.vesselDesignTypeMaster.id,
          availble: res.data.availble,
          attachment1: res.data.attachment1name,
          attachment2: res.data.attachment2name,
          attachment3: res.data.attachment3name,
          name1: res.data.attachment1name,
          name2: res.data.attachment2name,
          name3: res.data.attachment3name,
          cr_id: res.data.cr_id,
          cr_date: res.data.cr_date,
          thumbnail1Name: res.data.thumbnail1Name,
          thumbnail2Name: res.data.thumbnail2Name,
          thumbnail3Name: res.data.thumbnail3Name,
        });
        const valueToSet = this.state.selectOptions.find((row) => {
          return row.value === this.state.vessel_Design_name;
        });
        if (valueToSet) {
          this.handleChange(valueToSet);
        }
      }
    );
  }
  async getOptions() {
    const res = await axios.get("dsgType_view");
    const data = res.data;
    const options = data.map((d) => ({
      value: d.id,
      label: d.designTypeName,
    }));
    this.setState({ selectOptions: options });
    this.getDesignById();
  }
  handleChange = (value) => {
    this.setState({ vessel_Design_name: value });
  };
  switchhandleChange = (availble) => {
    this.setState({ availble });
  };
  onDropAttachment1 = (files) => {
    if (files.length > 0) {
      this.setState({ vesselDesign_attachment1: files });
    }
  };
  onDropAttachment2 = (files) => {
    if (files.length > 0) {
      this.setState({ vesselDesign_attachment2: files });
    }
  };

  onDropAttachment3 = (files) => {
    if (files.length > 0) {
      this.setState({ vesselDesign_attachment3: files });
    }
  };

  onClear = () => {
    this.setState({
      errors: {},
      availble: false,
      attachment1: "",
      attachment2: "",
      attachment3: "",
      thumbnail1Name: "",
      thumbnail2Name: "",
      vessel_Design_name: "",
      thumbnail3Name: "",
      name1: "",
      name2: "",
      name3: "",
      vesselDesign_attachment1: "",
      vesselDesign_attachment2: "",
      vesselDesign_attachment3: "",
    });
  };
  updateDesign = (e) => {
    e.preventDefault();
    if (
      !this.state.vesselDesign_attachment1 &&
      !this.state.vesselDesign_attachment2 &&
      !this.state.vesselDesign_attachment3
    ) {
      var designformData = {
        id: this.state.designid,
        vesselDesignTypeMaster: {
          id: this.state.vessel_Design_name.value,
        },

        availble: this.state.availble,
        attachment1name: this.state.name1,
        attachment2name: this.state.name2,
        attachment3name: this.state.name3,
        thumbnail1Name: this.state.thumbnail1Name,
        thumbnail2Name: this.state.thumbnail2Name,
        thumbnail3Name: this.state.thumbnail3Name,
        cr_id: this.state.cr_id,
        cr_date: this.state.cr_date,
      };

      axios
        .put("vesseldetaildesign/" + this.state.designid, designformData)
        .then((res) => {
          this.setState({
            message: `vessel design update Successfully!!`,
          });
          this.onClear();
        });
    } else {
      let array = [];
      let arr = [];
      if (this.state.vesselDesign_attachment1) {
        array.push(this.state.vesselDesign_attachment1[0]);
        arr.push({
          name: "name1",
          filename: "",
          thumbnailfile: "",
          thumbnailfilename: "thumbnail1Name",
        });
      }
      if (this.state.vesselDesign_attachment2) {
        array.push(this.state.vesselDesign_attachment2[0]);
        arr.push({
          name: "name2",
          filename: "",
          thumbnailfile: "",
          thumbnailfilename: "thumbnail2Name",
        });
      }
      if (this.state.vesselDesign_attachment3) {
        array.push(this.state.vesselDesign_attachment3[0]);
        arr.push({
          name: "name3",
          filename: "",
          thumbnailfile: "",
          thumbnailfilename: "thumbnail3Name",
        });
      }
      let formData1 = new FormData();

      for (let a = 0; a < array.length; a++) {
        console.log(array[a]);
        formData1.append("files", array[a]);
      }

      ImageService.post("addMultiImageFile", formData1).then((res) => {
        if (res.status == 200) {
          let filenameloop = res.data.fileNames;

          for (let i = 0; i < filenameloop.length; i++) {
            arr[i].filename = res.data.fileNames[i];
            arr[i].thumbnailfile = res.data.thumbnailsFileName[i];

            this.setState({ [arr[i].name]: res.data.fileNames[i] });
            this.setState({
              [arr[i].thumbnailfilename]: res.data.thumbnailsFileName[i],
            });
          }
          this.setState({
            fileDownloadUri: res.data.fileDownloadUri,
            thumbnailUrl: res.data.thumbnailUrl,
          });
          var designformData = {
            id: this.state.designid,
            vessel_Design_name: this.state.vessel_Design_name.value,
            availble: this.state.availble,
            attachment1name: this.state.name1,
            attachment2name: this.state.name2,
            attachment3name: this.state.name3,
            thumbnail1Name: this.state.thumbnail1Name,
            thumbnail2Name: this.state.thumbnail2Name,
            thumbnail3Name: this.state.thumbnail3Name,
            cr_id: this.state.cr_id,
            cr_date: this.state.cr_date,
          };
          axios
            .put("vesseldetaildesign/" + this.state.designid, designformData)
            .then((res) => {
              this.setState({
                message: `vessel design update Successfully!!`,
              });
              this.onClear();
            });
        }
      });
    }

    this.outMessage();
  };
  outMessage = () => {
    $(".alert").show();

    setTimeout(function () {
      $(".alert").hide();
    }, 2000);

    this.setState({ message: "" });
  };
  render() {
    return (
      <div>
        <Container fluid className="AddVesselDrawing formWpr">
          <h6>
            {this.state.message && (
              <div className="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                <a
                  href="FlagMasterTable"
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                >
                  {" "}
                  &times;{" "}
                </a>{" "}
                {this.state.message}{" "}
              </div>
            )}{" "}
          </h6>

          <Card className="shadow p-4 col-md-10 mx-auto">
            {" "}
            <Row>
              <Col className="mb-4">
                <h3 className="text-center blueText">Vessel Designs </h3>
              </Col>
              {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
            </Row>
            <Form onSubmit={this.updateDesign}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">Vessel Design</Label>
                    <Select
                      options={this.state.selectOptions}
                      onChange={this.handleChange}
                      required
                      value={this.state.vessel_Design_name}
                    />{" "}
                    <div className="errorMsg">
                      {this.state.errors.vessel_Design_name}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="required">availble</Label>
                    <br />
                    <Switch
                      onChange={this.switchhandleChange}
                      checked={this.state.availble}
                      icon={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mb-2">
                  <FormGroup>
                    <Label className="required">Attachment 1</Label>
                    <FileDropzone
                      ondrop={this.onDropAttachment1}
                      selectedfiles={this.state.vesselDesign_attachment1}
                      fileinfo={this.state.attachment1}
                    />
                    <div className="errorMsg">
                      {this.state.errors.vesselDesign_attachment1}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Label className="required">Attachment 2</Label>
                  <FileDropzone
                    ondrop={this.onDropAttachment2}
                    selectedfiles={this.state.vesselDesign_attachment2}
                    fileinfo={this.state.attachment2}
                  />
                  <div className="errorMsg">
                    {this.state.errors.vesselDesign_attachment2}
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={6}>
                  <Label className="required">Attachment 3</Label>
                  <FileDropzone
                    ondrop={this.onDropAttachment3}
                    selectedfiles={this.state.vesselDesign_attachment3}
                    fileinfo={this.state.attachment3}
                  />
                  <div className="errorMsg">
                    {this.state.errors.vesselDesign_attachment3}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={1} className="AddBtnCol">
                  <Button>UPDATE</Button>
                </Col>

                <Col md={3} className="AddBtnCol">
                  <div className="center-block text-center">
                    <Button
                      className="text-center "
                      onClick={this.onClear}
                      color="danger"
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>

          <Modal show={this.state.showHide} className="ViewModal">
            <Modal.Header
              closeButton
              onClick={() => {
                this.handleModalShowHide();
                this.handleModalShowHide1();
                this.handleModalShowHide2();
              }}
            >
              <Modal.Title>Attachment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <img className="photo" src={this.state.url1} /> */}
              <img
                className="photo"
                src={ThumbnailImageUrl + `${this.state.url}`}
              />
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    );
  }
}
