import React from "react";
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import { Image } from "react-bootstrap";

import Switch from "react-switch";
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'

import $ from 'jquery';


import '../../../src/index.css'

import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import "bootstrap/dist/css/bootstrap.min.css";
import FileDropzone from "../Common/FileDropzone";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { AxiosConfig, ImageService, ThumbnailImageUrl } from "../Common/AxiosConfig";

import VisibilityIcon from '@material-ui/icons/Visibility';

import { Modal } from 'react-bootstrap'
import Select from 'react-select';

import { withRouter } from "react-router";

const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0,
}

const rowStyle = {
    fontSize: '12px',
    padding: '10px',
    color: '#818698',
};

class AddVesselDrawing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            VesselYearBuilt: [
                { value: '2019' },
                { value: '2018' }]
            ,
            checked: false,
            allinfo: [],
            columns: [
                { title: "Id", field: "id", hidden: true },

                { title: "Drawing", field: "drwTypeName" },
                {

                    title: "Available", field: "vesselDrawing_available",

                    render: rowData => {

                        return (rowData.vesselDrawing_available ? "YES" : "NO")

                    }
                },
                {
                    title: "Attachment 1", field: "vesselDrawing_attachment1", render: rowData =>

                        <VisibilityIcon className="visiblityIcnViewer" onClick={() => this.handleModalShowHide(rowData.id, rowData.thumbnail1Name)} status={rowData.id} />
                },
                { title: "Attachment 2", field: "vesselDrawing_attachment2", render: rowData => <VisibilityIcon className="visiblityIcnViewer" onClick={() => this.handleModalShowHide1(rowData.id, rowData.thumbnail2Name)} status={rowData.id} /> },
                { title: "Attachment 3", field: "vesselDrawing_attachment3", render: rowData => <VisibilityIcon className="visiblityIcnViewer" onClick={() => this.handleModalShowHide2(rowData.id, rowData.thumbnail3Name)} status={rowData.id} /> },
            ],
            drawing_type: [],
            vesselDrawing_name: "",
            vesselDrawing_available: false,
            id: this.props.match.params.id,
            vesselDrawing_attachment1: "",
            vesselDrawing_attachment2: "",
            vesselDrawing_attachment3: "",
            rowData: [],
            showHide: false,
            selectedFiles: "",
            selectedFiles1: "",
            selectedFiles2: "",
            currentFile: "",
            currentFile1: "",
            currentFile2: "",
            message1: "",
            message2: "",
            url: "",

            vesselDrawingDetails: [],
            vesselDrawingDetails1: [],
            fileInfos: [],

            cr_id: "",
            cr_date: "",
            errors: {},
            merged: [],


        }
    }
    validateForm = () => {

        let errors = {};
        let formIsValid = true;

        if (!this.props.inputData.vesselDrawing_name) {
            formIsValid = false;

            errors["vesselDrawing_name"] = "*Please choose vessel drawing type";
        }



        if (!this.props.inputData.selectedFiles && !this.props.inputData.vesselDrawing_attachment1) {
            formIsValid = false;
            errors["selectedFiles"] = "*Please select attachment1";
        }

        if (!this.props.inputData.selectedFiles1 && !this.props.inputData.vesselDrawing_attachment2) {
            formIsValid = false;
            errors["selectedFiles1"] = "*Please select attachment2";
        }

        if (!this.props.inputData.selectedFiles2 && !this.props.inputData.vesselDrawing_attachment3) {
            formIsValid = false;
            errors["selectedFiles2"] = "*Please select attachment3";
        }



        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    validation = () => {


        if (this.validateForm() === true) {

            this.props.next();
        }
        else {
            this.validateForm()
        }
    }

    outMessage = () => {

        $(".alert").show();

        setTimeout(function () {

            $(".alert").hide();

        }, 2000);

        this.setState({ message1: "" })
        this.setState({ message2: "" })

    }


    onReset = (e) => {

        this.setState({
            selectedFiles: "",
            selectedFiles1: "",
            selectedFiles2: "",
            vesselDrawing_available: false,
            vesselDrawing_name: "",
            name1: "",
            name2: "",
            name3: '',
            vesselDrawing_attachment1: '',
            vesselDrawing_attachment2: '',
            vesselDrawing_attachment3: '',

        })

        this.props.inputData.vesselDrawing_name = ""
        this.props.inputData.vesselDrawing_available = ""
        this.props.inputData.vesselDrawing_attachment1 = ""
        this.props.inputData.vesselDrawing_attachment2 = ""
        this.props.inputData.vesselDrawing_attachment3 = ""
        this.props.inputData.name1 = ""
        this.props.inputData.name2 = ""
        this.props.inputData.name3 = ""
        this.props.inputData.cr_id = ""
        this.props.inputData.cr_date = ""
        this.props.inputData.drawingid = ""

    }
    getData(id) {

        if (id != null) {
            AxiosConfig.get("vesseldrawing/" + id)
                .then((response) => {
                    console.log(response.data, "res");

                    let vesselDrawing = response.data;
                    this.props.inputData.drawingid = id;
                    this.props.inputData.vesselDrawing_name = vesselDrawing.vesselDrawing_name
                    this.props.inputData.vesselDrawing_available = vesselDrawing.vesselDrawing_available
                    this.props.inputData.vesselDrawing_attachment1 = vesselDrawing.vesselDrawing_attachment1
                    this.props.inputData.vesselDrawing_attachment2 = vesselDrawing.vesselDrawing_attachment2
                    this.props.inputData.vesselDrawing_attachment3 = vesselDrawing.vesselDrawing_attachment3
                    this.props.inputData.name1 = vesselDrawing.attachment1name
                    this.props.inputData.name2 = vesselDrawing.attachment2name
                    this.props.inputData.name3 = vesselDrawing.attachment3name
                    this.props.inputData.cr_id = vesselDrawing.cr_id
                    this.props.inputData.cr_date = vesselDrawing.cr_date

                    this.setState({
                        id: vesselDrawing.id,
                        vesselDrawing_name: vesselDrawing.vesselDrawing_name,
                        vesselDrawing_available: vesselDrawing.vesselDrawing_available,
                        vesselDrawing_attachment1: vesselDrawing.vesselDrawing_attachment1,
                        vesselDrawing_attachment2: vesselDrawing.vesselDrawing_attachment2,
                        vesselDrawing_attachment3: vesselDrawing.vesselDrawing_attachment3,
                        name1: vesselDrawing.attachment1name,
                        name2: vesselDrawing.attachment2name,
                        name3: vesselDrawing.attachment3name,
                        cr_id: vesselDrawing.cr_id,
                        cr_date: vesselDrawing.cr_date,

                    });
                    const valueToSet = this.state.drawing_type.find((row) => {
                        return row.value === this.props.inputData.vesselDrawing_name;
                    });
                    if (valueToSet) {
                        this.onInputChange(valueToSet);
                    }
                    if (valueToSet) {
                        this.onInputChange(valueToSet);
                    }
                })
                .catch((error) => {

                    console.log(error);
                });
        }
    }
    selectOne(id) {

        AxiosConfig.get("/vesseldrawing/" + id).
            then(response => {
                this.getData(id);
            })
    }

    update1 = (e) => {
        e.preventDefault();
        console.log(this.state)
        // e.target.reset();
        let currentFile;
        let currentFile1;
        let currentFile2;
        if (this.props.inputData.selectedFiles) {
            currentFile = this.props.inputData.selectedFiles[0];
        }
        else {
            currentFile = this.props.inputData.vesselDrawing_attachment1
        }
        if (this.props.inputData.selectedFiles1) {
            currentFile1 = this.props.inputData.selectedFiles1[0];
        }
        else {
            currentFile1 = this.props.inputData.vesselDrawing_attachment2
        }
        if (this.props.inputData.selectedFiles2) {
            currentFile2 = this.props.inputData.selectedFiles2[0];
        }
        else {
            currentFile2 = this.props.inputData.vesselDrawing_attachment3
        }
        let vesselDrawing_name = this.state.vesselDrawing_name.value;
        let vesselDrawing_available = this.state.vesselDrawing_available;
        this.setState({ progress: 0 })
        this.setState({ currentFile: currentFile })
        this.setState({ currentFile1: currentFile1 })
        this.setState({ currentFile2: currentFile2 })
        let formData = new FormData();

        formData.append("vesselDrawing_name", this.props.inputData.vesselDrawing_name.value);
        formData.append("vesselDrawing_available", this.props.inputData.vesselDrawing_available);
        formData.append("vesselDrawing_attachment1", currentFile);
        formData.append("vesselDrawing_attachment2", currentFile1);
        formData.append("vesselDrawing_attachment3", currentFile2);
        formData.append("cr_id", this.props.inputData.cr_id);
        formData.append("cr_date", this.props.inputData.cr_date);
        if (this.validateForm()) {
            AxiosConfig.put("vesseldrawing/" + this.props.inputData.drawingid, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {

                    this.setState({
                        message1: `vessel drawing update Successfully!!`,
                    });
                    this.setState({ id: '' })
                    this.getVesselDrawing();
                    this.onReset();

                })
                .catch((error) => {
                    console.log("file not upload")
                    this.onReset();
                });

        }
        this.outMessage();

    };


    deleteDesign = (id) => {
        AxiosConfig.delete(`vesseldrawing/` + id, this.state)
            .then(response => {
                if (response === 201) {
                    console.log(response);
                }
                this.getVesselDrawing();

                this.setState({ message2: "Vessel Drawing Deleted Successfully" });
            })


        this.outMessage();
    }
    async getOptions() {
        const res = await AxiosConfig.get("drw_type")
        const data = res.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.drwTypeName
        }))
        this.setState({ drawing_type: options })
    }


    async getVesselDrawing() {



        await AxiosConfig.get("vesseldrawing")
            .then((res) => {
                this.setState({ vesselDrawingDetails: res.data });
                this.setState({ allinfo: [...this.state.vesselDrawingDetails, ...this.state.fileInfos] });

            });

        await AxiosConfig.get("getDrawingInfo")
            .then((res) => {

                this.setState({ vesselDrawingDetails1: res.data });

            });

        var vesselDrawingDetails = this.state.allinfo;
        var getDrawingInfo = this.state.vesselDrawingDetails1;


        const result = vesselDrawingDetails.map(item => {
            const obj = getDrawingInfo.find(o => o.id === item.id);

            return { ...item, ...obj };
        });
        console.log(result, "result");
        this.setState({ merged: result })

    }
    componentDidMount() {
        this.getOptions();
        this.getVesselDrawing();
        // this.getData();

        let imgUrl = this.state.fileInfos;
        let vesselDetail = this.state.vesselDrawingDetails;
        let all = imgUrl.concat(vesselDetail);
        this.setState({ allinfo: all })
    }
    onDropAttachment1 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles: files })
        }
    };
    onDropAttachment2 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles1: files })
        }
    };
    onDropAttachment3 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles2: files })
        }
    };
    handleChange = (event) => {
        this.setState({ vesselDrawing_name: event });
        console.log(`Option selected:`, this.state.value);
    };
    showImage = () => {
        this.setState({ showHide: !this.state.showHide })
        return this.state.vesselDrawingDetails.map((vesselDrawing_attachment1, index) => (
            <Image
                vesselDrawing_attachment1={vesselDrawing_attachment1.name}
                index={index}
                key={index} />
        ))
    }
    switchhandleChange = (vesselDrawing_available) => {
        this.setState({ vesselDrawing_available });
    }
    // filterArrayElementByEdit(array, id) {
    //     return array.find((element) => {
    //         return element.id === id;
    //     })
    // }
    handleModalShowHide(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })
        
                    this.setState({

                        url: thumbnailName,
                    });

               
    }
    handleModalShowHide1(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })

       
                    this.setState({
                        url: thumbnailName
                    });
               
    }
    handleModalShowHide2(id, thumbnailName) {
        this.setState({ showHide: !this.state.showHide })

       
                    this.setState({
                        url: thumbnailName
                    });
               
       
    }


    onInputChange = (obj) => {
        this.setState({ vesselDrawing_name: obj })
        this.props.inputData.vesselDrawing_name = obj
    }
    


    saveDrawing = (e) => {
        e.preventDefault();
        console.log(this.state)
        e.target.reset();
        var records = {
            "vesselDrawing_name": this.state.vesselDrawing_name.value,
            "vesselDrawing_available": this.state.vesselDrawing_available,
            // "thumbnailsFileName":
        }
        // let currentFile = this.state.selectedFiles[0];
        // let currentFile1 = this.state.selectedFiles1[0];
        // let currentFile2 = this.state.selectedFiles2[0];
        // let vesselDrawing_name = this.state.vesselDrawing_name.value;
        // let vesselDrawing_available = this.state.vesselDrawing_available;
        this.setState({ progress: 0 })
        // this.setState({ currentFile: currentFile })
        // this.setState({ currentFile1: currentFile1 })
        // this.setState({ currentFile2: currentFile2 })
        let formData = new FormData();
        formData.append('vesselDrawing', JSON.stringify(records));

        // formData.append("vesselDrawing_name", vesselDrawing_name);
        // formData.append("vesselDrawing_available", vesselDrawing_available);
        formData.append("vesselDrawing_attachment1", this.state.selectedFiles[0]);
        formData.append("vesselDrawing_attachment2", this.state.selectedFiles1[0]);
        formData.append("vesselDrawing_attachment3", this.state.selectedFiles2[0]);
        if (this.validateForm()) {
            AxiosConfig.post("vesseldrawing", formData
                , {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
                .then((response) => {
                    console.log(response.data, "vessel_drawings_id");
                    sessionStorage.setItem('vessel_drawings_id', response.data)

                    console.log("file added successfully")
                    this.setState({
                        message1: `Vessel drawing added Successfully!!`,
                    });
                    this.getVesselDrawing();
                    this.onReset();
                })
        }
        this.outMessage();
    };

   
    render() {
        const { merged, id, cr_id, cr_date, rowData, Data, columns, drawing_type, value, vesselDrawing_name, vesselDrawing_attachment1, vesselDrawing_attachment2, vesselDrawing_attachment3 } = this.state;

        return (
            <Container fluid className="AddVesselDrawing formWpr">
                <h6>{this.state.message2 && (<div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
                    <a href="FlagMasterTable" className="close" data-dismiss="alert" aria-label="close"
                    > &times; </a> {this.state.message2}  </div>)} </h6>
                <h6>
                    {this.state.message1 && (
                        <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                            <a
                                href="/VesselDetails"
                                className="close"
                                data-dismiss="alert"
                                aria-label="close"
                            >
                                &times;
                            </a>
                            {this.state.message1}
                        </div>
                    )}
                </h6>
                <Card className="shadow p-4 col-md-10 mx-auto">
                    <Row>
                        <Col className="mb-4">
                            <h3 className="text-center blueText">Vessel Drawings </h3>
                        </Col>
                        {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                    </Row>
                    <Form
                    // onSubmit={this.state.id ? this.update1 : this.saveDrawing}
                    >
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Input

                                        type="hidden"

                                        name="id"

                                        required

                                        value={id}

                                    />

                                    <Input type="hidden" name="cr_id" required value={cr_id} />

                                    <Input

                                        type="hidden"

                                        name="cr_date"

                                        required

                                        value={cr_date}

                                    />

                                    <Label className="required">Vessel Drawing</Label>
                                    {/* <DropDown title="Vessel Drawing" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}
                                    {/* <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={Design}/> */}


                                    <Select options={this.state.drawing_type} onChange={this.props.onInputChange} value={this.props.inputData.vesselDrawing_name} placeholder="Select Drawing Type" required />

                                    {
                                        this.props.errors.vesselDrawing_name ?
                                            <div className="errorMsg">{this.props.errors.vesselDrawing_name}</div> :
                                            <div className="errorMsg">{this.state.errors.vesselDrawing_name}</div>}

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Available</Label><br />
                                    <Switch onChange={this.props.switchhandleChange} checked={this.props.inputData.vesselDrawing_available} icon={false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label className="required">Attachment 1</Label>
                                    <FileDropzone ondrop={this.props.onDropAttachment1} selectedfiles={this.props.inputData.selectedFiles} fileinfo={this.props.inputData.name1} />
                                    {
                                        this.props.errors.selectedFiles ?
                                            <div className="errorMsg">{this.props.errors.selectedFiles}</div> :
                                            <div className="errorMsg">{this.state.errors.selectedFiles}</div>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label className="required">Attachment 2</Label>
                                <FileDropzone ondrop={this.props.onDropAttachment2} selectedfiles={this.props.inputData.selectedFiles1} fileinfo={this.props.inputData.name2} />
                                {
                                    this.props.errors.selectedFiles1 ?
                                        <div className="errorMsg">{this.props.errors.selectedFiles1}</div> :
                                        <div className="errorMsg">{this.state.errors.selectedFiles1}</div>}

                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={6}>
                                <Label className="required">Attachment 3</Label>
                                <FileDropzone ondrop={this.props.onDropAttachment3} selectedfiles={this.props.inputData.selectedFiles2} fileinfo={this.props.inputData.name3} />
                                {
                                    this.props.errors.selectedFiles2 ?
                                        <div className="errorMsg">{this.props.errors.selectedFiles2}</div> :
                                        <div className="errorMsg">{this.state.errors.selectedFiles2}</div>}

                            </Col>
                            <Col>
                                <aside className="selected-file-wrapper">
                                    {this.props.inputData.drawingid ?
                                        <Col md={1} className="AddBtnCol">
                                            <Button onClick={this.update1}>UPDATE</Button>
                                        </Col> :
                                        <Col md={1} className="AddBtnCol">
                                            <Button onClick={this.validation}>NEXT</Button>
                                        </Col>
                                    }

                                </aside>
                            </Col>
                            <div style={{ color: "red" }}>{this.state.errormessage}</div>
                            <div style={{ color: "green" }}>
                                <NotificationContainer />
                            </div>

                            <Col className="text-center mt-3">
                                <div className="center-block text-center">

                                    <Button type="reset" className="text-center " color="danger"
                                        onClick={this.onReset}>Clear</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-3">
                            <Col className="tableWp">
                                <Row>
                                    <Col className="DashboardTbl">
                                        {/* <Table data={merged} pageSize={8} paging={false} columns={columns} search={false} headerStyle={headerStyle}
                                            rowStyle={rowStyle} maxBodyHeight='350px'
                                            actions={
                                                [
                                                    {
                                                        icon: 'edit',
                                                        tooltip: 'Edit',
                                                        onClick: (event, rowData) => {
                                                            this.selectOne(rowData.id)
                                                        }
                                                    },
                                                    // {
                                                    //     icon: 'delete',
                                                    //     tooltip: 'Delete',
                                                    //     onClick: (event, rowData) => {
                                                    //         this.setState({ show: true, rowData: rowData })
                                                    //     }
                                                    // },
                                                ]
                                            }
                                        /> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className="text-center mt-3">
                                <div className="center-block text-center">

                                    <Button type="reset" className="text-center " color="danger"
                                        onClick={this.onReset}>Clear</Button>
                                </div>
                            </Col>
                        </Row> */}
                    </Form>
                </Card>
                <DeleteConfirm
                    show={this.state.show}
                    animation={true}
                    rowData={rowData}
                    id={rowData.id}

                    name={rowData.drwTypeName}

                    close={() => this.setState({ show: false })}
                    deleteHandler={this.deleteDesign}
                />
                <Modal show={this.state.showHide} className="ViewModal">
                    <Modal.Header closeButton onClick={() => { this.handleModalShowHide(); this.handleModalShowHide1(); this.handleModalShowHide2(); }}>
                        <Modal.Title>Attachment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img className="photo" src={this.state.url} /> */}
                        <img className="photo" src={ThumbnailImageUrl + `${this.state.url}`} />
                       
                    </Modal.Body>
                </Modal>
            </Container >
        );
    }
}

export default withRouter(AddVesselDrawing)