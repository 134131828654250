import React from 'react';

class DropDown extends React.Component {
  constructor(props){
    super(props);
    this.state={
      value: this.props.value,
      options:this.props.options,
      title:this.props.title
    }
  }
  async componentWillReceiveProps(nextProps){
    if(nextProps.options !== this.props.options){
      await this.setState({ options: nextProps.options });
    }
    if (nextProps.value !== this.props.value) {
      await this.setState({ value: nextProps.value });
    }
  }

  onChange = async (e) => {
    await this.setState({ 
      value: e.target.value 
    });
    this.props.selectedVal(this.state.value);
  };

  render() {
    const{options, title}=this.state;
    return (
      <div>
        <select className="form-control"
            value={this.state.value}
            onChange={this.onChange}
          >
            <option>{title}</option>
           
          {
              options.map(select => {
                  return (
                    <option key={select.id} value={select.name}>
                      {select.name}
                    </option>
                  );
              })
          }
       </select>
     </div>
    )
  }
}




export default DropDown;