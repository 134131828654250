
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import DropDown from '../Common/DropDown'
import VisibilityIcon from '@material-ui/icons/Visibility';

export default  class VesselClassWiseDrawingMaster extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
       DrawingTypeName:[
        { value: 'Test1' },
        { value: 'Test2' }
      ],
        }
       
  }
  selectedValue = (value) =>{
    this.setState({selectedValue:value})
  }

  redirection = () => {
    const { history } = this.props;
    if(history) history.push('/VesselClasswiseDrawingList');
   }
  
  render(){
    const{Data,columns,DrawingTypeName} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
        {/* <Card className="shadow p-4 col-md-12"> */}
       <Card className="shadow p-4 col-md-7 mx-auto">
       <Row>
         <Col className="mb-4">
          <h3 className="text-center blueText">Vessel Class Drawing  Master</h3>
         </Col>
         <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col>
       </Row>
       <Form>
        <Row>
        <Col md={12}>
           <FormGroup>
                <Label>Drawing Type Name</Label>
                <DropDown title=" Select Drawing Type Name" options={DrawingTypeName} selectedVal={this.selectedValue}/>
            </FormGroup>
           </Col>
           </Row>
    
        <Row>
          <Col className="text-center mt-3">
          <div  className="center-block text-center">
            <Button className="text-center mr-3  btnblue">Submit</Button>
            <Button className="text-center " color="danger">Cancel</Button>
           </div>
          </Col>
        </Row>
       </Form>
       </Card>
       {/* <Row className="mt-5 mb-3">
         <Col className="tableWp">
               <Row>
                 <Col className="DashboardTbl">
                   <Table data={Data} pageSize={5} paging={true} columns={columns} search={false} headerStyle={headerStyle}
                    rowStyle={rowStyle} maxBodyHeight='300px' 
                  actions={
                    [
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/CourseMaster?id=${rowData._id}`)
                        }
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.props.history.push(`/Page?id=${rowData._id}`)
                        }
                      }
                    ]
                  }
                   />
                 </Col>
             </Row>            
        
         </Col>
       </Row> */}
       
    </Container>
    );
}

}