import React, { useState } from "react";
import axios from '../../../service/axiosConfig.js';

const ValidateDouble = (props) => {
    console.log(props);
    const url = props.url;
    let name = props.name;
    let id = props.id;

    const [errorMsg, setErrorMsg] = useState([]);

    React.useEffect(() => {
        axios.get(url + "/" + name)
            .then(response => {
                console.log(response.data);
                console.log(id, "idddddd");
                if (id === undefined) {
                    id = undefined
                    name = response.data.name

                }
                else {
                    id = response.data.id;

                }


                if (response.data.id == id || response.data == "") {

                    setErrorMsg("");
                    document.getElementById("submit").disabled = !1;
                    // id = response.data.id

                }
                else {
                    setErrorMsg("This Data Present already");
                    document.getElementById("submit").disabled = !0;


                }
            })
    })


    return (
        <div className="text-danger">
            {errorMsg}
        </div>
    )
}

export default ValidateDouble;