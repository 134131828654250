import React from 'react'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import $ from 'jquery';
import axios from '../../service/axiosConfig.js';

const validateUrl = 'VesselClass/name';
export default class ViewClassDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      className: '',
      cr_id: "",
      cr_date: "",
      message: "",
      title: ""
    }
  }

  componentDidMount() {
    this.getData();
    this.props.history.push("/AddClassDetails");
    if (this.state.id == null) {
      this.setState({ title: "Add" });
    } else {
      this.setState({ title: "Update" });
    }
  }


  getData() {
    if (this.state.id != null) {
      axios.get('VesselClass/' + this.state.id)
        .then((response) => {
          let VesselParticular = response.data;
          this.setState({
            id: VesselParticular.id,
            className: VesselParticular.className,
            cr_id: this.state.cr_id,
            cr_date: this.state.cr_date
          });
        }).catch((error) => {
          alert('error ')
          console.log(error);
        });
    }
  }
  //redirect to list
  redirection = () => {
    const { history } = this.props;
    if (history) history.push("/VesselClassList");
  };

  //save function 
  saveClass = (e) => {
    e.preventDefault();
    var Particulars = {
      className: this.state.className,
    }
    axios.post("VesselClass", Particulars)
      .then((result) => {
        this.setState({
          message: `${this.state.className} class added Successfully!!`, className: ""

        });
    

          this.setState(result)

      }).catch((error) =>
        console.log(error))
    this.outMessage();
  }


  //function for alert suto close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }

  //update function 
  updateClass = (e) => {
    e.preventDefault();
    var Particulars = {
      id: this.state.id,
      className: this.state.className,
      cr_id: this.state.cr_id,
      cr_date: this.state.cr_date
    }
    axios.put("VesselClass/" + this.state.id, Particulars)
      .then((result) => {
        this.setState({
          message: `${this.state.className} class Update Successfully!!`
        });
        this.setState(result)
        this.setState({ className: "" })
      }).catch((error) =>
        console.log(error))
    this.outMessage();
  }

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  reset = () => {
    this.setState({
      className: "",
      id: ""
    });
  };

  render() {
    const { cr_id, cr_date, title, id, className } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/AddClassDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              > </a>
              {this.state.message}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} class</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={id ? this.updateClass : this.saveClass}>
            {/* <Form onSubmit={this.addOrUpdate}> */}
            <Input type="hidden" required value={id} />
            <Input type="hidden" required value={cr_id} />
            <Input type="hidden" required value={cr_date} />
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Add Vessel Class</Label>
                  <Input
                    type="text"
                    name="className"
                    placeholder="Enter class Name"
                    value={className}
                    onChange={this.onInputChange}
                    required="Please enter a class Name"
                  />
                  <ValidateMaterial url={validateUrl} name={className} id={id}></ValidateMaterial>
                  <div style={{ color: "red" }}>{this.state.errormessage}</div>
                  <div style={{ color: "green" }}>
                    <NotificationContainer />
                  </div>
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3 bluebtn" id="submit" type="submit">Submit</Button>
                  <Button className="text-center " color="danger" onClick={this.reset}>Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}
