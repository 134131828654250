
import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../Common/Table'
import '../../Containers/Common/Table.css'
import Switch from "react-switch";
import FileUploader from '../Common/FileUploader'
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DropDown from '../Common/DropDown'
const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,

}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
export default class AddVesselDrawingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      DrawingTypeName: [
        { value: 'Drawing 1' },
        { value: 'Drawing 2' }
      ],
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  selectedValue = (value) => {
    this.setState({ selectedValue: value })
  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push('/VesselDrawingList');
  }
  render() {
    const { DrawingTypeName } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Add Vessel Drawing Details</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Drawing Type Name</Label>
                  <DropDown title="Select Drawing Type Name" options={DrawingTypeName} selectedVal={this.selectedValue} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-2">
                <FormGroup>
                  <Label>Attachment 1</Label>
                  <FileUploader />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={12}>
                <Label>Attachment 2</Label>
                <FileUploader />
              </Col>
              <Col md={12}>
                <Label>Attachment 3</Label>
                <FileUploader />
              </Col>

            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Available </Label><br />
                  <Switch onChange={this.handleChange} checked={this.state.checked} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-5">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Submit</Button>
                  <Button className="text-center " color="danger">Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }

}