import React, { Component } from 'react'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Select from 'react-select';
import { AxiosConfig } from "../Common/AxiosConfig";
import $ from 'jquery';

export default class UpdateVesselEquipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vesselEquipment: "",
            vesselEquipments: [],
            equipmentValue: "",
            drawing_type: [],
            errors: {},
            equipmentid: sessionStorage.getItem('equipmentid'),
            message: "",
            cr_id: "",
            cr_date: ""
        }
    }
    componentDidMount() {
        this.getOptions();





    }
    getEquipmentById() {
        AxiosConfig.get("VesselEquipment/" + this.state.equipmentid).
            then(response => {
                console.log(response.data);
                this.setState({

                    vesselEquipment: response.data.vesselEquipmentMaster.id,
                    equipmentValue: response.data.equipmentValue,
                    cr_id: response.data.cr_id,
                    cr_date: response.data.cr_date,
                });
                const valueToSet = this.state.drawing_type.find((row) => {
                    return row.value === response.data.vesselEquipmentMaster.id;
                });
                console.log(valueToSet);
                if (valueToSet) {

                    this.onInputChange(valueToSet);
                }
            })
    }
    async getOptions() {
        const res = await AxiosConfig.get("EquipmentMaster_view")
        const data = res.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.vesselEqpname
        }))
        this.setState({ drawing_type: options })
        this.getEquipmentById();
    }
    onClear = () => {
        this.setState({
            vesselEquipment: "",
            vesselEquipments: [],
            equipmentValue: "",
            drawing_type: []
        })
    }
    validateForm() {

        let errors = {};
        let formIsValid = true;

        if (!this.state.vesselEquipment) {
            formIsValid = false;
            errors["vesselEquipment"] = "*Please choose vessel equipments";
        }
        if (!this.state.equipmentValue) {
            formIsValid = false;
            errors["equipmentValue"] = "*Please enter equipment value";
        }



        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    onInputChange = (obj) => {

        this.setState({ vesselEquipment: obj })


    }
    onInputValueChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    updateRecord = (e) => {
        e.preventDefault();
        var Equipment = {
            id: this.state.equipmentid,
            vesselEquipmentMaster:{
                id:this.state.vesselEquipment.value
            },
            equipmentValue: this.state.equipmentValue,
            cr_id: this.state.cr_id,
            cr_date: this.state.cr_date,

        }
        if(this.validateForm()){
        AxiosConfig.put("VesselEquipment/" + this.state.equipmentid, Equipment)
            .then((result) => {
                if (result === 201) {
                    console.log(result);
                    this.onClear();
                }

                this.setState({
                    message: 'Vessel Equipments Updated sucessfully!!',

                })

            })
            .catch((error) => {
                console.log(error);
            });
        }
        this.outMessage();
    }
    outMessage = () => {

        $(".alert").show();

        setTimeout(function () {

            $(".alert").hide();

        }, 2000);

        this.setState({ message: "" })


    }
    render() {
        return (
            <div>
                <Container fluid className=" FormWp">

                    <h6>
                        {this.state.message && (
                            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                                <a
                                    href="/VesselDetails"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="close"
                                >
                                    &times;
                                </a>
                                {this.state.message}
                            </div>
                        )}
                    </h6>
                    <Card className="shadow p-4 col-md-8 mx-auto">
                        <Row>
                            <Col className="mb-4">
                                <h3 className="text-center blueText"> Vessel Equipments</h3>
                            </Col>
                            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                        </Row>
                        <Form
                            onSubmit={this.updateRecord}
                        >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>


                                        <Label className="required">Vessel Equipment</Label>
                                        <Select options={this.state.drawing_type} onChange={this.onInputChange} value={this.state.vesselEquipment} placeholder="Select Equipments"
                                        />
                                        {
                                            this.state.errors.vesselEquipment &&

                                            <div className="errorMsg">{this.state.errors.vesselEquipment}</div>
                                        }

                                    </FormGroup>
                                </Col>

                                <Col md={5}>
                                    <FormGroup>
                                        <Label className="required">Equipment Value</Label>
                                        <Input type="text" name="equipmentValue" value={this.state.equipmentValue} onChange={this.onInputValueChange} placeholder="Enter Equipment Value" />

                                        {
                                            this.state.errors.equipmentValue &&

                                            <div className="errorMsg">{this.state.errors.equipmentValue}</div>}

                                    </FormGroup>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Col md={1} className="AddBtnCol">

                                    <Button>UPDATE</Button>


                                </Col>


                                <Col md={3} className="AddBtnCol">
                                    <div className="center-block text-center">
                                        <Button className="text-center "
                                            onClick={this.onClear}
                                            color="danger">Clear</Button>
                                    </div>
                                </Col>
                            </Row>

                        </Form>
                    </Card>


                </Container>
            </div>
        )
    }
}
