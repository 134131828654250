import React from "react";
// import '../Course/CourseMaster.css'
import {
  Form,
  Card,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import DropDown from "../Common/DropDown";
import Table from "../Common/Table";
import "../../Containers/Common/Table.css";
import Switch from "react-switch";
import Select from "react-select";
import { AxiosConfig } from "../Common/AxiosConfig.js";

import axios from "../../service/axiosConfig.js";
import { withRouter } from "react-router";
import DeleteConfirm from "../../Containers/Common/deleteComf.js";
import $ from "jquery";

const headerStyle = {
  backgroundColor: "#052754",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  padding: "10px",
  position: "sticky",
  top: 0,
};
const rowStyle = {
  fontSize: "12px",
  padding: "10px",
  color: "#818698",
};

class AddVesselMaterial extends React.Component {
  // _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      columns: [
        { title: "Id", field: "material_id", hidden: true },
        // {
        //   title: 'Sr No', field: 'tableData.id',
        //   render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        // },
        { title: "Material", field: "materialName" },
        { title: "Material Value", field: "material_Value" },
        { title: "Unit", field: "unitName" },
        { title: "Surveyor", field: "surveyorName" },
        {
          title: "Approved",
          field: "approved",
          render: (rowData) => {
            return rowData.approved ? "YES" : "NO";
          },
        },
      ],
      selectedName: [],
      selectedUnit: [],
      selectedSurveyor: [],

      id: this.props.match.params.id,
      material_Name: "",
      material_Value: "",
      material_Unit: "",
      surveyor: "",
      approved: false,

      message: "",
      message1: "",

      MaterialData: [],
      MaterialNameData: [],
      MaterialUnitData: [],
      MaterialSurData: [],
      merged: [],
      rowData: [],
      show: false,

      cr_id: "",
      cr_date: "",

      error: {},
      errors: {
        material_Name: "",
        material_Value: "",
        material_Unit: "",
        surveyor: "",
        approved: "",
      },
    };
  }
  //to get material name from databse
  async getMaterialName() {
    const res = await axios.get("material");
    const data = res.data;

    const materialOptions = data.map((d) => ({
      value: d.id,
      label: d.materialName,
    }));
    this.setState({ selectedName: materialOptions });
  }

  //to get material unit from databse
  async getUnit() {
    const res = await axios.get("unit");
    const data = res.data;

    const unitOptions = data.map((d) => ({
      value: d.id,
      label: d.unitName,
    }));
    this.setState({ selectedUnit: unitOptions });
  }

  //to get surveyor from databse
  async getSurveyor() {
    const res = await axios.get("surveyor");
    const data = res.data;

    const surveyorOptions = data.map((d) => ({
      value: d.id,
      label: d.surveyorName,
    }));
    this.setState({ selectedSurveyor: surveyorOptions });
  }

  //to fetch all vessel material from database
  async getVesselMaterials() {
    await AxiosConfig.get("VesselMaterial").then((response) => {
      this.setState({ MaterialData: response.data });
    });

    await AxiosConfig.get("/getMaterialNameInfo").then((response) => {
      this.setState({ MaterialNameData: response.data });
    });

    await AxiosConfig.get("/getMaterialUnitInfo").then((response) => {
      this.setState({ MaterialUnitData: response.data });
    });

    await AxiosConfig.get("/getSurveyorInfo").then((response) => {
      this.setState({ MaterialSurData: response.data });
    });

    var data = this.state.MaterialData;
    var nameData = this.state.MaterialNameData;
    var unitData = this.state.MaterialUnitData;
    var surData = this.state.MaterialSurData;

    const result = data.map((item) => {
      const obj = nameData.find((o) => o.id === item.id);
      const obj1 = unitData.find((p) => p.id === item.id);
      const obj2 = surData.find((q) => q.id === item.id);

      return { ...item, ...obj, ...obj1, ...obj2 };
    });
    this.setState({ merged: result });
  }

  //to update vessel material
  updateVesselMaterial(id) {
    AxiosConfig.get("VesselMaterial/" + id).then((response) => {
      this.getData(id);
    });
  }

  //Get single material data for update
  getData(id) {
    if (id != null) {
      AxiosConfig.get("VesselMaterial/" + id)
        .then((response) => {
          let material = response.data;
          this.props.inputData.materialId = id;
          this.props.inputData.material_Name = material.material_Name;
          this.props.inputData.material_Value = material.material_Value;
          this.props.inputData.material_Unit = material.material_Unit;
          this.props.inputData.surveyor = material.surveyor;
          this.props.inputData.approved = material.approved;
          this.props.inputData.cr_id = material.cr_id;
          this.props.inputData.cr_date = material.cr_date;
          this.setState({
            id: material.id,
            material_Name: material.material_Name,
            material_Value: material.material_Value,
            material_Unit: material.material_Unit,
            surveyor: material.surveyor,
            approved: material.approved,
            cr_id: material.cr_id,
            cr_date: material.cr_date,
          });

          //for Materialname
          const valueToSet = this.state.selectedName.find((row) => {
            return row.value === this.props.inputData.material_Name;
          });
          if (valueToSet) {
            this.onMaterialChange(valueToSet);
          }

          //for MaterialUnit
          const valueToSet1 = this.state.selectedUnit.find((row) => {
            return row.value === this.props.inputData.material_Unit;
          });
          if (valueToSet1) {
            this.onUnitChange(valueToSet1);
          }

          //for Surveyor
          const valueToSet2 = this.state.selectedSurveyor.find((row) => {
            return row.value === this.props.inputData.surveyor;
          });
          if (valueToSet2) {
            this.onSurveyorChange(valueToSet2);
          }
          if (valueToSet2) {
            this.onSurveyorChange(valueToSet2);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  deleteMaterial = (id) => {
    AxiosConfig.delete("/VesselMaterial/" + id, this.state)
      // axios.delete(`http://localhost:8080/VesselMaterial/` + id)

      .then((response) => {
        console.log(id);
        if (response === 201) {
          console.log(response);
        }
        this.getVesselMaterials();

        this.setState({ message1: "Vessel Material Deleted Successfully" });
      });
    this.outMessage();
  };

  componentDidMount() {
    this.getData();
    this.getMaterialName();
    this.getUnit();
    this.getSurveyor();
    // this.getVesselMaterials();
  }

  SwitchhandleChange = (approved) => {
    this.setState({ approved });
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  changeState = (e) => {
    //validation for material_value
    const { name, value } = e.target;
    let errors = this.state.errors;
    // let success = e.target.error;
    const validValue = RegExp(/^[0-9\b]+$/);

    switch (name) {
      case "material_Value":
        errors.material_Value = validValue.test(value) ? "" : "Invalid value";
        break;
      default:
        break;
    }
    // this.setState({ successmessage: success });
    this.setState({ errors, [name]: value });
  };

  validateForm = () => {
    let error = {};
    let formIsValid = true;
    if (!this.props.inputData.material_Name) {
      formIsValid = false;
      error["material_Name"] = "*Please Choose Material Name";
    }

    if (!this.props.inputData.material_Value) {
      formIsValid = false;
      error["material_Value"] = "*Please Choose Material Value";
    }

    if (!this.props.inputData.material_Unit) {
      formIsValid = false;
      error["material_Unit"] = "*Please Choose Material Unit";
    }

    if (!this.props.inputData.surveyor) {
      formIsValid = false;
      error["surveyor"] = "*Please Choose Surveyor";
    }

    this.setState({
      error: error,
    });
    return formIsValid;
  };

  validation = () => {
    if (this.validateForm() === true) {
      this.props.next();
    } else {
      this.validateForm();
    }
  };

  onMaterialChange = (obj) => {
    this.setState({ material_Name: obj });
    this.props.inputData.material_Name = obj;
  };

  onUnitChange = (obj) => {
    this.setState({ material_Unit: obj });
    this.props.inputData.material_Unit = obj;
  };

  onSurveyorChange = (obj) => {
    this.state.surveyor = obj;
    this.setState({ surveyor: obj });
    this.props.inputData.surveyor = obj;
  };

  saveMaterial = (e) => {
    e.preventDefault();
    e.target.reset();

    var Material = {
      material_Name: this.state.material_Name.value,
      material_Value: this.state.material_Value,
      material_Unit: this.state.material_Unit.value,
      surveyor: this.state.surveyor.value,
      // surveyorModel: {
      //   surveyor_id: this.state.surveyor.value,
      // },

      approved: this.state.approved,
    };

    if (this.validateForm()) {
      axios.post("VesselMaterial", Material).then((result) => {
        // this.setState(result)
        sessionStorage.setItem("material_id", result.data);
        this.getVesselMaterials();
        //setTimeout(() => { this.get(); }, 200);
        this.setState({
          message: `Vessel Material Added Successfully!!`,
        });
        this.onReset();
      });
    }
    // .catch((error) =>
    //   console.log(error))
    this.outMessage();
  };

  updateMaterial = (e) => {
    e.preventDefault();
    // e.target.reset();

    var Material = {
      id: this.props.inputData.materialId,
      material_Name: this.props.inputData.material_Name.value,
      material_Value: this.props.inputData.material_Value,
      material_Unit: this.props.inputData.material_Unit.value,
      surveyor: this.props.inputData.surveyor.value,
      approved: this.props.inputData.approved,
      cr_id: this.props.inputData.cr_id,
      cr_date: this.props.inputData.cr_date,
    };

    // axios.post("VesselMaterial", Material)
    if (this.validateForm()) {
      axios.put("VesselMaterial/" + this.state.id, Material).then((result) => {
        // this.setState(result)
        this.setState({
          message: `Vessel Material Updated Successfully!!`,
          id: "",
        });
        this.getVesselMaterials();
        // setTimeout(() => { this.get(); }, 200);

        this.onReset();
      });
    }
    // .catch((error) =>
    //   console.log(error))
    this.outMessage();
  };

  //function for alert  close
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 1000);
    this.setState({
      message: "",
      message1: "",
    });
  };

  onReset = () => {
    this.setState({
      id: "",
      material_Name: "",
      material_Value: "",
      material_Unit: "",
      surveyor: "",
      approved: false,
    });
    this.props.inputData.materialId = "";
    this.props.inputData.material_Name = "";
    this.props.inputData.material_Value = "";
    this.props.inputData.material_Unit = "";
    this.props.inputData.surveyor = "";
    this.props.inputData.approved = "";
  };

  render() {
    const {
      material_Name,
      material_Value,
      surveyor,
      selectedName,
      material_Unit,
      selectedUnit,
      selectedSurveyor,
      errors,
      selectedOption,
      columns,
      rowData,
      merged,
      cr_id,
      cr_date,
      id,
    } = this.state;
    return (
      <Container fluid className=" FormWp formWpr">
        <h6>
          {this.state.message1 && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="VesselDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                {" "}
                &times;
              </a>
              {this.state.message1}
            </div>
          )}
        </h6>
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/VesselDetails"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                {" "}
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>

        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText"> Vessel Material</h3>
            </Col>
            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
          </Row>
          <Form
          //  onSubmit={this.addOrUpdate}
          // onSubmit={this.state.id ? this.updateMaterial : this.saveMaterial}
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input type="hidden" name="id" required value={id} />

                  <Input type="hidden" name="cr_id" required value={cr_id} />

                  <Input
                    type="hidden"
                    name="cr_date"
                    required
                    value={cr_date}
                  />

                  <Label className="required">Vessel Material</Label>
                  {/* <DropDown title="Vessel Material" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}

                  {/* <Select options={this.state.selectedName}
                    name="material_Name"
                    onChange={this.onMaterialChange}
                    selectedVal={this.selectedName}
                  /> */}

                  <br />
                  <Select
                    required
                    value={this.props.inputData.material_Name}
                    onChange={this.props.onMaterialChange}
                    options={selectedName}
                    name="material_Name"
                  />
                  {this.props.errors.material_Name ? (
                    <div className="errorMsg">
                      {this.props.errors.material_Name}{" "}
                    </div>
                  ) : (
                    <div className="errorMsg">
                      {this.state.error.material_Name}{" "}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="required"> Material Value</Label>
                  <Input
                    type="text"
                    name="material_Value"
                    value={this.props.inputData.material_Value}
                    placeholder="Enter Material Value"
                    autoComplete="false"
                    onChange={(e) => {
                      this.props.test(e);
                      this.changeState(e);
                    }}
                  />
                  {
                    <span className="error" style={{ color: "red" }}>
                      {errors.material_Value}
                    </span>
                  }
                  {this.props.errors.material_Value ? (
                    <div className="errorMsg">
                      {this.props.errors.material_Value}{" "}
                    </div>
                  ) : (
                    <div className="errorMsg">
                      {this.state.error.material_Value}{" "}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={2}>
                <Label className="required">Unit</Label>
                {/* <DropDown title="Select Vessel Built Year" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}

                {/* <Select options={this.state.selectedUnit}
                  // name="material_Name"
                  onChange={this.onUnitChange} required
                /> */}
                <Select
                  required
                  value={this.props.inputData.material_Unit}
                  onChange={this.props.onUnitChange}
                  options={selectedUnit}
                  name="material_Unit"
                />
                {this.props.errors.material_Unit ? (
                  <div className="errorMsg">
                    {this.props.errors.material_Unit}{" "}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {this.state.error.material_Unit}{" "}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="required"> Surveyors </Label>
                {/* <DropDown title="Surveyor " options={VesselYearBuilt} value={this.state.material_Name}
                  onChange={this.handleChange}  options={Surveyors} /> */}

                {/* <Select options={this.state.selectedSurveyor}
                  // name="material_Name"
                  onChange={this.onSurveyorChange} required
                /> */}
                <Select
                  required
                  value={this.props.inputData.surveyor}
                  onChange={this.props.onSurveyorChange}
                  options={selectedSurveyor}
                  name="surveyor"
                />
                {this.props.errors.surveyor ? (
                  <div className="errorMsg">{this.props.errors.surveyor} </div>
                ) : (
                  <div className="errorMsg">{this.state.error.surveyor} </div>
                )}
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Material Approved</Label>
                  <br />
                  <Switch
                    checked={this.props.inputData.approved}
                    onChange={this.props.SwitchhandleChange}
                    icon={false}
                  />
                </FormGroup>
              </Col>
              {this.props.inputData.materialId ? (
                <Col md={1} className="AddBtnCol">
                  <Button onClick={this.updateMaterial}>UPDATE</Button>
                </Col>
              ) : (
                <Col md={1} className="AddBtnCol">
                  <Button onClick={this.validation}>NEXT</Button>
                </Col>
              )}

              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  {/* <Button className="text-center mr-3  btnblue">Submit</Button> */}
                  <Button
                    type="reset"
                    className="text-center "
                    color="danger"
                    onClick={this.onReset}
                  >
                    Clear
                  </Button>
                </div>
              </Col>

              {/* <div style={{ color: "red" }}>{this.state.errormessage}</div>
              <div style={{ color: "green" }}>
                <NotificationContainer />
              </div> */}
            </Row>
            <Row className="mt-4 mb-3">
              <Col className="tableWp">
                <Row>
                  <Col className="DashboardTbl">
                    {/* <Table data={merged} pageSize={8} paging={false} Approved columns={columns} search={false} headerStyle={headerStyle}
                      rowStyle={rowStyle} maxBodyHeight='350px'
                      actions={
                        [
                          {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                              this.getData(rowData.id)
                              // this.props.history.push(`/AddVesselMaterial?id=${rowData._id}`)   
                            }
                          },
                          // {
                          //   icon: 'delete',
                          //   tooltip: 'Delete',
                          //   onClick: (event, rowData) => {
                          //     this.setState({ show: true, rowData: rowData })
                          //     // this.props.history.push(`/Page?id=${rowData._id}`)
                          //   }
                          // },

                        ]
                      }
                    /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center mt-3">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue">Submit</Button>
                  <Button type="reset" className="text-center " color="danger" onClick={this.onReset}>Clear</Button>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Card>
        <DeleteConfirm
          show={this.state.show}
          animation={true}
          rowData={rowData}
          id={rowData.id}
          name={rowData.materialName}
          close={() => this.setState({ show: false })}
          deleteHandler={this.deleteMaterial}
        />
      </Container>
    );
  }
}
export default withRouter(AddVesselMaterial);
