import React from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import react, { useState } from "react"
import './Sidebar.css'
import SubMenu from "./SubMenu";
import SingleSubMenu from "./SingleSubMenu";
import DashboardIcon from '@material-ui/icons/Dashboard';
import logo from '../../assets/Shiplogo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchor, faCog, faFlag, faShip, faUser, faAngleRight
} from "@fortawesome/free-solid-svg-icons";

// import Home from  '../../Containers/Home/Home'


const SideBar = ({ isOpen, toggle }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const intentFunct = (id) => {
    setActiveIndex(id);
  }
  return (

    <div className={classNames("sidebar shadow", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <div className="logowp mt-4 mb-4">
          <a href="https://shiplords.com">
            <img src={logo} alt="logo" />
          </a>
        </div>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3 sideNv">
          <NavLink onClick={() => intentFunct(1)}
            className={activeIndex === 1 ? "setActiveTab pt-0 pb-0 pl-0" : ""}>
            <SubMenu title="Master" icon={faAnchor} items={submenus[0]} />
          </NavLink>
          {/* <NavItem onClick={() => intentFunct(2)}
            className={activeIndex === 2 ? "setActiveTab pl-0" : ""}>
            <NavLink tag={Link} to={"/"}>
              <FontAwesomeIcon icon={faCog} className="mr-4" />
              Settings
            </NavLink>
          </NavItem> */}
          {/* <NavLink onClick={()=> intentFunct(3)}
            className={activeIndex === 3 ? "setActiveTab pt-0 pb-0 pl-0" : ""}>
            <SingleSubMenu title="Flag Master" icon={faFlag} items={Asubmenus[0]} />
      </NavLink>
      <NavLink onClick={()=> intentFunct(4)}
          className={activeIndex === 4 ? "setActiveTab pt-0 pb-0 pl-0" : ""}>
          <SingleSubMenu title="vessel Class" icon={faUser} items={Asubmenus[0]} />
      </NavLink>
      <NavLink onClick={()=> intentFunct(5)}
          className={activeIndex === 5 ? "setActiveTab pt-0 pb-0 pl-0" : ""}>
          <SingleSubMenu title="vessel Owner" icon={faShip} items={Asubmenus[0]} />
      </NavLink> */}
        </Nav>
      </div>
    </div>
  );
}

const submenus = [
  [
    {
      id: 1,
      title: "vessel Flag", target: "#",
      title: "Vessel Flag", target: "#",
      menu: [{ title: "Add New Flag", target: "FlagMaster" },
      { title: "Flag List", target: "FlagMasterTable" }]
    },
    {
      title: " vessel Owner", target: "#",
      menu: [{ title: "vessel Owner Details", target: "VesselOwnerDetails" },
      { title: "vessel Owner List", target: "VesselOwnerListTable" },
        // { title: "vessel Owner Detail Info", target: "VesselOwnerDetailInfo" },
      ]
    },
    // {
    //   title: "vessel Class", target: "#",
    //   title: " Vessel Owner", target: "#",
    //   menu: [{ title: "Vessel Owner Details", target: "VesselOwnerDetails" },
    //   { title: "Vessel Owner List", target: "VesselOwnerListTable" },
    //   { title: "Vessel Owner Detail Info", target: "VesselOwnerDetailInfo" },
    //   ]
    // },
    {
      title: "Vessel Class", target: "#",
      menu: [{ title: "Add new Class", target: "AddClassDetails" },
      { title: "Vessel Class List", target: "VesselClassList" },
        //  { title: "Vessel Class Master", target: "VesselClassMaster" }
      ]
    },
    {
      title: "Surveyors", target: "#",
      menu: [
        // { title: "Add Class", target: "AddClassSurveyors" },
        { title: "Surveyor Master", target: "SurveyorMaster", },
        { title: "Surveyor List ", target: "VesselSurveyorsListTable" },
      ]
    },
    {
      title: "Parameters", target: "#",
      menu: [{ title: "Add Parameter", target: "AddParameter" }, { title: "Parameter PList", target: "ParameterListTable", }]
    },
    {
      title: "Vessel Type", target: "#",
      menu: [{ title: "Add Vessel Type", target: "AddVesselType" }, { title: "Vessel Type List", target: "VesselTypeList", }]
    },
    {
      title: "Vessel Details",
      menu: [
        { title: "Vessel  Details", target: "VesselDetails" },
        { title: "Vessel  Details List", target: "AllVesselDetailsList" }
        // { title: " Add Vessel  Details", target: "AddVesselDetails"},
        // { title: "Vessel  Details List", target: "VesselDetailList"}
      ]
    },
   
    {
      title: "Vessel Design Master",
      menu: [{ title: "Vessel Design Master", target: "VesselDesignMaster" },
        // { title: "Vessel Design Master List", target: "VesselDesignDetailList" },
        // { title: " Add Vessel Design Details", target: "AddVesselDesignDetails" },
        // { title: "Vessel Design Detail List", target: "AddVesselDesignDetailList" }
      ]
    },

    {
      title: "Drawing Master", target: "#",
      menu: [{ title: "Vessel Drawing Master", target: "VesselDrawingTypeMaster" },
        // { title: "ClassWise Drawing Master", target: "VesselClassWiseDrawingMaster" },
        // { title: "Add Vessel Drawing Details", target: "AddVesselDrawingDetails" },
        // { title: "Vessel Drawing Deatil List", target: "VesselDrawingList" }
      ]

    },
    {
      title: "Equipments", target: "#",
      menu: [{ title: "Vessel Equipment Details", target: "VesselEquipmentMaster" }]
    },
    {
      title: "Material Master", target: "#",
      menu: [{ title: "Add Material", target: "MaterialMaster" },
      { title: "Material Master List", target: "MaterialMasterList" },
        // { title: "Vessel Material Details", target: "VesselMaterialDetails" }
      ]
    },
    {
      title: "Inspection Master", target: "InspectionTypeMaster",
      menu: [{ title: "Add Inspection", target: "InspectionTypeMaster" },
      { title: "Inspection List", target: "InspectionList" }
      ]

    },
    {
      title: "Unit Master", target: "UnitMaster",
      menu: [{ title: "Add Unit", target: "UnitMaster" },
      ]

    },

  ],

];
const Asubmenus = [
  [{ title: "Flag Master", target: "FlagMaster" }, { title: "View Flag Master", target: "FlagMasterTable" }],


];


export default SideBar;