
import React from "react";
// import '../Course/CourseMaster.css'
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AxiosConfig } from "../Common/AxiosConfig";
import ValidateMaterial from "../Common/Validation/DoubleEntry.js";
import $ from 'jquery';


const validateUrl = 'designName/name/';


export default class VesselDesignMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {


      id: this.props.match.params.id,
      designTypeName: "",
      cr_id: "",
      cr_date: "",
      message: "",
      title: ""

    }

  }

  onInputChange = (e) => {
    let { name, value } = e.target;
    value = value.replace(/[^A-Za-z\s]/ig, '');
    if (name == "designTypeName") {
      if (name.length < 1) {
        alert("Vessel Type must have more than one character");
      }
    }
    this.setState({ [name]: value });
  }


  getData() {
    if (this.state.id != null) {
      AxiosConfig.get("/dsgType_findone/" + this.state.id)
        .then((response) => {
          let design = response.data;
          this.setState({
            id: design.id,
            designTypeName: design.designTypeName,
            cr_id: design.cr_id,
            cr_date: design.cr_date,
          })
        })
        .catch((error) => {
          console.log(error);
        })
    }

  }
  componentDidMount() {
    this.getData();

    if (this.state.id != null) {
      this.setState({ title: "Update" });
    }
    //  else {
    //   this.setState({ title: "Update" });
    // }
  }

  addOrUpdate = (e) => {
    e.preventDefault();
    if (this.state.id == null) {
      this.addDesign();
      this.setState({ message: `Vessel Design Type Master Added Sucessfully!!` })
      this.setState({ designTypeName: "" })
    }
    else {
      this.updateDesign();
      this.setState({ message: `Vessel Design Type Master Updated Sucessfully!!`, id: "" })
      this.setState({ designTypeName: "" })
    }
    this.outMessage();
  }


  addDesign() {
    // console.log("add");
    AxiosConfig.post('dsgType_add', this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  updateDesign(id) {

    console.log("update");
    AxiosConfig.put('dsgType_up/' + this.state.id, this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })


  }
  redirection = () => {
    const { history, id, designTypeName } = this.props;
    if (history) history.push('/VesselDesignMasterList');
  }
  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }


  onReset = (e) => {
    this.setState({
      designTypeName: ""
    })
  }
  render() {
    const { designTypeName, id, title, Data, columns } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>{this.state.message && <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
          <a href="/VesselDesignMaster" className="close" data-dismiss="alert" aria-label="close">&times;</a>{this.state.message}</div>}</h6>

        <Card className="shadow p-4 col-md-7 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Vessel Design Type Master</h3>
            </Col>
            <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon /></Button></Col>
          </Row>
          <Form onSubmit={this.addOrUpdate}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Design Type Name</Label>
                  <Input type="text" name="designTypeName"
                    placeholder=" Enter Design Type Name" required
                    value={this.state.designTypeName} onChange={this.onInputChange} />
                  <ValidateMaterial url={validateUrl} name={designTypeName} id={id}></ValidateMaterial>

                </FormGroup>
              </Col>

            </Row>

            <Row>
              <Col className="text-center mt-2">
                <div className="center-block text-center">
                  <Button className="text-center mr-3  btnblue" id="submit" type="submit">Submit</Button>

                  <Button className="text-center " color="danger" type="clear" onClick={this.onReset}> Cancel</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }

}