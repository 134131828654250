
import React from "react";
// import '../Course/CourseMaster.css';
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import Table from '../../Containers/Common/Table';
import '../../Containers/Common/Table.css';
import DropDown from '../../Containers/Common/DropDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AxiosConfig } from '../Common/AxiosConfig.js';
import 'react-notifications-component/dist/theme.css'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import data from "../../Containers/Common/Data.json";
import $ from 'jquery';



export default class VesselOwnerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectState: [],
      SelectCity: [],
      SelectCountry: [],
      SelectPhone: "",
      vessel_Owner_ID: this.props.match.params.id,
      vessel_Owner_Name: "",
      vesselOwnerRegNo: "",
      vessel_Owner_Add1: "",
      vessel_Owner_Add2: "",
      vessel_Owner_Add3: "",
      vessel_Owner_OffPh1: "",
      vessel_Owner_OffPh2: "",
      vessel_Owner_Website: "",
      vessel_Owner_City: "",
      vessel_Owner_State: "",
      vessel_Owner_Country: "",
      vesselOwnerEmail: "",
      vessel_Owner_ContactPerson: "",
      vessel_Owner__CP_ContactNo: "",
      vessel_Owner__CP_Email: "",
      cr_ID: "",
      cr_Date: "",
      message: "",
      title: "",
      error: {},
      errors: {
        vessel_Owner_Name: "",
        vesselOwnerRegNo: "",
        vessel_Owner_Add1: "",
        vessel_Owner_Add2: "",
        vessel_Owner_Add3: "",
        vessel_Owner_OffPh1: "",
        vessel_Owner_OffPh2: "",
        vessel_Owner_Website: "",
        vessel_Owner_City: "",
        vessel_Owner_State: "",
        vessel_Owner_Country: "",
        vesselOwnerEmail: "",
        vessel_Owner_ContactPerson: "",
        vessel_Owner__CP_ContactNo: "",
        vessel_Owner__CP_Email: "",
      },
    };
  }

  reset = () => {
    this.setState({
      vessel_Owner_Name: "",
      vesselOwnerRegNo: "",
      vessel_Owner_Add1: "",
      vessel_Owner_Add2: "",
      vessel_Owner_Add3: "",
      vessel_Owner_OffPh1: "",
      vessel_Owner_OffPh2: "",
      vessel_Owner_Website: "",
      vessel_Owner_City: "",
      vessel_Owner_State: "",
      vessel_Owner_Country: "",
      vesselOwnerEmail: "",
      vessel_Owner_ContactPerson: "",
      vessel_Owner__CP_ContactNo: "",
      vessel_Owner__CP_Email: "",
    });
  };

  componentDidMount() {

    //console.log(data);
    this.getData();
    this.setState({
      SelectCountry: data,
    });

    if (this.state.vessel_Owner_ID != null) {
      this.setState({ title: "Update" });
    }
    // else {
    //   this.setState({ title: "Update" });
    // }
  }

  getData = () => {
    if (this.state.vessel_Owner_ID != null) {
      AxiosConfig.get("vesselOwnerMaster/" + this.state.vessel_Owner_ID)
        .then((response) => {
          console.log(response.data);
          let vesselOwner = response.data;
          this.setState({
            vessel_Owner_ID: vesselOwner.vessel_Owner_ID,
            vessel_Owner_Name: vesselOwner.vessel_Owner_Name,
            vesselOwnerRegNo: vesselOwner.vesselOwnerRegNo,
            vessel_Owner_Add1: vesselOwner.vessel_Owner_Add1,
            vessel_Owner_Add2: vesselOwner.vessel_Owner_Add2,
            vessel_Owner_Add3: vesselOwner.vessel_Owner_Add3,
            vessel_Owner_City: vesselOwner.vessel_Owner_City,
            vessel_Owner_State: vesselOwner.vessel_Owner_State,
            vessel_Owner_Country: vesselOwner.vessel_Owner_Country,
            vessel_Owner_OffPh1: vesselOwner.vessel_Owner_OffPh1,
            vessel_Owner_OffPh2: vesselOwner.vessel_Owner_OffPh2,
            vessel_Owner_Website: vesselOwner.vessel_Owner_Website,
            vesselOwnerEmail: vesselOwner.vesselOwnerEmail,
            vessel_Owner_ContactPerson: vesselOwner.vessel_Owner_ContactPerson,
            vessel_Owner__CP_ContactNo: vesselOwner.vessel_Owner__CP_ContactNo,
            vessel_Owner__CP_Email: vesselOwner.vessel_Owner__CP_Email,
            cr_ID: vesselOwner.cr_ID,
            cr_Date: vesselOwner.cr_Date,

          });
          this.selectedCountry(this.state.vessel_Owner_Country);
          this.selectedState(this.state.vessel_Owner_State);
          this.selectedCity(this.state.vessel_Owner_City);


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  addOrUpdate = (e) => {
    e.preventDefault();

    if (this.validateForm1()) {
      if (this.state.vessel_Owner_ID != null) {
        this.update(this.state.vessel_Owner_ID);
        //this.updateAlert();
        // this.setState({
        //   message: ` Vessel Owner Updated Successfully!!`
        // });

      } else if (this.state.vessel_Owner_ID == null) {
        this.add();
        //.addAlert();

        this.setState({
          message: ` Vessel Owner Added Successfully!!`
        });

        console.log(this.state)
      }

    }

    // this.addPage();
    // this.reset();
    // this.outMessage();
  }


  validation = (e) => {

    e.preventDefault();
    const validateForm = (errors) => {
      let valid = true;
      Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
      return valid;
    };
    const checkRegNo = () => {
      AxiosConfig.get(
        "vesselOwnerMasterReg/" + this.state.vesselOwnerRegNo
      ).then((response) => {
        let regNo = response.data.vesselOwnerRegNo;
        let err = e.target.error;
        if (regNo == undefined) {
          console.log(regNo);
          //validation on submit
          if (validateForm(this.state.errors)) {
            console.log(this.state);
            console.info("Valid Form");
          } else {
            NotificationManager.error("Invalid Form Inputs", "");
            console.error("Invalid Form");
          }
        } else {
          err = <strong>Registration Number already exists</strong>;
          this.setState({ errormessage: err });
        }
      });
    };
    AxiosConfig.get(
      "vesselOwnerMasterMail/" + this.state.vesselOwnerEmail
    ).then((response) => {
      let MailN = response.data.vesselOwnerEmail;
      let err = e.target.error;
      if (MailN == undefined) {
        checkRegNo();
      } else {
        err = <strong>Mail already exists</strong>;
        this.setState({ mailMessage: err });
      }
    });
  };

  update(vessel_Owner_ID) {

    AxiosConfig.put("vesselOwnerMaster/" + this.state.vessel_Owner_ID, this.state)
      .then((response) => {
        this.setState({
          message: ` Vessel Owner Updated Successfully!!`
        });
        if (response === 201) {
        
         
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.reset();
    this.outMessage();
  }

  //alerts
  updateAlert() {
    NotificationManager.success("", "Updated");
  }
  addAlert() {
    NotificationManager.success("", "Added");
  }

  add() {
    console.log("add method");
    AxiosConfig.post("vesselOwnerMaster", this.state)
      .then((response) => {
        if (response === 201) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.reset();
    this.outMessage();

  }

  changeState = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;
    let success = e.target.error;
    const validName = RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/);
    const validRegNo = RegExp(/[^A-Za-z]\d/gi, "");
    const validPhone = RegExp(/^[6-9]\d{9}$/);
    const validWeb = RegExp(
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
    );
    const validEmail = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    switch (name) {
      case "vessel_Owner_Name":
        errors.vessel_Owner_Name = validName.test(value) ? "" : "Invalid name";
        break;
      case "vesselOwnerRegNo":
        errors.vesselOwnerRegNo = validRegNo.test(value) ? "" : "Invalid input";
        break;
      case "vessel_Owner_OffPh1":
        errors.vessel_Owner_OffPh1 = validPhone.test(value)
          ? ""
          : "Phone number not valid";
        break;
      case "vessel_Owner_OffPh2":
        errors.vessel_Owner_OffPh2 = validPhone.test(value)
          ? ""
          : "Phone number not valid";
        break;
      case "vessel_Owner_Website":
        errors.vessel_Owner_Website = validWeb.test(value)
          ? ""
          : "Website format invalid";
        break;
      case "vesselOwnerEmail":
        errors.vesselOwnerEmail = validEmail.test(value) ? "" : "Invalid Email";
        break;
      case "vessel_Owner_ContactPerson":
        errors.vessel_Owner_ContactPerson = validName.test(value)
          ? ""
          : "Invalid Name";
        break;
      case "vessel_Owner__CP_ContactNo":
        errors.vessel_Owner__CP_ContactNo = validPhone.test(value)
          ? ""
          : "Invalid number";
        break;
      case "vessel_Owner__CP_Email":
        errors.vessel_Owner__CP_Email = validEmail.test(value)
          ? ""
          : "Invalid Email";
        break;
      default:
        break;
    }
    this.setState({ successmessage: success });
    this.setState({ errors, [name]: value });
  };


  validateForm1() {
    let error = {};
    let formIsValid = true;
    if (this.state.vessel_Owner_Country == "") {
      formIsValid = false;
      error["vessel_Owner_Country"] = "*Please Choose Country";
    }

    if (this.state.vessel_Owner_State == "") {
      formIsValid = false;
      error["vessel_Owner_State"] = "*Please Choose State";
    }

    if (this.state.vessel_Owner_City == "") {
      formIsValid = false;
      error["vessel_Owner_City"] = "*Please Choose City";
    }

    this.setState({
      error: error
    });
    return formIsValid;
  }

  selectedCity = (value) => {
    this.setState({ vessel_Owner_City: value });
    console.log(value);
  };

  selectedState = (value) => {

    this.setState({ vessel_Owner_State: value });
    console.log(value);
    let stats = this.state.SelectCountry.find(
      (cntry) => cntry.name === this.state.vessel_Owner_Country
    ).states;
    this.setState({
      SelectCity: stats.find((stats) => stats.name === value)
        .cities,
    });
  };

  selectedCountry = (value) => {

    this.setState({ vessel_Owner_Country: value });
    console.log(value);
    this.setState({
      SelectState: this.state.SelectCountry.find(
        (cntry) => cntry.name === value
      ).states,
      // SelectPhone: this.state.SelectCountry.find(
      //   (cntry) => cntry.name === value
      // ).phone_code,
    });
    // console.log(this.state.SelectPhone);
  };

  // selectedCountry = (value) => {
  //   this.setState({ vessel_Owner_Country: value });
  //    this.setState({
  //      SelectState: this.state.SelectCountry.find(
  //        (cntry) => cntry.id === parseInt(value)
  //      ).states
  //    });
  //    console.log(this.state.vessel_Owner_Country);
  // };

  addPage() {
    window.location.href = "/VesselOwnerDetails";
  }

  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }

  redirection = () => {
    const { history } = this.props;
    if (history) history.push("/VesselOwnerListTable");
  };
  render() {
    const {
      errors,
      SelectState,
      SelectCity,
      SelectCountry,
      vessel_Owner_Id,
      vessel_Owner_Name,
      vesselOwnerRegNo,
      vessel_Owner_Add1,
      vessel_Owner_Add2,
      vessel_Owner_Add3,
      vessel_Owner_City,
      vessel_Owner_State,
      vessel_Owner_Country,
      vessel_Owner_OffPh1,
      vessel_Owner_OffPh2,
      vessel_Owner_Website,
      vesselOwnerEmail,
      vessel_Owner_ContactPerson,
      vessel_Owner__CP_ContactNo,
      vessel_Owner__CP_Email,
      cr_ID,
      cr_Date,
      title
    } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp">
        <h6>
          {this.state.message && (
            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="/VesselDetails" className="close" data-dismiss="alert" aria-label="close"> &times;
              </a>
              {this.state.message}
            </div>)}
        </h6>

        <Card className="shadow p-4 col-md-8 mx-auto">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">{title} Vessel Owner Details</h3>
            </Col>
            <Col md={1}>
              <Button className="pt-0 pb-0" onClick={this.redirection}>
                <VisibilityIcon />
              </Button>
            </Col>
          </Row>
          <Form onSubmit={this.addOrUpdate}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="hidden"
                    name="vessel_Owner_Id"
                    value={vessel_Owner_Id}
                  />
                  <Input type="hidden" name="cr_ID" value={cr_ID} />
                  <Input type="hidden" name="cr_Date" value={cr_Date} />

                  <Label className="required">Owner Name</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    name="vessel_Owner_Name"
                    value={vessel_Owner_Name}
                    onChange={this.changeState}
                    required
                    placeholder="Owner Name"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner_Name}
                    </span>
                  }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Registration No.</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    name="vesselOwnerRegNo"
                    value={vesselOwnerRegNo}
                    onChange={this.changeState}
                    required
                    placeholder=" Register No"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vesselOwnerRegNo}
                    </span>
                  }{" "}
                  <div style={{ color: "red" }}>{this.state.errormessage}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Address</Label>
                  {/* <Label className="required"> Line 1</Label> */}
                  <Input
                    type="text"
                    autocomplete="off"
                    name="vessel_Owner_Add1"
                    required
                    value={vessel_Owner_Add1}
                    onChange={this.changeState}
                    placeholder="Building name/ No."
                  />
                </FormGroup>
                <FormGroup>
                  {/* <Label>Line 2</Label> */}
                  <Input
                    type="text"
                    autocomplete="off"
                    name="vessel_Owner_Add2"
                    value={vessel_Owner_Add2}
                    onChange={this.changeState}
                    placeholder="Street/ Area"
                  />
                </FormGroup>
                <FormGroup>
                  {/* <Label>Line 3</Label> */}
                  <Input
                    type="text"
                    autocomplete="on"
                    name="vessel_Owner_Add3"
                    value={vessel_Owner_Add3}
                    onChange={this.changeState}
                    placeholder="optional"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Country</Label>
                  <DropDown
                    title="Select Country"
                    required
                    name="vessel_Owner_Country"
                    options={SelectCountry}
                    value={vessel_Owner_Country}
                    selectedVal={this.selectedCountry}
                  />
                  <div className="errorMsg">{this.state.error.vessel_Owner_Country} </div>

                  {/* <CountryDropdown
                    value={country}
                    onChange={(val) => this.selectCountry(val)}
                  /> */}
                  {/* <select
                    placeholder="Country"
                    value={this.state.selectedCountry}
                    onChange={this.changeCountry}
                  >
                    <option>--Choose Country--</option>
                    {this.state.countries.map((e, key) => {
                      return <option key={key}>{data}</option>;
                    })}
                  </select> */}
                </FormGroup>
                <FormGroup>
                  <Label className="required">State</Label>
                  <DropDown
                    title="Select State"
                    required
                    name="vessel_Owner_State"
                    value={vessel_Owner_State}
                    options={SelectState}
                    selectedVal={this.selectedState}
                  />
                  <div className="errorMsg">{this.state.error.vessel_Owner_State} </div>

                </FormGroup>
              </Col>
            </Row><Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Official Website</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    required
                    name="vessel_Owner_Website"
                    value={vessel_Owner_Website}
                    onChange={this.changeState}
                    placeholder="Official Website"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner_Website}
                    </span>
                  }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">City</Label>
                  <DropDown
                    title="Select City"
                    required
                    name="vessel_Owner_City"
                    options={SelectCity}
                    value={vessel_Owner_City}
                    selectedVal={this.selectedCity}
                  />
                  <div className="errorMsg">{this.state.error.vessel_Owner_City} </div>

                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Office Phone No 1</Label>
                  <Label value={this.state.SelectPhone} />
                  <Input
                    type="text"
                    autocomplete="off"
                    required
                    name="vessel_Owner_OffPh1"
                    value={vessel_Owner_OffPh1}
                    // value={"+" + this.state.SelectPhone + vessel_Owner_OffPh1}
                    onChange={this.changeState}
                    placeholder="Office Phone No 1"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner_OffPh1}
                    </span>
                  }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Office Phone No 2</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    name="vessel_Owner_OffPh2"
                    value={vessel_Owner_OffPh2}
                    onChange={this.changeState}
                    placeholder="Office Phone No 2"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner_OffPh2}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person's Email</Label>
                  <Input
                    type="email"
                    autocomplete="off"
                    required
                    name="vessel_Owner__CP_Email"
                    value={vessel_Owner__CP_Email}
                    onChange={this.changeState}
                    placeholder="Contact Person's Email"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner__CP_Email}
                    </span>
                  }
                </FormGroup>

              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Email</Label>
                  <Input
                    type="Email"
                    autocomplete="off"
                    required
                    name="vesselOwnerEmail"
                    value={vesselOwnerEmail}
                    onChange={this.changeState}
                    placeholder="Email"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vesselOwnerEmail}
                    </span>
                  }
                  <div style={{ color: "red" }}>{this.state.mailMessage}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    required
                    name="vessel_Owner_ContactPerson"
                    value={vessel_Owner_ContactPerson}
                    onChange={this.changeState}
                    placeholder="contact Person"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner_ContactPerson}
                    </span>
                  }
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Contact Person Contact No</Label>
                  <Input
                    type="text"
                    autocomplete="off"
                    required
                    name="vessel_Owner__CP_ContactNo"
                    value={vessel_Owner__CP_ContactNo}
                    onChange={this.changeState}
                    placeholder="Contact Person Contact No"
                  />
                  {
                    <span classNAme="error" style={{ color: "red" }}>
                      {errors.vessel_Owner__CP_ContactNo}
                    </span>
                  }
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col>{/* <MultiSelect/> */}</Col>
            </Row>
            <Row>
              <Col className="text-center mt-5">
                <div className="center-block text-center">
                  <Button
                    className="text-center mr-3  btnblue"
                    id="button"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    className="text-center"
                    onClick={this.reset}
                    color="danger"
                    type="reset"
                  >
                    Reset
                  </Button>
                </div>
                <div className="text-center">
                  <NotificationContainer />
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}
