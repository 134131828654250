
import React from "react";
// import '../Course/CourseMaster.css'
import { Form,Card, FormGroup, Label, Input,Col,Row,Container ,Button} from 'reactstrap';
import Table from  '../Common/Table'
import   '../../Containers/Common/Table.css'
import CheckIcon from '@material-ui/icons/Check';
const headerStyle = {
    backgroundColor: '#052754',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'12px',
    padding:'10px',
    color:'#818698',
  
  }
export default  class AddVesselDesignDetailList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        
        columns: [  
            // { title: "Id", field: "id" , hidden: true }
            { title: "Sr No", field: "Id"  , hidden: true },
            { title: "Design Type Name", field: "DesignTypeId"},
            { title: "Available status", field: "Available"},
            { title: "Attachment 1", field: "Attachment1" ,render: rowData => <CheckIcon className="CheckIcn" status={rowData.Attachment1} />},
            { title: "Attachment 2", field: "Attachment 2" ,render: rowData => <CheckIcon className="CheckIcn" status={rowData.Attachment2} />},
            { title: "Attachment 3", field: "Attachment 3" ,render: rowData => <CheckIcon className="CheckIcn" status={rowData.Attachment3} />},
          ],
          Data : [
            {Id: "01",DesignTypeId:"ABC",Available:"Yes"},
            {Id: "02",DesignTypeId:"PQR", Available:"Yes"},
            {Id: "03",DesignTypeId:"XYZ", Available:"Yes"},
            {Id: "04",DesignTypeId:"GHI",Available:"Yes"},
            { Id: "05", DesignTypeId: "pradnya", Available: "Yes" },
          ],
          
        }
       
  }

  render(){
    const{Data,columns} =this.state;
    return(
    <Container fluid className="CouseMasterWp FormWp">
        <Card className="shadow p-4 col-md-12">
        <Row>
         <Col className="">
          <h3 className="text-center blueText">Vessel Design Details List</h3>
         </Col>
       </Row>
            <Row className=" mb-3">
                <Col className="tableWp">
                    <Row>
                        <Col className="DashboardTbl">
                        <Table data={Data} pageSize={5} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                            rowStyle={rowStyle} maxBodyHeight='300px' 
                        actions={
                            [
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                this.props.history.push(`/AddVesselDesignDetails?id=${rowData._id}`)
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => {
                                this.props.history.push(`/Page?id=${rowData._id}`)
                                }
                            },
                            ]
                        }
                        />
                        </Col>
                    </Row>            
                </Col>
            </Row>
       </Card>
    </Container>
    );
}

}