import axios from "axios";

//axios Instance
const axiInst = axios.create({
  baseURL: "https://api.shiplords.com/ShipForSaleService",
  // baseURL: "http://localhost:8087/ShipForSaleService",
});

export default axiInst;

// import axios from "axios";

// export const ShipForSaleService = axios.create({
//     baseURL: 'http://localhost:9000/ShipForSaleService'
// });
