
import React from "react";
import { Form, Card, FormGroup, Label, Input, Col, Row, Container, Button } from 'reactstrap';
import CheckIcon from '@material-ui/icons/Check';
import Table from '../Common/Table';
import '../../Containers/Common/Table.css';
import { Modal } from 'react-bootstrap';
import { AxiosConfig } from '../Common/AxiosConfig.js';
import DeleteConfirm from '../../Containers/Common/deleteComf.js';
import $ from 'jquery';


const headerStyle = {
  backgroundColor: '#052754',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'normal',
  padding: '10px',
  position: 'sticky', top: 0,
}
const rowStyle = {
  fontSize: '12px',
  padding: '10px',
  color: '#818698',

}
export default class VesselOwnerListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Id", field: "vessel_Owner_ID", hidden: true },
        {
          title: 'Sr No', field: 'tableData.id',
          render: rowData => { return (<p>{rowData.tableData.id + 1}</p>) }
        },
        { title: "Owner Name", field: "vessel_Owner_Name" },
        { title: "Register No", field: "vesselOwnerRegNo" },
        { title: "Email", field: "vesselOwnerEmail", type: "Email" }
      ],
      Data: [],
      Array: [],
      // show: false,
      // close: false,
      vessel_Owner_ID: this.props.match.params.id,
      sr_no: "",
      vessel_Owner_Name: "",
      vesselOwnerRegNo: "",
      vessel_Owner_Add1: "",
      vessel_Owner_Add2: "",
      vessel_Owner_Add3: "",
      vessel_Owner_OffPh1: "",
      vessel_Owner_OffPh2: "",
      vessel_Owner_Website: "",
      vessel_Owner_City: "",
      vessel_Owner_State: "",
      vessel_Owner_Country: "",
      vesselOwnerEmail: "",
      vessel_Owner_ContactPerson: "",
      vessel_Owner__CP_ContactNo: "",
      vessel_Owner__CP_Email: "",
      cr_ID: "",
      cr_Date: "",
      rowData: [],
      showC: false,
      show: false,
      close: false,
      message: ""
    }
    this.getOwner = this.getOwner.bind(this)
  }

  getOwner() {
    // alert("koi");
    AxiosConfig.get('vesselOwnerMaster')
      .then(response => {

        this.setState({ Data: response.data });
        console.log(response);
      });
  }

  componentDidMount() {
    this.getOwner();
  }

  deleteOwner = (vessel_Owner_ID) => {
    AxiosConfig.delete('vesselOwnerMaster/' + vessel_Owner_ID)
      .then(response => {
        if (response === 201) {
          console.log(response);
        }
        this.getOwner();
        this.setState({ message: "Vessel Owner Deleted Successfully" });
      })
    this.outMessage();
  }
  //function for alert  close 
  outMessage = () => {
    $(".alert").show();
    setTimeout(function () {
      $(".alert").hide();
    }, 2000);
  }

  selectOne(vessel_Owner_ID) {
    AxiosConfig.get("/vesselOwnerMaster/" + vessel_Owner_ID).
      then(response => {
        this.props.history.push("/VesselOwnerDetails/" + vessel_Owner_ID);
      })
  }

  render() {
    const { Array, Data, columns, rowData } = this.state;
    return (
      <Container fluid className="CouseMasterWp FormWp pl-0 pr-0">

        <h6>
          {this.state.message && (
            <div className="alert alert-danger alert-dismissble shadow col-md-8 mx-auto">
              <a
                href="VesselOwnerListTable" className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {this.state.message}
            </div>
          )}
        </h6>
        <Card className="shadow p-4 col-md-12">
          <Row>
            <Col className="mb-4">
              <h3 className="text-center blueText">Vessel Owner List</h3>
            </Col>
          </Row>
          <Row className="">
            <Col className="tableWp">
              <Row>
                <Col className="DashboardTbl">
                  <Table
                    data={Data}
                    pageSize={5}
                    paging={true}
                    columns={columns}
                    search={false}
                    headerStyle={headerStyle}
                    rowStyle={rowStyle}
                    maxBodyHeight="300px"
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          this.selectOne(rowData.vessel_Owner_ID);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          this.setState({ showC: true, rowData: rowData });
                        },
                      },
                      {
                        icon: "visibility",
                        tooltip: "View",
                        onClick: (event, rowData) => {
                          this.setState({ show: true, Array: rowData });
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Modal
          show={this.state.show}
          animation={true}
          size="lg"
          className="shadow-lg border"
        >
          <Modal.Header
            closeButton
            onClick={() => this.setState({ show: false })}
          >
            <Modal.Title>{Array.vessel_Owner_Name}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            data={Data}
            actions={{
              onLoad: (event, rowData) => {
                this.getData(rowData.vessel_Owner_ID);
              },
            }}
          >
            <Row>
              <Col md={3}>
                <Label className="DetailText">Registration No. :</Label>
              </Col>
              <Col md={3}>
                {" "}
                <Label className="normalTxt">{Array.vesselOwnerRegNo}</Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText"> Email :</Label>
              </Col>
              <Col md={3}>
                {" "}
                <Label className="normalTxt">{Array.vesselOwnerEmail}</Label>
              </Col>
            </Row>
            <Row>
              <Label type="hidden" value={Array.vessel_Owner_ID} />
              <Col md={3}>
                <Label className="DetailText">Address :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner_Add1}, {Array.vessel_Owner_Add2},{" "}
                  {Array.vessel_Owner_Add3}
                </Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText">City :</Label>
              </Col>
              <Col md={3}>
                {" "}
                <Label className="normalTxt">{Array.vessel_Owner_City}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="DetailText">Country :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner_Country}
                </Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText">State :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">{Array.vessel_Owner_State}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="DetailText">Office Phone 1 :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">{Array.vessel_Owner_OffPh1}</Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText">Office Phone 2 :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">{Array.vessel_Owner_OffPh2}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="DetailText">Contact Person :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner_ContactPerson}
                </Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText">Officil Website :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner_Website}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="DetailText">Contact No :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner__CP_ContactNo}
                </Label>
              </Col>
              <Col md={3}>
                <Label className="DetailText">Contact Person's Email :</Label>
              </Col>
              <Col md={3}>
                <Label className="normalTxt">
                  {Array.vessel_Owner__CP_Email}
                </Label>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="py-1 d-flex justify-content-center mt-2 mb-2">
            <Button
              color="danger"
              onClick={() => this.setState({ show: false })}
            >
              Cancel
            </Button>
            {/* <Button variant="primary"  onClick={() => this.setState({ show: false })}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        <DeleteConfirm
          show={this.state.showC}
          animation={true}
          rowData={rowData}
          id={rowData.vessel_Owner_ID}
          name={rowData.vessel_Owner_Name}
          close={() => this.setState({ showC: false })}
          deleteHandler={this.deleteOwner}
        />
      </Container>
    );
  }
}
