import React, { Component } from 'react'
import { Container, Card, Input, Form, Row, Col, Button, Label, FormGroup } from "reactstrap";
import { AxiosConfig, ImageService, ThumbnailImageUrl } from "../Common/AxiosConfig";
import { Modal } from 'react-bootstrap'
import Select from 'react-select';
import Switch from "react-switch";
import FileDropzone from "../Common/FileDropzone";
import $ from 'jquery';


export default class UpdateVesselDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            drawing_type: [],
            vesselDrawing_name: '',
            vesselDrawing_available: false,
            errors: {},
            selectedFiles: "",
            selectedFiles1: "",
            selectedFiles2: "",
            name1: "",
            name2: "",
            name3: "",
            drawingId: sessionStorage.getItem('drawingId'),
            vesselDrawing_attachment1: "",
            vesselDrawing_attachment2: "",
            vesselDrawing_attachment3: "",
            attachment1name: "",
            attachment2name: "",
            attachment3name: "",
            thumbnail1Name: "",
            thumbnail2Name: "",
            thumbnail3Name: "",
            cr_id: "",
            cr_date: ""
        }
    }
    componentDidMount() {
        this.getOptions();




    }
    async getOptions() {
        const res = await AxiosConfig.get("drw_type")
        const data = res.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.drwTypeName
        }))
        this.setState({ drawing_type: options })
        this.getDrawingById();
    }
    getDrawingById() {
        AxiosConfig.get("vesseldrawing/" + this.state.drawingId)
            .then((response) => {
                console.log(response.data);
                this.setState({
                  vesselDrawing_name: response.data.drawing_typeModel.id,
                  vesselDrawing_available:
                    response.data.vesselDrawing_available,
                  attachment1name: response.data.attachment1name,
                  attachment2name: response.data.attachment2name,
                  attachment3name: response.data.attachment3name,
                  thumbnail1Name: response.data.thumbnail1Name,
                  thumbnail2Name: response.data.thumbnail2Name,
                  thumbnail3Name: response.data.thumbnail3Name,
                  name1: response.data.attachment1name,
                  name2: response.data.attachment2name,
                  name3: response.data.attachment3name,
                  cr_id: response.data.cr_id,
                  cr_date: response.data.cr_date,
                });
                const valueToSet = this.state.drawing_type.find((row) => {
                    return row.value === this.state.vesselDrawing_name;
                });
                if (valueToSet) {
                    this.onInputChange(valueToSet);
                }
            })
    }
    onInputChange = (obj) => {
        this.setState({ vesselDrawing_name: obj })

    }
    switchhandleChange = (vesselDrawing_available) => {
        this.setState({ vesselDrawing_available });
    }
    onDropAttachment1 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles: files, vesselDrawing_attachment1: files })
        }
    };
    onDropAttachment2 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles1: files, vesselDrawing_attachment2: files })
        }
    };
    onDropAttachment3 = (files) => {
        if (files.length > 0) {
            this.setState({ selectedFiles2: files, vesselDrawing_attachment3: files })
        }
    };
    onClear = () => {
        this.setState({
            vesselDrawing_name: '',
            vesselDrawing_available: false,
            selectedFiles: "",
            selectedFiles1: "",
            selectedFiles2: "",
            name1: "",
            name2: "",
            name3: "",
            attachment1name: "",
            attachment2name: "",
            attachment3name: "",
            vesselDrawing_attachment1: "",
            vesselDrawing_attachment2: "",
            vesselDrawing_attachment3: "",
        })
    }

    outMessage = () => {

        $(".alert").show();

        setTimeout(function () {

            $(".alert").hide();

        }, 2000);

        this.setState({ message: "" })


    }
    updateDrawing = (e) => {
        e.preventDefault();
        if ((this.state.vesselDrawing_attachment1 === undefined || this.state.vesselDrawing_attachment1 === "")
            && (this.state.vesselDrawing_attachment2 === undefined || this.state.vesselDrawing_attachment2 === "")
            && (this.state.vesselDrawing_attachment3 === undefined || this.state.vesselDrawing_attachment3 === "")
        ) {

            var drawingformData = {
                'id': this.state.drawingId,
                drawing_typeModel:{
                    id:this.state.vesselDrawing_name.value
                },
               
                "vesselDrawing_available": this.state.vesselDrawing_available,
                "attachment1name": this.state.attachment1name,
                "attachment2name": this.state.attachment2name,
                "attachment3name": this.state.attachment3name,
                // "thumbnailsFileName": this.state.thumbnailsFileName,
                "thumbnail1Name": this.state.thumbnail1Name,
                "thumbnail2Name": this.state.thumbnail2Name,
                "thumbnail3Name": this.state.thumbnail3Name,
                "cr_id": this.state.cr_id,
                "cr_date": this.state.cr_date

            }

            AxiosConfig.put("vesseldrawing/" + this.state.drawingId, drawingformData)
                .then((res) => {
                  
                    this.setState({
                        message: `vessel drawing update Successfully!!`,
                    });
                    this.onClear();
                })

        }
        else {
            let array = [];
            let arr = [];
            if (this.state.vesselDrawing_attachment1) {
            
                array.push(this.state.vesselDrawing_attachment1[0])
                arr.push({
                    "name": "attachment1name",
                    "filename": "",
                    "thumbnailfile": "",
                    "thumbnailfilename": "thumbnail1Name",
                })

            }
            if (this.state.vesselDrawing_attachment2) {
            
                array.push(this.state.vesselDrawing_attachment2[0])
                arr.push({
                    "name": "attachment2name",
                    "filename": "",
                    "thumbnailfile": "",
                    "thumbnailfilename": "thumbnail2Name",
                })
            }
            if (this.state.vesselDrawing_attachment3) {
             
                array.push(this.state.vesselDrawing_attachment3[0])
                arr.push({
                    "name": "attachment3name",
                    "filename": "",
                    "thumbnailfile": "",
                    "thumbnailfilename": "thumbnail3Name",
                })
            }
            let formData1 = new FormData();

            for (let a = 0; a < array.length; a++) {
                console.log(array[a]);
                formData1.append('files', array[a])
            }

            ImageService.post('addMultiImageFile', formData1).then((res) => {
                if (res.status == 200) {
                    let filenameloop = res.data.fileNames;

                    for (let i = 0; i < filenameloop.length; i++) {
                        arr[i].filename = res.data.fileNames[i];
                        arr[i].thumbnailfile = res.data.thumbnailsFileName[i];

                        this.setState({ [arr[i].name]: res.data.fileNames[i] })
                        this.setState({ [arr[i].thumbnailfilename]: res.data.thumbnailsFileName[i] })
                    }
                    this.setState({
                        fileDownloadUri: res.data.fileDownloadUri,
                        thumbnailUrl: res.data.thumbnailUrl,

                    })
                    var drawingformData = {
                        'id': this.state.drawingId,
                        "vesselDrawing_name": this.state.vesselDrawing_name.value,
                        "vesselDrawing_available": this.state.vesselDrawing_available,
                        "attachment1name": this.state.attachment1name,
                        "attachment2name": this.state.attachment2name,
                        "attachment3name": this.state.attachment3name,
                        // "thumbnailsFileName": this.state.thumbnailsFileName,
                        "thumbnail1Name": this.state.thumbnail1Name,
                        "thumbnail2Name": this.state.thumbnail2Name,
                        "thumbnail3Name": this.state.thumbnail3Name,
                        "cr_id": this.state.cr_id,
                        "cr_date": this.state.cr_date

                    }
                    AxiosConfig.put("vesseldrawing/" + this.state.drawingId, drawingformData)
                        .then((res) => {
                            this.setState({
                                message: `vessel drawing update Successfully!!`,
                            });
                            this.onClear();

                        })
                }
            })



        }
        this.outMessage();
    }
    render() {
        return (
            <div>
                <Container fluid className="AddVesselDrawing formWpr">

                    <h6>
                        {this.state.message && (
                            <div class="alert alert-success alert-dismissble shadow col-md-8 mx-auto">
                                <a
                                    href="/VesselDetails"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="close"
                                >
                                    &times;
                                </a>
                                {this.state.message}
                            </div>
                        )}
                    </h6>
                    <Card className="shadow p-4 col-md-10 mx-auto">
                        <Row>
                            <Col className="mb-4">
                                <h3 className="text-center blueText">Vessel Drawings </h3>
                            </Col>
                            {/* <Col md={1} className="pl-0"><Button className="pt-0 pb-0" onClick={this.redirection}><VisibilityIcon/></Button></Col> */}
                        </Row>
                        <Form
                            onSubmit={this.updateDrawing}
                        >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>




                                        <Label className="required">Vessel Drawing</Label>
                                        {/* <DropDown title="Vessel Drawing" options={VesselYearBuilt} selectedVal={this.selectedValue}/> */}
                                        {/* <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={Design}/> */}


                                        <Select options={this.state.drawing_type} onChange={this.onInputChange} value={this.state.vesselDrawing_name} placeholder="Select Drawing Type" required />

                                        {
                                            this.state.errors.vesselDrawing_name &&

                                            <div className="errorMsg">{this.state.errors.vesselDrawing_name}</div>}

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label >Available</Label><br />
                                        <Switch onChange={this.switchhandleChange} checked={this.state.vesselDrawing_available} icon={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mb-2">
                                    <FormGroup>
                                        <Label className="required">Attachment 1</Label>
                                        <FileDropzone ondrop={this.onDropAttachment1} selectedfiles={this.state.selectedFiles} fileinfo={this.state.name1} />
                                        {
                                            this.state.errors.selectedFiles &&

                                            <div className="errorMsg">{this.state.errors.selectedFiles}</div>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <Label className="required">Attachment 2</Label>
                                    <FileDropzone ondrop={this.onDropAttachment2} selectedfiles={this.state.selectedFiles1} fileinfo={this.state.name2} />
                                    {
                                        this.state.errors.selectedFiles1 &&

                                        <div className="errorMsg">{this.state.errors.selectedFiles1}</div>}

                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Label className="required">Attachment 3</Label>
                                    <FileDropzone ondrop={this.onDropAttachment3} selectedfiles={this.state.selectedFiles2} fileinfo={this.state.name3} />
                                    {
                                        this.state.errors.selectedFiles2 &&

                                        <div className="errorMsg">{this.state.errors.selectedFiles2}</div>}

                                </Col>

                            </Row>
                            <Row>
                                <Col md={1} className="AddBtnCol">

                                    <Button>UPDATE</Button>


                                </Col>


                                <Col md={3} className="AddBtnCol">
                                    <div className="center-block text-center">
                                        <Button className="text-center "
                                            onClick={this.onClear}
                                            color="danger">Clear</Button>
                                    </div>
                                </Col>
                            </Row>


                        </Form>
                    </Card>

                    <Modal show={this.state.showHide} className="ViewModal">
                        <Modal.Header closeButton onClick={() => { this.handleModalShowHide(); this.handleModalShowHide1(); this.handleModalShowHide2(); }}>
                            <Modal.Title>Attachment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <img className="photo" src={this.state.url} /> */}
                            <img className="photo" src={ThumbnailImageUrl + `${this.state.url}`} />

                        </Modal.Body>
                    </Modal>
                </Container >
            </div>
        )
    }
}
